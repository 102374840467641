module["exports"] = [
  "Söder",
  "Norr",
  "Väst",
  "Öster",
  "Aling",
  "Ar",
  "Av",
  "Bo",
  "Br",
  "Bå",
  "Ek",
  "En",
  "Esk",
  "Fal",
  "Gäv",
  "Göte",
  "Ha",
  "Helsing",
  "Karl",
  "Krist",
  "Kram",
  "Kung",
  "Kö",
  "Lyck",
  "Ny"
];
