module["exports"] = [
  "Alsace",
  "Aquitaine",
  "Auvergne",
  "Basse-Normandie",
  "Bourgogne",
  "Bretagne",
  "Centre",
  "Champagne-Ardenne",
  "Corse",
  "Franche-Comté",
  "Haute-Normandie",
  "Île-de-France",
  "Languedoc-Roussillon",
  "Limousin",
  "Lorraine",
  "Midi-Pyrénées",
  "Nord-Pas-de-Calais",
  "Pays de la Loire",
  "Picardie",
  "Poitou-Charentes",
  "Provence-Alpes-Côte d'Azur",
  "Rhône-Alpes"
];
