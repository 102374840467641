module["exports"] = [
  "Австралія",
  "Австрія",
  "Азербайджан",
  "Албанія",
  "Алжир",
  "Ангола",
  "Андорра",
  "Антигуа і Барбуда",
  "Аргентина",
  "Афганістан",
  "Багамські Острови",
  "Бангладеш",
  "Барбадос",
  "Бахрейн",
  "Беліз",
  "Бельгія",
  "Бенін",
  "Білорусь",
  "Болгарія",
  "Болівія",
  "Боснія і Герцеговина",
  "Ботсвана",
  "Бразилія",
  "Бруней",
  "Буркіна-Фасо",
  "Бурунді",
  "Бутан",
  "В’єтнам",
  "Вануату",
  "Ватикан",
  "Велика Британія",
  "Венесуела",
  "Вірменія",
  "Габон",
  "Гаїті",
  "Гайана",
  "Гамбія",
  "Гана",
  "Гватемала",
  "Гвінея",
  "Гвінея-Бісау",
  "Гондурас",
  "Гренада",
  "Греція",
  "Грузія",
  "Данія",
  "Демократична Республіка Конго",
  "Джибуті",
  "Домініка",
  "Домініканська Республіка",
  "Еквадор",
  "Екваторіальна Гвінея",
  "Еритрея",
  "Естонія",
  "Ефіопія",
  "Єгипет",
  "Ємен",
  "Замбія",
  "Зімбабве",
  "Ізраїль",
  "Індія",
  "Індонезія",
  "Ірак",
  "Іран",
  "Ірландія",
  "Ісландія",
  "Іспанія",
  "Італія",
  "Йорданія",
  "Кабо-Верде",
  "Казахстан",
  "Камбоджа",
  "Камерун",
  "Канада",
  "Катар",
  "Кенія",
  "Киргизстан",
  "Китай",
  "Кіпр",
  "Кірибаті",
  "Колумбія",
  "Коморські Острови",
  "Конго",
  "Коста-Рика",
  "Кот-д’Івуар",
  "Куба",
  "Кувейт",
  "Лаос",
  "Латвія",
  "Лесото",
  "Литва",
  "Ліберія",
  "Ліван",
  "Лівія",
  "Ліхтенштейн",
  "Люксембург",
  "Маврикій",
  "Мавританія",
  "Мадаґаскар",
  "Македонія",
  "Малаві",
  "Малайзія",
  "Малі",
  "Мальдіви",
  "Мальта",
  "Марокко",
  "Маршаллові Острови",
  "Мексика",
  "Мозамбік",
  "Молдова",
  "Монако",
  "Монголія",
  "Намібія",
  "Науру",
  "Непал",
  "Нігер",
  "Нігерія",
  "Нідерланди",
  "Нікарагуа",
  "Німеччина",
  "Нова Зеландія",
  "Норвегія",
  "Об’єднані Арабські Емірати",
  "Оман",
  "Пакистан",
  "Палау",
  "Панама",
  "Папуа-Нова Гвінея",
  "Парагвай",
  "Перу",
  "Південна Корея",
  "Південний Судан",
  "Південно-Африканська Республіка",
  "Північна Корея",
  "Польща",
  "Португалія",
  "Російська Федерація",
  "Руанда",
  "Румунія",
  "Сальвадор",
  "Самоа",
  "Сан-Марино",
  "Сан-Томе і Принсіпі",
  "Саудівська Аравія",
  "Свазіленд",
  "Сейшельські Острови",
  "Сенеґал",
  "Сент-Вінсент і Гренадини",
  "Сент-Кітс і Невіс",
  "Сент-Люсія",
  "Сербія",
  "Сирія",
  "Сінгапур",
  "Словаччина",
  "Словенія",
  "Соломонові Острови",
  "Сомалі",
  "Судан",
  "Суринам",
  "Східний Тимор",
  "США",
  "Сьєрра-Леоне",
  "Таджикистан",
  "Таїланд",
  "Танзанія",
  "Того",
  "Тонга",
  "Тринідад і Тобаго",
  "Тувалу",
  "Туніс",
  "Туреччина",
  "Туркменістан",
  "Уганда",
  "Угорщина",
  "Узбекистан",
  "Україна",
  "Уругвай",
  "Федеративні Штати Мікронезії",
  "Фіджі",
  "Філіппіни",
  "Фінляндія",
  "Франція",
  "Хорватія",
  "Центральноафриканська Республіка",
  "Чад",
  "Чехія",
  "Чилі",
  "Чорногорія",
  "Швейцарія",
  "Швеція",
  "Шрі-Ланка",
  "Ямайка",
  "Японія"
];
