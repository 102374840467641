module["exports"] = [
  "02# #### ###",
  "02## #### ###",
  "03## #### ###",
  "04## #### ###",
  "05## #### ###",
  "06## #### ###",
  "07## #### ###",
  "09## #### ###",
  "02# #### ####",
  "02## #### ####",
  "03## #### ####",
  "04## #### ####",
  "05## #### ####",
  "06## #### ####",
  "07## #### ####",
  "09## #### ####",
  "08## ### ###",
  "08## #### ###",
  "08## #### ####",
  "(+62) 8## ### ###",
  "(+62) 2# #### ###",
  "(+62) 2## #### ###",
  "(+62) 3## #### ###",
  "(+62) 4## #### ###",
  "(+62) 5## #### ###",
  "(+62) 6## #### ###",
  "(+62) 7## #### ###",
  "(+62) 8## #### ###",
  "(+62) 9## #### ###",
  "(+62) 2# #### ####",
  "(+62) 2## #### ####",
  "(+62) 3## #### ####",
  "(+62) 4## #### ####",
  "(+62) 5## #### ####",
  "(+62) 6## #### ####",
  "(+62) 7## #### ####",
  "(+62) 8## #### ####",
  "(+62) 9## #### ####"
];