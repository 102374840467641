module["exports"] = [
  "com",
  "info",
  "name",
  "net",
  "org",
  "de",
  "ch",
  "at"
];
