module["exports"] = [
  "Aadrika",
  "Aanandinii",
  "Aaratrika",
  "Aarya",
  "Arya",
  "Aashritha",
  "Aatmaja",
  "Atmaja",
  "Abhaya",
  "Adwitiya",
  "Agrata",
  "Ahilya",
  "Ahalya",
  "Aishani",
  "Akshainie",
  "Akshata",
  "Akshita",
  "Akula",
  "Ambar",
  "Amodini",
  "Amrita",
  "Amritambu",
  "Anala",
  "Anamika",
  "Ananda",
  "Anandamayi",
  "Ananta",
  "Anila",
  "Anjali",
  "Anjushri",
  "Anjushree",
  "Annapurna",
  "Anshula",
  "Anuja",
  "Anusuya",
  "Anasuya",
  "Anasooya",
  "Anwesha",
  "Apsara",
  "Aruna",
  "Asha",
  "Aasa",
  "Aasha",
  "Aslesha",
  "Atreyi",
  "Atreyee",
  "Avani",
  "Abani",
  "Avantika",
  "Ayushmati",
  "Baidehi",
  "Vaidehi",
  "Bala",
  "Baala",
  "Balamani",
  "Basanti",
  "Vasanti",
  "Bela",
  "Bhadra",
  "Bhagirathi",
  "Bhagwanti",
  "Bhagwati",
  "Bhamini",
  "Bhanumati",
  "Bhaanumati",
  "Bhargavi",
  "Bhavani",
  "Bhilangana",
  "Bilwa",
  "Bilva",
  "Buddhana",
  "Chakrika",
  "Chanda",
  "Chandi",
  "Chandni",
  "Chandini",
  "Chandani",
  "Chandra",
  "Chandira",
  "Chandrabhaga",
  "Chandrakala",
  "Chandrakin",
  "Chandramani",
  "Chandrani",
  "Chandraprabha",
  "Chandraswaroopa",
  "Chandravati",
  "Chapala",
  "Charumati",
  "Charvi",
  "Chatura",
  "Chitrali",
  "Chitramala",
  "Chitrangada",
  "Daksha",
  "Dakshayani",
  "Damayanti",
  "Darshwana",
  "Deepali",
  "Dipali",
  "Deeptimoyee",
  "Deeptimayee",
  "Devangana",
  "Devani",
  "Devasree",
  "Devi",
  "Daevi",
  "Devika",
  "Daevika",
  "Dhaanyalakshmi",
  "Dhanalakshmi",
  "Dhana",
  "Dhanadeepa",
  "Dhara",
  "Dharani",
  "Dharitri",
  "Dhatri",
  "Diksha",
  "Deeksha",
  "Divya",
  "Draupadi",
  "Dulari",
  "Durga",
  "Durgeshwari",
  "Ekaparnika",
  "Elakshi",
  "Enakshi",
  "Esha",
  "Eshana",
  "Eshita",
  "Gautami",
  "Gayatri",
  "Geeta",
  "Geetanjali",
  "Gitanjali",
  "Gemine",
  "Gemini",
  "Girja",
  "Girija",
  "Gita",
  "Hamsini",
  "Harinakshi",
  "Harita",
  "Heema",
  "Himadri",
  "Himani",
  "Hiranya",
  "Indira",
  "Jaimini",
  "Jaya",
  "Jyoti",
  "Jyotsana",
  "Kali",
  "Kalinda",
  "Kalpana",
  "Kalyani",
  "Kama",
  "Kamala",
  "Kamla",
  "Kanchan",
  "Kanishka",
  "Kanti",
  "Kashyapi",
  "Kumari",
  "Kumuda",
  "Lakshmi",
  "Laxmi",
  "Lalita",
  "Lavanya",
  "Leela",
  "Lila",
  "Leela",
  "Madhuri",
  "Malti",
  "Malati",
  "Mandakini",
  "Mandaakin",
  "Mangala",
  "Mangalya",
  "Mani",
  "Manisha",
  "Manjusha",
  "Meena",
  "Mina",
  "Meenakshi",
  "Minakshi",
  "Menka",
  "Menaka",
  "Mohana",
  "Mohini",
  "Nalini",
  "Nikita",
  "Ojaswini",
  "Omana",
  "Oormila",
  "Urmila",
  "Opalina",
  "Opaline",
  "Padma",
  "Parvati",
  "Poornima",
  "Purnima",
  "Pramila",
  "Prasanna",
  "Preity",
  "Prema",
  "Priya",
  "Priyala",
  "Pushti",
  "Radha",
  "Rageswari",
  "Rageshwari",
  "Rajinder",
  "Ramaa",
  "Rati",
  "Rita",
  "Rohana",
  "Rukhmani",
  "Rukmin",
  "Rupinder",
  "Sanya",
  "Sarada",
  "Sharda",
  "Sarala",
  "Sarla",
  "Saraswati",
  "Sarisha",
  "Saroja",
  "Shakti",
  "Shakuntala",
  "Shanti",
  "Sharmila",
  "Shashi",
  "Shashikala",
  "Sheela",
  "Shivakari",
  "Shobhana",
  "Shresth",
  "Shresthi",
  "Shreya",
  "Shreyashi",
  "Shridevi",
  "Shrishti",
  "Shubha",
  "Shubhaprada",
  "Siddhi",
  "Sitara",
  "Sloka",
  "Smita",
  "Smriti",
  "Soma",
  "Subhashini",
  "Subhasini",
  "Sucheta",
  "Sudeva",
  "Sujata",
  "Sukanya",
  "Suma",
  "Suma",
  "Sumitra",
  "Sunita",
  "Suryakantam",
  "Sushma",
  "Swara",
  "Swarnalata",
  "Sweta",
  "Shwet",
  "Tanirika",
  "Tanushree",
  "Tanushri",
  "Tanushri",
  "Tanya",
  "Tara",
  "Trisha",
  "Uma",
  "Usha",
  "Vaijayanti",
  "Vaijayanthi",
  "Baijayanti",
  "Vaishvi",
  "Vaishnavi",
  "Vaishno",
  "Varalakshmi",
  "Vasudha",
  "Vasundhara",
  "Veda",
  "Vedanshi",
  "Vidya",
  "Vimala",
  "Vrinda",
  "Vrund",
  "Aadi",
  "Aadidev",
  "Aadinath",
  "Aaditya",
  "Aagam",
  "Aagney",
  "Aamod",
  "Aanandaswarup",
  "Anand Swarup",
  "Aanjaneya",
  "Anjaneya",
  "Aaryan",
  "Aryan",
  "Aatmaj",
  "Aatreya",
  "Aayushmaan",
  "Aayushman",
  "Abhaidev",
  "Abhaya",
  "Abhirath",
  "Abhisyanta",
  "Acaryatanaya",
  "Achalesvara",
  "Acharyanandana",
  "Acharyasuta",
  "Achintya",
  "Achyut",
  "Adheesh",
  "Adhiraj",
  "Adhrit",
  "Adikavi",
  "Adinath",
  "Aditeya",
  "Aditya",
  "Adityanandan",
  "Adityanandana",
  "Adripathi",
  "Advaya",
  "Agasti",
  "Agastya",
  "Agneya",
  "Aagneya",
  "Agnimitra",
  "Agniprava",
  "Agnivesh",
  "Agrata",
  "Ajit",
  "Ajeet",
  "Akroor",
  "Akshaj",
  "Akshat",
  "Akshayakeerti",
  "Alok",
  "Aalok",
  "Amaranaath",
  "Amarnath",
  "Amaresh",
  "Ambar",
  "Ameyatma",
  "Amish",
  "Amogh",
  "Amrit",
  "Anaadi",
  "Anagh",
  "Anal",
  "Anand",
  "Aanand",
  "Anang",
  "Anil",
  "Anilaabh",
  "Anilabh",
  "Anish",
  "Ankal",
  "Anunay",
  "Anurag",
  "Anuraag",
  "Archan",
  "Arindam",
  "Arjun",
  "Arnesh",
  "Arun",
  "Ashlesh",
  "Ashok",
  "Atmanand",
  "Atmananda",
  "Avadhesh",
  "Baalaaditya",
  "Baladitya",
  "Baalagopaal",
  "Balgopal",
  "Balagopal",
  "Bahula",
  "Bakula",
  "Bala",
  "Balaaditya",
  "Balachandra",
  "Balagovind",
  "Bandhu",
  "Bandhul",
  "Bankim",
  "Bankimchandra",
  "Bhadrak",
  "Bhadraksh",
  "Bhadran",
  "Bhagavaan",
  "Bhagvan",
  "Bharadwaj",
  "Bhardwaj",
  "Bharat",
  "Bhargava",
  "Bhasvan",
  "Bhaasvan",
  "Bhaswar",
  "Bhaaswar",
  "Bhaumik",
  "Bhaves",
  "Bheeshma",
  "Bhisham",
  "Bhishma",
  "Bhima",
  "Bhoj",
  "Bhramar",
  "Bhudev",
  "Bhudeva",
  "Bhupati",
  "Bhoopati",
  "Bhoopat",
  "Bhupen",
  "Bhushan",
  "Bhooshan",
  "Bhushit",
  "Bhooshit",
  "Bhuvanesh",
  "Bhuvaneshwar",
  "Bilva",
  "Bodhan",
  "Brahma",
  "Brahmabrata",
  "Brahmanandam",
  "Brahmaanand",
  "Brahmdev",
  "Brajendra",
  "Brajesh",
  "Brijesh",
  "Birjesh",
  "Budhil",
  "Chakor",
  "Chakradhar",
  "Chakravartee",
  "Chakravarti",
  "Chanakya",
  "Chaanakya",
  "Chandak",
  "Chandan",
  "Chandra",
  "Chandraayan",
  "Chandrabhan",
  "Chandradev",
  "Chandraketu",
  "Chandramauli",
  "Chandramohan",
  "Chandran",
  "Chandranath",
  "Chapal",
  "Charak",
  "Charuchandra",
  "Chaaruchandra",
  "Charuvrat",
  "Chatur",
  "Chaturaanan",
  "Chaturbhuj",
  "Chetan",
  "Chaten",
  "Chaitan",
  "Chetanaanand",
  "Chidaakaash",
  "Chidaatma",
  "Chidambar",
  "Chidambaram",
  "Chidananda",
  "Chinmayanand",
  "Chinmayananda",
  "Chiranjeev",
  "Chiranjeeve",
  "Chitraksh",
  "Daiwik",
  "Daksha",
  "Damodara",
  "Dandak",
  "Dandapaani",
  "Darshan",
  "Datta",
  "Dayaamay",
  "Dayamayee",
  "Dayaananda",
  "Dayaanidhi",
  "Kin",
  "Deenabandhu",
  "Deepan",
  "Deepankar",
  "Dipankar",
  "Deependra",
  "Dipendra",
  "Deepesh",
  "Dipesh",
  "Deeptanshu",
  "Deeptendu",
  "Diptendu",
  "Deeptiman",
  "Deeptimoy",
  "Deeptimay",
  "Dev",
  "Deb",
  "Devadatt",
  "Devagya",
  "Devajyoti",
  "Devak",
  "Devdan",
  "Deven",
  "Devesh",
  "Deveshwar",
  "Devi",
  "Devvrat",
  "Dhananjay",
  "Dhanapati",
  "Dhanpati",
  "Dhanesh",
  "Dhanu",
  "Dhanvin",
  "Dharmaketu",
  "Dhruv",
  "Dhyanesh",
  "Dhyaneshwar",
  "Digambar",
  "Digambara",
  "Dinakar",
  "Dinkar",
  "Dinesh",
  "Divaakar",
  "Divakar",
  "Deevakar",
  "Divjot",
  "Dron",
  "Drona",
  "Dwaipayan",
  "Dwaipayana",
  "Eekalabya",
  "Ekalavya",
  "Ekaksh",
  "Ekaaksh",
  "Ekaling",
  "Ekdant",
  "Ekadant",
  "Gajaadhar",
  "Gajadhar",
  "Gajbaahu",
  "Gajabahu",
  "Ganak",
  "Ganaka",
  "Ganapati",
  "Gandharv",
  "Gandharva",
  "Ganesh",
  "Gangesh",
  "Garud",
  "Garuda",
  "Gati",
  "Gatik",
  "Gaurang",
  "Gauraang",
  "Gauranga",
  "Gouranga",
  "Gautam",
  "Gautama",
  "Goutam",
  "Ghanaanand",
  "Ghanshyam",
  "Ghanashyam",
  "Giri",
  "Girik",
  "Girika",
  "Girindra",
  "Giriraaj",
  "Giriraj",
  "Girish",
  "Gopal",
  "Gopaal",
  "Gopi",
  "Gopee",
  "Gorakhnath",
  "Gorakhanatha",
  "Goswamee",
  "Goswami",
  "Gotum",
  "Gautam",
  "Govinda",
  "Gobinda",
  "Gudakesha",
  "Gudakesa",
  "Gurdev",
  "Guru",
  "Hari",
  "Harinarayan",
  "Harit",
  "Himadri",
  "Hiranmay",
  "Hiranmaya",
  "Hiranya",
  "Inder",
  "Indra",
  "Indra",
  "Jagadish",
  "Jagadisha",
  "Jagathi",
  "Jagdeep",
  "Jagdish",
  "Jagmeet",
  "Jahnu",
  "Jai",
  "Javas",
  "Jay",
  "Jitendra",
  "Jitender",
  "Jyotis",
  "Kailash",
  "Kama",
  "Kamalesh",
  "Kamlesh",
  "Kanak",
  "Kanaka",
  "Kannan",
  "Kannen",
  "Karan",
  "Karthik",
  "Kartik",
  "Karunanidhi",
  "Kashyap",
  "Kiran",
  "Kirti",
  "Keerti",
  "Krishna",
  "Krishnadas",
  "Krishnadasa",
  "Kumar",
  "Lai",
  "Lakshman",
  "Laxman",
  "Lakshmidhar",
  "Lakshminath",
  "Lal",
  "Laal",
  "Mahendra",
  "Mohinder",
  "Mahesh",
  "Maheswar",
  "Mani",
  "Manik",
  "Manikya",
  "Manoj",
  "Marut",
  "Mayoor",
  "Meghnad",
  "Meghnath",
  "Mohan",
  "Mukesh",
  "Mukul",
  "Nagabhushanam",
  "Nanda",
  "Narayan",
  "Narendra",
  "Narinder",
  "Naveen",
  "Navin",
  "Nawal",
  "Naval",
  "Nimit",
  "Niranjan",
  "Nirbhay",
  "Niro",
  "Param",
  "Paramartha",
  "Pran",
  "Pranay",
  "Prasad",
  "Prathamesh",
  "Prayag",
  "Prem",
  "Puneet",
  "Purushottam",
  "Rahul",
  "Raj",
  "Rajan",
  "Rajendra",
  "Rajinder",
  "Rajiv",
  "Rakesh",
  "Ramesh",
  "Rameshwar",
  "Ranjit",
  "Ranjeet",
  "Ravi",
  "Ritesh",
  "Rohan",
  "Rohit",
  "Rudra",
  "Sachin",
  "Sameer",
  "Samir",
  "Sanjay",
  "Sanka",
  "Sarvin",
  "Satish",
  "Satyen",
  "Shankar",
  "Shantanu",
  "Shashi",
  "Sher",
  "Shiv",
  "Siddarth",
  "Siddhran",
  "Som",
  "Somu",
  "Somnath",
  "Subhash",
  "Subodh",
  "Suman",
  "Suresh",
  "Surya",
  "Suryakant",
  "Suryakanta",
  "Sushil",
  "Susheel",
  "Swami",
  "Swapnil",
  "Tapan",
  "Tara",
  "Tarun",
  "Tej",
  "Tejas",
  "Trilochan",
  "Trilochana",
  "Trilok",
  "Trilokesh",
  "Triloki",
  "Triloki Nath",
  "Trilokanath",
  "Tushar",
  "Udai",
  "Udit",
  "Ujjawal",
  "Ujjwal",
  "Umang",
  "Upendra",
  "Uttam",
  "Vasudev",
  "Vasudeva",
  "Vedang",
  "Vedanga",
  "Vidhya",
  "Vidur",
  "Vidhur",
  "Vijay",
  "Vimal",
  "Vinay",
  "Vishnu",
  "Bishnu",
  "Vishwamitra",
  "Vyas",
  "Yogendra",
  "Yoginder",
  "Yogesh"
];
