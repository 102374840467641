module["exports"] = [
  "Алчевськ",
  "Артемівськ",
  "Бердичів",
  "Бердянськ",
  "Біла Церква",
  "Бровари",
  "Вінниця",
  "Горлівка",
  "Дніпродзержинськ",
  "Дніпропетровськ",
  "Донецьк",
  "Євпаторія",
  "Єнакієве",
  "Житомир",
  "Запоріжжя",
  "Івано-Франківськ",
  "Ізмаїл",
  "Кам’янець-Подільський",
  "Керч",
  "Київ",
  "Кіровоград",
  "Конотоп",
  "Краматорськ",
  "Красний Луч",
  "Кременчук",
  "Кривий Ріг",
  "Лисичанськ",
  "Луганськ",
  "Луцьк",
  "Львів",
  "Макіївка",
  "Маріуполь",
  "Мелітополь",
  "Миколаїв",
  "Мукачеве",
  "Нікополь",
  "Одеса",
  "Олександрія",
  "Павлоград",
  "Полтава",
  "Рівне",
  "Севастополь",
  "Сєвєродонецьк",
  "Сімферополь",
  "Слов’янськ",
  "Суми",
  "Тернопіль",
  "Ужгород",
  "Умань",
  "Харків",
  "Херсон",
  "Хмельницький",
  "Черкаси",
  "Чернівці",
  "Чернігів",
  "Шостка",
  "Ялта"
];
