var company = {};
module['exports'] = company;
company.suffix = require("./suffix");
company.adjective = require("./adjective");
company.descriptor = require("./descriptor");
company.noun = require("./noun");
company.bs_verb = require("./bs_verb");
company.bs_adjective = require("./bs_adjective");
company.bs_noun = require("./bs_noun");
company.name = require("./name");
