module["exports"] = [
  "Andalucía",
  "Aragón",
  "Principado de Asturias",
  "Baleares",
  "Canarias",
  "Cantabria",
  "Castilla-La Mancha",
  "Castilla y León",
  "Cataluña",
  "Comunidad Valenciana",
  "Extremadura",
  "Galicia",
  "La Rioja",
  "Comunidad de Madrid",
  "Navarra",
  "País Vasco",
  "Región de Murcia"
];
