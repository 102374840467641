module["exports"] = [
  "Abadon",
  "Abdon",
  "Ábel",
  "Abelard",
  "Abraham",
  "Abrahám",
  "Absolon",
  "Absolón",
  "Adalbert",
  "Adam",
  "Adin",
  "Adolf",
  "Adrian",
  "Adrián",
  "Agaton",
  "Achil",
  "Achiles",
  "Alan",
  "Alban",
  "Albert",
  "Albín",
  "Albrecht",
  "Aldo",
  "Alen",
  "Aleš",
  "Alexandr",
  "Alexej",
  "Alfons",
  "Alfréd",
  "Alois",
  "Alojz",
  "Alva",
  "Alvar",
  "Alvin",
  "Amadeus",
  "Amand",
  "Amát",
  "Ambrož",
  "Amos",
  "Ámos",
  "Anastáz",
  "Anatol",
  "Anděl",
  "Andělín",
  "Andrej",
  "Anselm",
  "Antal",
  "Antonín",
  "Aram",
  "Ariel",
  "Aristid",
  "Arkád",
  "Armand",
  "Armin",
  "Arne",
  "Arnold",
  "Arnošt",
  "Áron",
  "Árón",
  "Arpád",
  "Arsen",
  "Artur",
  "Artuš",
  "Arzen",
  "Atanas",
  "Atanáš",
  "Atila",
  "August",
  "Augustin",
  "Augustýn",
  "Aurel",
  "Aurelián",
  "Axel",
  "Baltazar",
  "Barnabáš",
  "Bartoloměj",
  "Basil",
  "Bazil",
  "Beatus",
  "Bedřich",
  "Benedikt",
  "Benjamin",
  "Benjamín",
  "Bernard",
  "Bertold",
  "Bertram",
  "Bivoj",
  "Blahomil",
  "Blahomír",
  "Blahoslav",
  "Blažej",
  "Bohdan",
  "Bohuchval",
  "Bohumil",
  "Bohumír",
  "Bohun",
  "Bohuslav",
  "Bohuš",
  "Bojan",
  "Bolemír",
  "Boleslav",
  "Bonifác",
  "Borek",
  "Boris",
  "Borislav",
  "Bořek",
  "Bořislav",
  "Bořivoj",
  "Božetěch",
  "Božidar",
  "Božislav",
  "Branimír",
  "Branislav",
  "Bratislav",
  "Bret",
  "Brian",
  "Brit",
  "Bronislav",
  "Bruno",
  "Břetislav",
  "Budimír",
  "Budislav",
  "Budivoj",
  "Cecil",
  "Cedrik",
  "Celestin",
  "Celestýn",
  "César",
  "Cézar",
  "Ctibor",
  "Ctirad",
  "Ctislav",
  "Cyprián",
  "Cyril",
  "Čeněk",
  "Čestmír",
  "Čistoslav",
  "Dag",
  "Dalibor",
  "Dalimil",
  "Dalimír",
  "Damián",
  "Dan",
  "Daniel",
  "Darek",
  "Darius",
  "David",
  "Denis",
  "Děpold",
  "Dětmar",
  "Dětřich",
  "Dezider",
  "Dimitrij",
  "Dino",
  "Dionýz",
  "Dionýzos",
  "Diviš",
  "Dluhoš",
  "Dobromil",
  "Dobromír",
  "Dobroslav",
  "Dominik",
  "Donald",
  "Donát",
  "Dorian",
  "Dorián",
  "Drahomil",
  "Drahomír",
  "Drahoň",
  "Drahoslav",
  "Drahoš",
  "Drahotín",
  "Drahutin",
  "Dušan",
  "Edgar",
  "Edmond",
  "Edmund",
  "Eduard",
  "Edvard",
  "Edvin",
  "Edvín",
  "Egmont",
  "Egon",
  "Eliáš",
  "Elizej",
  "Elizeus",
  "Elmar",
  "Elvis",
  "Emanuel",
  "Emanuel",
  "Emerich",
  "Emil",
  "Emilián",
  "Engelbert",
  "Erazim",
  "Erazmus",
  "Erhard",
  "Erich",
  "Erik",
  "Ernest",
  "Ernst",
  "Ervín",
  "Eugen",
  "Eusebius",
  "Evald",
  "Evan",
  "Evarist",
  "Evžen",
  "Ezechiel",
  "Ezra",
  "Fabián",
  "Faust",
  "Faustin",
  "Faustýn",
  "Fedor",
  "Felicián",
  "Felix",
  "Ferdinand",
  "Fidel",
  "Fidelius",
  "Filemon",
  "Filibert",
  "Filip",
  "Filomen",
  "Flavián",
  "Flavius",
  "Florentin",
  "Florentýn",
  "Florián",
  "Fortunát",
  "Fráňa",
  "Franc",
  "František",
  "Fridolín",
  "Gabin",
  "Gabriel",
  "Gál",
  "Garik",
  "Gaston",
  "Gedeon",
  "Gejza",
  "Genadij",
  "Gerald",
  "Gerard",
  "Gerazim",
  "Gerhard",
  "Géza",
  "Gilbert",
  "Gleb",
  "Glen",
  "Gorazd",
  "Gordon",
  "Gothard",
  "Gracián",
  "Grant",
  "Gunter",
  "Gűnter",
  "Gustav",
  "Hanuš",
  "Harald",
  "Harold",
  "Haštal",
  "Havel",
  "Helmut",
  "Herbert",
  "Herman",
  "Heřman",
  "Hilar",
  "Hilarius",
  "Hjalmar",
  "Homér",
  "Honor",
  "Honorius",
  "Horác",
  "Horst",
  "Horymír",
  "Hostimil",
  "Hostimír",
  "Hostislav",
  "Hostivít",
  "Hovard",
  "Hubert",
  "Hugo",
  "Hvězdoslav",
  "Hyacint",
  "Hynek",
  "Hypolit",
  "Chrabroš",
  "Chraniboj",
  "Chranibor",
  "Chranislav",
  "Chrudoš",
  "Chval",
  "Ignác",
  "Ignát",
  "Igor",
  "Ilja",
  "Inocenc",
  "Irenej",
  "Ireneus",
  "Irvin",
  "Isidor",
  "Ivan",
  "Ivar",
  "Ivo",
  "Ivor",
  "Izaiáš",
  "Izák",
  "Izidor",
  "Izmael",
  "Jacek",
  "Jáchym",
  "Jakub",
  "Jan",
  "Jarmil",
  "Jarolím",
  "Jaromil",
  "Jaromír",
  "Jaroslav",
  "Jason",
  "Jasoň",
  "Jeremiáš",
  "Jeroným",
  "Jiljí",
  "Jimram",
  "Jindřich",
  "Jiří",
  "Job",
  "Joel",
  "Jonáš",
  "Jonatan",
  "Jonathan",
  "Jordan",
  "Josef",
  "Jošt",
  "Jozef",
  "Jozue",
  "Juda",
  "Julián",
  "Julius",
  "Justin",
  "Justýn",
  "Kajetán",
  "Kamil",
  "Karel",
  "Kasián",
  "Kastor",
  "Kašpar",
  "Kazimír",
  "Kilián",
  "Kim",
  "Klaudián",
  "Klaudius",
  "Klement",
  "Kliment",
  "Knut",
  "Koloman",
  "Kolombín",
  "Kolumbán",
  "Kolumbín",
  "Konrád",
  "Konstantin",
  "Konstantýn",
  "Kornel",
  "Kornelius",
  "Kosma",
  "Kosmas",
  "Krasomil",
  "Krasoslav",
  "Kristián",
  "Kryšpín",
  "Kryštof",
  "Křesomysl",
  "Křišťan",
  "Kurt",
  "Květoň",
  "Květoslav",
  "Květoš",
  "Kvido",
  "Ladislav",
  "Lambert",
  "Lars",
  "Laurenc",
  "Lazar",
  "Leander",
  "Leandr",
  "Leo",
  "Leodegar",
  "Leon",
  "Leonard",
  "Leonid",
  "Leontýn",
  "Leopold",
  "Leoš",
  "Lešek",
  "Lev",
  "Libor",
  "Liboslav",
  "Lionel",
  "Livius",
  "Lorenc",
  "Lotar",
  "Lothar",
  "Lubomír",
  "Lubor",
  "Luboslav",
  "Luboš",
  "Lucián",
  "Lucius",
  "Luděk",
  "Ludivoj",
  "Ludomír",
  "Ludoslav",
  "Ludvík",
  "Lukáš",
  "Lukrecius",
  "Lumír",
  "Lutibor",
  "Lutobor",
  "Magnus",
  "Makar",
  "Manfred",
  "Manfréd",
  "Mansvet",
  "Manuel",
  "Marcel",
  "Marek",
  "Marian",
  "Marián",
  "Marin",
  "Mario",
  "Marius",
  "Martin",
  "Matěj",
  "Matouš",
  "Matyáš",
  "Max",
  "Maxim",
  "Maximilián",
  "Maxmilián",
  "Mečislav",
  "Medard",
  "Melichar",
  "Merlin",
  "Mervin",
  "Metod",
  "Metoděj",
  "Michael",
  "Michal",
  "Mikoláš",
  "Mikuláš",
  "Milan",
  "Milíč",
  "Milík",
  "Milivoj",
  "Miloň",
  "Milorad",
  "Miloslav",
  "Miloš",
  "Milota",
  "Milouš",
  "Milovan",
  "Milovín",
  "Milutín",
  "Mirek",
  "Mirko",
  "Miromil",
  "Miron",
  "Miroslav",
  "Mirtil",
  "Mlad",
  "Mladen",
  "Mnata",
  "Mnislav",
  "Modest",
  "Mojmír",
  "Mojžíš",
  "Morgan",
  "Moric",
  "Moris",
  "Mořic",
  "Mstislav",
  "Myron",
  "Myrtil",
  "Napoleon",
  "Narcis",
  "Natan",
  "Natanael",
  "Nathan",
  "Nathanael",
  "Něhoslav",
  "Neklan",
  "Nepomuk",
  "Nezamysl",
  "Nikita",
  "Nikodém",
  "Nikola",
  "Nikolas",
  "Norbert",
  "Norman",
  "Odolen",
  "Odon",
  "Oktavián",
  "Oktavius",
  "Olaf",
  "Olbram",
  "Oldřich",
  "Oleg",
  "Oliver",
  "Omar",
  "Ondřej",
  "Orest",
  "Oskar",
  "Osvald",
  "Ota",
  "Otakar",
  "Otmar",
  "Oto",
  "Otokar",
  "Otomar",
  "Ovidius",
  "Palmiro",
  "Pankrác",
  "Pantaleon",
  "Paris",
  "Parsival",
  "Paskal",
  "Patrik",
  "Pavel",
  "Pavlín",
  "Pelhřim",
  "Perikles",
  "Petr",
  "Petronius",
  "Pius",
  "Platon",
  "Platón",
  "Polykarp",
  "Pravdomil",
  "Pravomil",
  "Prokop",
  "Prosper",
  "Přemysl",
  "Přibyslav",
  "Radan",
  "Radegast",
  "Radek",
  "Radhost",
  "Radim",
  "Radimír",
  "Radislav",
  "Radivoj",
  "Radko",
  "Radmil",
  "Radomil",
  "Radomír",
  "Radoslav",
  "Radoš",
  "Radovan",
  "Radúz",
  "Radvan",
  "Rafael",
  "Raimund",
  "Rainald",
  "Rainer",
  "Rainhard",
  "Rainold",
  "Rajko",
  "Ralf",
  "Ramon",
  "Randolf",
  "Ranek",
  "Ranko",
  "Rastislav",
  "Ratibor",
  "Ratmír",
  "Redmond",
  "Reginald",
  "Remig",
  "Remus",
  "Renát",
  "René",
  "Richard",
  "Robert",
  "Robin",
  "Robinson",
  "Rodan",
  "Roderik",
  "Rodrigo",
  "Roger",
  "Roch",
  "Roland",
  "Rolf",
  "Roman",
  "Romeo",
  "Romuald",
  "Romul",
  "Romulus",
  "Ronald",
  "Rostislav",
  "Ruben",
  "Rudolf",
  "Rufus",
  "Rupert",
  "Ruprecht",
  "Ruslan",
  "Řehoř",
  "Sába",
  "Sámo",
  "Samson",
  "Samuel",
  "Saturnin",
  "Saul",
  "Sáva",
  "Sebastian",
  "Sebastián",
  "Sebestian",
  "Sedrik",
  "Serafín",
  "Serenus",
  "Sergej",
  "Servác",
  "Severín",
  "Sidon",
  "Sigfríd",
  "Silvan",
  "Silván",
  "Silvestr",
  "Silvius",
  "Simeon",
  "Simon",
  "Sinkler",
  "Sixt",
  "Sixtus",
  "Slávek",
  "Slaviboj",
  "Slavibor",
  "Slavoboj",
  "Slavoj",
  "Slavomil",
  "Slavomír",
  "Smil",
  "Soběslav",
  "Sokrat",
  "Soter",
  "Spytihněv",
  "Stanimír",
  "Stanislav",
  "Stojan",
  "Stojmír",
  "Svatoboj",
  "Svatobor",
  "Svatomír",
  "Svatopluk",
  "Svatoslav",
  "Sven",
  "Svetozar",
  "Šalamoun",
  "Šalomoun",
  "Šavel",
  "Šebastián",
  "Šimon",
  "Šťasta",
  "Štefan",
  "Štěpán",
  "Tadeáš",
  "Tankred",
  "Taras",
  "Teobald",
  "Teodor",
  "Teodorik",
  "Teodoz",
  "Teofan",
  "Teofil",
  "Terenc",
  "Terencius",
  "Theobald",
  "Theodor",
  "Theodorik",
  "Theofan",
  "Theofil",
  "Tiber",
  "Tiberius",
  "Tibor",
  "Tiburcius",
  "Tichomil",
  "Tichomír",
  "Tichon",
  "Timon",
  "Timotej",
  "Timoteus",
  "Timur",
  "Titus",
  "Tobiáš",
  "Tomáš",
  "Tomislav",
  "Tor",
  "Torkvát",
  "Torsten",
  "Tristan",
  "Udo",
  "Ulrich",
  "Upton",
  "Urban",
  "Uve",
  "Václav",
  "Vadim",
  "Valdemar",
  "Valentin",
  "Valentýn",
  "Valerián",
  "Valter",
  "Valtr",
  "Vasil",
  "Vavřinec",
  "Veleslav",
  "Velimír",
  "Velislav",
  "Věnceslav",
  "Vendelín",
  "Věnek",
  "Verner",
  "Věroslav",
  "Vidor",
  "Viktor",
  "Viktorin",
  "Viktorín",
  "Vilém",
  "Vilibald",
  "Vilmar",
  "Vincenc",
  "Virgil",
  "Virgin",
  "Vít",
  "Vítězslav",
  "Vitold",
  "Vítoslav",
  "Vivian",
  "Vladan",
  "Vladimír",
  "Vladislav",
  "Vladivoj",
  "Vlastimil",
  "Vlastimír",
  "Vlastislav",
  "Vlk",
  "Vojen",
  "Vojmil",
  "Vojmír",
  "Vojslav",
  "Vojtěch",
  "Vok",
  "Volfgang",
  "Vratislav",
  "Vsevolod",
  "Všeboj",
  "Všebor",
  "Všerad",
  "Všeslav",
  "Xaver",
  "Xaverius",
  "Záboj",
  "Zachar",
  "Zachariáš",
  "Záviš",
  "Zbislav",
  "Zbyhněv",
  "Zbyněk",
  "Zbyslav",
  "Zbyšek",
  "Zdeněk",
  "Zderad",
  "Zdeslav",
  "Zdík",
  "Zdirad",
  "Zdislav",
  "Zeno",
  "Zenon",
  "Zikmund",
  "Zlatan",
  "Zlatko",
  "Zlatomír",
  "Zoltán",
  "Zoran",
  "Zoroslav",
  "Zosim",
  "Zvonimír",
  "Žarko",
  "Ždan",
  "Želibor",
  "Želimír",
  "Želislav",
  "Želmír",
  "Žitomír",
  "Žitoslav",
  "Živan",
];
