module["exports"] = [
  "AP",
  "AR",
  "AS",
  "BR",
  "CG",
  "DL",
  "GA",
  "GJ",
  "HR",
  "HP",
  "JK",
  "JS",
  "KA",
  "KL",
  "MP",
  "MH",
  "MN",
  "ML",
  "MZ",
  "NL",
  "OR",
  "PB",
  "RJ",
  "SK",
  "TN",
  "TR",
  "UK",
  "UP",
  "WB",
  "AN",
  "CH",
  "DN",
  "DD",
  "LD",
  "PY"
];
