module["exports"] = [
  "აბაზაძე",
  "აბაშიძე",
  "აბრამაშვილი",
  "აბუსერიძე",
  "აბშილავა",
  "ავაზნელი",
  "ავალიშვილი",
  "ამილახვარი",
  "ანთაძე",
  "ასლამაზიშვილი",
  "ასპანიძე",
  "აშკარელი",
  "ახალბედაშვილი",
  "ახალკაცი",
  "ახვლედიანი",
  "ბარათაშვილი",
  "ბარდაველიძე",
  "ბახტაძე",
  "ბედიანიძე",
  "ბერიძე",
  "ბერუაშვილი",
  "ბეჟანიშვილი",
  "ბოგველიშვილი",
  "ბოტკოველი",
  "გაბრიჩიძე",
  "გაგნიძე",
  "გამრეკელი",
  "გელაშვილი",
  "გზირიშვილი",
  "გიგაური",
  "გურამიშვილი",
  "გურგენიძე",
  "დადიანი",
  "დავითიშვილი",
  "დათუაშვილი",
  "დარბაისელი",
  "დეკანოიძე",
  "დვალი",
  "დოლაბერიძე",
  "ედიშერაშვილი",
  "ელიზბარაშვილი",
  "ელიოზაშვილი",
  "ერისთავი",
  "ვარამაშვილი",
  "ვარდიაშვილი",
  "ვაჩნაძე",
  "ვარდანიძე",
  "ველიაშვილი",
  "ველიჯანაშვილი",
  "ზარანდია",
  "ზარიძე",
  "ზედგინიძე",
  "ზუბიაშვილი",
  "თაბაგარი",
  "თავდგირიძე",
  "თათარაშვილი",
  "თამაზაშვილი",
  "თამარაშვილი",
  "თაქთაქიშვილი",
  "თაყაიშვილი",
  "თბილელი",
  "თუხარელი",
  "იაშვილი",
  "იგითხანიშვილი",
  "ინასარიძე",
  "იშხნელი",
  "კანდელაკი",
  "კაცია",
  "კერესელიძე",
  "კვირიკაშვილი",
  "კიკნაძე",
  "კლდიაშვილი",
  "კოვზაძე",
  "კოპაძე",
  "კოპტონაშვილი",
  "კოშკელაშვილი",
  "ლაბაძე",
  "ლეკიშვილი",
  "ლიქოკელი",
  "ლოლაძე",
  "ლურსმანაშვილი",
  "მაისურაძე",
  "მარტოლეკი",
  "მაღალაძე",
  "მახარაშვილი",
  "მგალობლიშვილი",
  "მეგრელიშვილი",
  "მელაშვილი",
  "მელიქიძე",
  "მერაბიშვილი",
  "მეფარიშვილი",
  "მუჯირი",
  "მჭედლიძე",
  "მხეიძე",
  "ნათაძე",
  "ნაჭყებია",
  "ნოზაძე",
  "ოდიშვილი",
  "ონოფრიშვილი",
  "პარეხელაშვილი",
  "პეტრიაშვილი",
  "სააკაძე",
  "სააკაშვილი",
  "საგინაშვილი",
  "სადუნიშვილი",
  "საძაგლიშვილი",
  "სებისკვერიძე",
  "სეთური",
  "სუთიაშვილი",
  "სულაშვილი",
  "ტაბაღუა",
  "ტყეშელაშვილი",
  "ულუმბელაშვილი",
  "უნდილაძე",
  "ქავთარაძე",
  "ქართველიშვილი",
  "ყაზბეგი",
  "ყაუხჩიშვილი",
  "შავლაშვილი",
  "შალიკაშვილი",
  "შონია",
  "ჩიბუხაშვილი",
  "ჩიხრაძე",
  "ჩიქოვანი",
  "ჩუბინიძე",
  "ჩოლოყაშვილი",
  "ჩოხელი",
  "ჩხვიმიანი",
  "ცალუღელაშვილი",
  "ცაძიკიძე",
  "ციციშვილი",
  "ციხელაშვილი",
  "ციხისთავი",
  "ცხოვრებაძე",
  "ცხომარია",
  "წამალაიძე",
  "წერეთელი",
  "წიკლაური",
  "წიფურია",
  "ჭაბუკაშვილი",
  "ჭავჭავაძე",
  "ჭანტურია",
  "ჭარელიძე",
  "ჭიორელი",
  "ჭუმბურიძე",
  "ხაბაზი",
  "ხარაძე",
  "ხარატიშვილი",
  "ხარატასშვილი",
  "ხარისჭირაშვილი",
  "ხარხელაური",
  "ხაშმელაშვილი",
  "ხეთაგური",
  "ხიზამბარელი",
  "ხიზანიშვილი",
  "ხიმშიაშვილი",
  "ხოსრუაშვილი",
  "ხოჯივანიშვილი",
  "ხუციშვილი",
  "ჯაბადარი",
  "ჯავახი",
  "ჯავახიშვილი",
  "ჯანელიძე",
  "ჯაფარიძე",
  "ჯაყელი",
  "ჯაჯანიძე",
  "ჯვარელია",
  "ჯინიუზაშვილი",
  "ჯუღაშვილი"
];
