module["exports"] = [
  "(+995 32) 2-##-##-##",
  "032-2-##-##-##",
  "032-2-######",
  "032-2-###-###",
  "032 2 ## ## ##",
  "032 2 ######",
  "2 ## ## ##",
  "2######",
  "2 ### ###"
];
