module["exports"] = [
  "Boot",
  "I",
  "Nu",
  "Nur",
  "Tu",
  "Um",
  "a",
  "becoose-a",
  "boot",
  "bork",
  "burn",
  "chuuses",
  "cumplete-a",
  "cun",
  "cunseqooences",
  "curcoomstunces",
  "dee",
  "deeslikes",
  "denuoonceeng",
  "desures",
  "du",
  "eccuoont",
  "ectooel",
  "edfuntege-a",
  "efueeds",
  "egeeen",
  "ell",
  "ere-a",
  "feend",
  "foolt",
  "frum",
  "geefe-a",
  "gesh",
  "greet",
  "heem",
  "heppeeness",
  "hes",
  "hoo",
  "hoomun",
  "idea",
  "ifer",
  "in",
  "incuoonter",
  "injuy",
  "itselff",
  "ixcept",
  "ixemple-a",
  "ixerceese-a",
  "ixpleeen",
  "ixplurer",
  "ixpuoond",
  "ixtremely",
  "knoo",
  "lebureeuoos",
  "lufes",
  "meestekee",
  "mester-booeelder",
  "moost",
  "mun",
  "nu",
  "nut",
  "oobteeen",
  "oocceseeunelly",
  "ooccoor",
  "ooff",
  "oone-a",
  "oor",
  "peeen",
  "peeenffool",
  "physeecel",
  "pleesoore-a",
  "poorsooe-a",
  "poorsooes",
  "preeesing",
  "prucoore-a",
  "prudooces",
  "reeght",
  "reshunelly",
  "resooltunt",
  "sume-a",
  "teecheengs",
  "teke-a",
  "thees",
  "thet",
  "thuse-a",
  "treefiel",
  "troot",
  "tu",
  "tueel",
  "und",
  "undertekes",
  "unnuyeeng",
  "uny",
  "unyune-a",
  "us",
  "veell",
  "veet",
  "ves",
  "vheech",
  "vhu",
  "yuoo",
  "zee",
  "zeere-a"
];
