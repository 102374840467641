module["exports"] = [
  "(044) ###-##-##",
  "(050) ###-##-##",
  "(063) ###-##-##",
  "(066) ###-##-##",
  "(073) ###-##-##",
  "(091) ###-##-##",
  "(092) ###-##-##",
  "(093) ###-##-##",
  "(094) ###-##-##",
  "(095) ###-##-##",
  "(096) ###-##-##",
  "(097) ###-##-##",
  "(098) ###-##-##",
  "(099) ###-##-##"
];
