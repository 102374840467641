var pl = {};
module['exports'] = pl;
pl.title = "Polish";
pl.name = require("./name");
pl.address = require("./address");
pl.company = require("./company");
pl.internet = require("./internet");
pl.lorem = require("./lorem");
pl.phone_number = require("./phone_number");
pl.cell_phone = require("./cell_phone");
