module["exports"] = [
  "AG",
  "GmbH",
  "und Söhne",
  "und Partner",
  "& Co.",
  "Gruppe",
  "LLC",
  "Inc."
];
