module["exports"] = [
  "Adaptive",
  "Advanced",
  "Ameliorated",
  "Assimilated",
  "Automated",
  "Balanced",
  "Business-focused",
  "Centralized",
  "Cloned",
  "Compatible",
  "Configurable",
  "Cross-group",
  "Cross-platform",
  "Customer-focused",
  "Customizable",
  "Decentralized",
  "De-engineered",
  "Devolved",
  "Digitized",
  "Distributed",
  "Diverse",
  "Down-sized",
  "Enhanced",
  "Enterprise-wide",
  "Ergonomic",
  "Exclusive",
  "Expanded",
  "Extended",
  "Face to face",
  "Focused",
  "Front-line",
  "Fully-configurable",
  "Function-based",
  "Fundamental",
  "Future-proofed",
  "Grass-roots",
  "Horizontal",
  "Implemented",
  "Innovative",
  "Integrated",
  "Intuitive",
  "Inverse",
  "Managed",
  "Mandatory",
  "Monitored",
  "Multi-channelled",
  "Multi-lateral",
  "Multi-layered",
  "Multi-tiered",
  "Networked",
  "Object-based",
  "Open-architected",
  "Open-source",
  "Operative",
  "Optimized",
  "Optional",
  "Organic",
  "Organized",
  "Persevering",
  "Persistent",
  "Phased",
  "Polarised",
  "Pre-emptive",
  "Proactive",
  "Profit-focused",
  "Profound",
  "Programmable",
  "Progressive",
  "Public-key",
  "Quality-focused",
  "Reactive",
  "Realigned",
  "Re-contextualized",
  "Re-engineered",
  "Reduced",
  "Reverse-engineered",
  "Right-sized",
  "Robust",
  "Seamless",
  "Secured",
  "Self-enabling",
  "Sharable",
  "Stand-alone",
  "Streamlined",
  "Switchable",
  "Synchronised",
  "Synergistic",
  "Synergized",
  "Team-oriented",
  "Total",
  "Triple-buffered",
  "Universal",
  "Up-sized",
  "Upgradable",
  "User-centric",
  "User-friendly",
  "Versatile",
  "Virtual",
  "Visionary",
  "Vision-oriented"
];
