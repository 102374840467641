module["exports"] = [
  "北",
  "新北",
  "桃",
  "中",
  "南",
  "高",
  "基",
  "竹市",
  "嘉市",
  "竹縣",
  "苗",
  "彰",
  "投",
  "雲",
  "嘉縣",
  "宜",
  "花",
  "東",
  "澎",
  "金",
  "馬"
];
