module["exports"] = [
  "#",
  "##",
  "###",
  "###a",
  "###b",
  "###c",
  "### I",
  "### II",
  "### III"
];
