module["exports"] = [
  "Зелена",
  "Молодіжна",
  "Городоцька",
  "Стрийська",
  "Вузька",
  "Нижанківського",
  "Староміська",
  "Ліста",
  "Вічева",
  "Брюховичів",
  "Винників",
  "Рудного",
  "Коліївщини"
];
