module["exports"] = [
  "佐藤",
  "鈴木",
  "高橋",
  "田中",
  "渡辺",
  "伊藤",
  "山本",
  "中村",
  "小林",
  "加藤",
  "吉田",
  "山田",
  "佐々木",
  "山口",
  "斎藤",
  "松本",
  "井上",
  "木村",
  "林",
  "清水"
];
