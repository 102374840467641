module["exports"] = [
  "Андрухович",
  "Бабух",
  "Балабан",
  "Балабуха",
  "Балакун",
  "Балицька",
  "Бамбула",
  "Бандера",
  "Барановська",
  "Бачей",
  "Башук",
  "Бердник",
  "Білич",
  "Бондаренко",
  "Борецька",
  "Боровська",
  "Борочко",
  "Боярчук",
  "Брицька",
  "Бурмило",
  "Бутько",
  "Василишина",
  "Васильківська",
  "Вергун",
  "Вередун",
  "Верещук",
  "Витребенько",
  "Вітряк",
  "Волощук",
  "Гайдук",
  "Гайова",
  "Гайчук",
  "Галаєнко",
  "Галатей",
  "Галаціон",
  "Гаман",
  "Гамула",
  "Ганич",
  "Гарай",
  "Гарун",
  "Гладківська",
  "Гладух",
  "Глинська",
  "Гнатишина",
  "Гойко",
  "Головець",
  "Горбач",
  "Гордійчук",
  "Горова",
  "Городоцька",
  "Гречко",
  "Григоришина",
  "Гриневецька",
  "Гриневська",
  "Гришко",
  "Громико",
  "Данилишина",
  "Данилко",
  "Демків",
  "Демчишина",
  "Дзюб’як",
  "Дзюба",
  "Дідух",
  "Дмитришина",
  "Дмитрук",
  "Довгалевська",
  "Дурдинець",
  "Євенко",
  "Євпак",
  "Ємець",
  "Єрмак",
  "Забіла",
  "Зварич",
  "Зінкевич",
  "Зленко",
  "Іванишина",
  "Калач",
  "Кандиба",
  "Карпух",
  "Кивач",
  "Коваленко",
  "Ковальська",
  "Коломієць",
  "Коман",
  "Компанієць",
  "Кононець",
  "Кордун",
  "Корецька",
  "Корнїйчук",
  "Коров’як",
  "Коцюбинська",
  "Кулинич",
  "Кульчицька",
  "Лагойда",
  "Лазірко",
  "Ланова",
  "Латан",
  "Латанська",
  "Лахман",
  "Левадовська",
  "Ликович",
  "Линдик",
  "Ліхно",
  "Лобачевська",
  "Ломова",
  "Лугова",
  "Луцька",
  "Луцьків",
  "Лученко",
  "Лучко",
  "Люта",
  "Лящук",
  "Магера",
  "Мазайло",
  "Мазило",
  "Мазун",
  "Майборода",
  "Майстренко",
  "Маковецька",
  "Малкович",
  "Мамій",
  "Маринич",
  "Марієвська",
  "Марків",
  "Махно",
  "Миклашевська",
  "Миклухо",
  "Милославська",
  "Михайлюк",
  "Міняйло",
  "Могилевська",
  "Москаль",
  "Москалюк",
  "Мотрієнко",
  "Негода",
  "Ногачевська",
  "Опенько",
  "Осадко",
  "Павленко",
  "Павлишина",
  "Павлів",
  "Пагутяк",
  "Паламарчук",
  "Палій",
  "Паращук",
  "Пасічник",
  "Пендик",
  "Петик",
  "Петлюра",
  "Петренко",
  "Петрина",
  "Петришина",
  "Петрів",
  "Плаксій",
  "Погиба",
  "Поліщук",
  "Пономарів",
  "Поривай",
  "Поривайло",
  "Потебенько",
  "Потоцька",
  "Пригода",
  "Приймак",
  "Притула",
  "Прядун",
  "Розпутня",
  "Романишина",
  "Ромей",
  "Роменець",
  "Ромочко",
  "Савицька",
  "Саєнко",
  "Свидригайло",
  "Семеночко",
  "Семещук",
  "Сердюк",
  "Силецька",
  "Сідлецька",
  "Сідляк",
  "Сірко",
  "Скиба",
  "Скоропадська",
  "Слободян",
  "Сосюра",
  "Сплюха",
  "Спотикач",
  "Степанець",
  "Стигайло",
  "Сторожук",
  "Сторчак",
  "Стоян",
  "Сучак",
  "Сушко",
  "Тарасюк",
  "Тиндарей",
  "Ткаченко",
  "Третяк",
  "Троян",
  "Трублаєвська",
  "Трясило",
  "Трясун",
  "Уманець",
  "Унич",
  "Усич",
  "Федоришина",
  "Цушко",
  "Червоній",
  "Шамрило",
  "Шевченко",
  "Шестак",
  "Шиндарей",
  "Шиян",
  "Шкараба",
  "Шудрик",
  "Шумило",
  "Шупик",
  "Шухевич",
  "Щербак",
  "Юрчишина",
  "Юхно",
  "Ющик",
  "Ющук",
  "Яворівська",
  "Ялова",
  "Ялюк",
  "Янюк",
  "Ярмак",
  "Яцишина",
  "Яцьків",
  "Ящук"
];
