module["exports"] = [
  "ala",
  "açıq bənövşəyi",
  "ağ",
  "mavi",
  "boz",
  "bənövşəyi",
  "göy rəng",
  "gümüşü",
  "kardinal",
  "narıncı",
  "qara",
  "qırmızı",
  "qəhvəyi",
  "tünd göy",
  "tünd qırmızı",
  "xlorofil",
  "yaşıl",
  "çəhrayı"
];
