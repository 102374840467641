module["exports"] = [
  "New South Wales",
  "Queensland",
  "Northern Territory",
  "South Australia",
  "Western Australia",
  "Tasmania",
  "Australian Capital Territory",
  "Victoria"
];
