module["exports"] = [
  "Adamczak",
  "Adamczyk",
  "Adamek",
  "Adamiak",
  "Adamiec",
  "Adamowicz",
  "Adamski",
  "Adamus",
  "Aleksandrowicz",
  "Andrzejczak",
  "Andrzejewski",
  "Antczak",
  "Augustyn",
  "Augustyniak",
  "Bagiński",
  "Balcerzak",
  "Banach",
  "Banasiak",
  "Banasik",
  "Banaś",
  "Baran",
  "Baranowski",
  "Barański",
  "Bartczak",
  "Bartkowiak",
  "Bartnik",
  "Bartosik",
  "Bednarczyk",
  "Bednarek",
  "Bednarski",
  "Bednarz",
  "Białas",
  "Białek",
  "Białkowski",
  "Bielak",
  "Bielawski",
  "Bielecki",
  "Bielski",
  "Bieniek",
  "Biernacki",
  "Biernat",
  "Bieńkowski",
  "Bilski",
  "Bober",
  "Bochenek",
  "Bogucki",
  "Bogusz",
  "Borek",
  "Borkowski",
  "Borowiec",
  "Borowski",
  "Bożek",
  "Broda",
  "Brzeziński",
  "Brzozowski",
  "Buczek",
  "Buczkowski",
  "Buczyński",
  "Budziński",
  "Budzyński",
  "Bujak",
  "Bukowski",
  "Burzyński",
  "Bąk",
  "Bąkowski",
  "Błaszczak",
  "Błaszczyk",
  "Cebula",
  "Chmiel",
  "Chmielewski",
  "Chmura",
  "Chojnacki",
  "Chojnowski",
  "Cholewa",
  "Chrzanowski",
  "Chudzik",
  "Cichocki",
  "Cichoń",
  "Cichy",
  "Ciesielski",
  "Cieśla",
  "Cieślak",
  "Cieślik",
  "Ciszewski",
  "Cybulski",
  "Cygan",
  "Czaja",
  "Czajka",
  "Czajkowski",
  "Czapla",
  "Czarnecki",
  "Czech",
  "Czechowski",
  "Czekaj",
  "Czerniak",
  "Czerwiński",
  "Czyż",
  "Czyżewski",
  "Dec",
  "Dobosz",
  "Dobrowolski",
  "Dobrzyński",
  "Domagała",
  "Domański",
  "Dominiak",
  "Drabik",
  "Drozd",
  "Drozdowski",
  "Drzewiecki",
  "Dróżdż",
  "Dubiel",
  "Duda",
  "Dudek",
  "Dudziak",
  "Dudzik",
  "Dudziński",
  "Duszyński",
  "Dziedzic",
  "Dziuba",
  "Dąbek",
  "Dąbkowski",
  "Dąbrowski",
  "Dębowski",
  "Dębski",
  "Długosz",
  "Falkowski",
  "Fijałkowski",
  "Filipek",
  "Filipiak",
  "Filipowicz",
  "Flak",
  "Flis",
  "Florczak",
  "Florek",
  "Frankowski",
  "Frąckowiak",
  "Frączek",
  "Frątczak",
  "Furman",
  "Gadomski",
  "Gajda",
  "Gajewski",
  "Gaweł",
  "Gawlik",
  "Gawron",
  "Gawroński",
  "Gałka",
  "Gałązka",
  "Gil",
  "Godlewski",
  "Golec",
  "Gołąb",
  "Gołębiewski",
  "Gołębiowski",
  "Grabowski",
  "Graczyk",
  "Grochowski",
  "Grudzień",
  "Gruszczyński",
  "Gruszka",
  "Grzegorczyk",
  "Grzelak",
  "Grzesiak",
  "Grzesik",
  "Grześkowiak",
  "Grzyb",
  "Grzybowski",
  "Grzywacz",
  "Gutowski",
  "Guzik",
  "Gwóźdź",
  "Góra",
  "Góral",
  "Górecki",
  "Górka",
  "Górniak",
  "Górny",
  "Górski",
  "Gąsior",
  "Gąsiorowski",
  "Głogowski",
  "Głowacki",
  "Głąb",
  "Hajduk",
  "Herman",
  "Iwański",
  "Izdebski",
  "Jabłoński",
  "Jackowski",
  "Jagielski",
  "Jagiełło",
  "Jagodziński",
  "Jakubiak",
  "Jakubowski",
  "Janas",
  "Janiak",
  "Janicki",
  "Janik",
  "Janiszewski",
  "Jankowiak",
  "Jankowski",
  "Janowski",
  "Janus",
  "Janusz",
  "Januszewski",
  "Jaros",
  "Jarosz",
  "Jarząbek",
  "Jasiński",
  "Jastrzębski",
  "Jaworski",
  "Jaśkiewicz",
  "Jezierski",
  "Jurek",
  "Jurkiewicz",
  "Jurkowski",
  "Juszczak",
  "Jóźwiak",
  "Jóźwik",
  "Jędrzejczak",
  "Jędrzejczyk",
  "Jędrzejewski",
  "Kacprzak",
  "Kaczmarczyk",
  "Kaczmarek",
  "Kaczmarski",
  "Kaczor",
  "Kaczorowski",
  "Kaczyński",
  "Kaleta",
  "Kalinowski",
  "Kalisz",
  "Kamiński",
  "Kania",
  "Kaniewski",
  "Kapusta",
  "Karaś",
  "Karczewski",
  "Karpiński",
  "Karwowski",
  "Kasperek",
  "Kasprzak",
  "Kasprzyk",
  "Kaszuba",
  "Kawa",
  "Kawecki",
  "Kałuża",
  "Kaźmierczak",
  "Kiełbasa",
  "Kisiel",
  "Kita",
  "Klimczak",
  "Klimek",
  "Kmiecik",
  "Kmieć",
  "Knapik",
  "Kobus",
  "Kogut",
  "Kolasa",
  "Komorowski",
  "Konieczna",
  "Konieczny",
  "Konopka",
  "Kopczyński",
  "Koper",
  "Kopeć",
  "Korzeniowski",
  "Kos",
  "Kosiński",
  "Kosowski",
  "Kostecki",
  "Kostrzewa",
  "Kot",
  "Kotowski",
  "Kowal",
  "Kowalczuk",
  "Kowalczyk",
  "Kowalewski",
  "Kowalik",
  "Kowalski",
  "Koza",
  "Kozak",
  "Kozieł",
  "Kozioł",
  "Kozłowski",
  "Kołakowski",
  "Kołodziej",
  "Kołodziejczyk",
  "Kołodziejski",
  "Krajewski",
  "Krakowiak",
  "Krawczyk",
  "Krawiec",
  "Kruk",
  "Krukowski",
  "Krupa",
  "Krupiński",
  "Kruszewski",
  "Krysiak",
  "Krzemiński",
  "Krzyżanowski",
  "Król",
  "Królikowski",
  "Książek",
  "Kubacki",
  "Kubiak",
  "Kubica",
  "Kubicki",
  "Kubik",
  "Kuc",
  "Kucharczyk",
  "Kucharski",
  "Kuchta",
  "Kuciński",
  "Kuczyński",
  "Kujawa",
  "Kujawski",
  "Kula",
  "Kulesza",
  "Kulig",
  "Kulik",
  "Kuliński",
  "Kurek",
  "Kurowski",
  "Kuś",
  "Kwaśniewski",
  "Kwiatkowski",
  "Kwiecień",
  "Kwieciński",
  "Kędzierski",
  "Kędziora",
  "Kępa",
  "Kłos",
  "Kłosowski",
  "Lach",
  "Laskowski",
  "Lasota",
  "Lech",
  "Lenart",
  "Lesiak",
  "Leszczyński",
  "Lewandowski",
  "Lewicki",
  "Leśniak",
  "Leśniewski",
  "Lipiński",
  "Lipka",
  "Lipski",
  "Lis",
  "Lisiecki",
  "Lisowski",
  "Maciejewski",
  "Maciąg",
  "Mackiewicz",
  "Madej",
  "Maj",
  "Majcher",
  "Majchrzak",
  "Majewski",
  "Majka",
  "Makowski",
  "Malec",
  "Malicki",
  "Malinowski",
  "Maliszewski",
  "Marchewka",
  "Marciniak",
  "Marcinkowski",
  "Marczak",
  "Marek",
  "Markiewicz",
  "Markowski",
  "Marszałek",
  "Marzec",
  "Masłowski",
  "Matusiak",
  "Matuszak",
  "Matuszewski",
  "Matysiak",
  "Mazur",
  "Mazurek",
  "Mazurkiewicz",
  "Maćkowiak",
  "Małecki",
  "Małek",
  "Maślanka",
  "Michalak",
  "Michalczyk",
  "Michalik",
  "Michalski",
  "Michałek",
  "Michałowski",
  "Mielczarek",
  "Mierzejewski",
  "Mika",
  "Mikołajczak",
  "Mikołajczyk",
  "Mikulski",
  "Milczarek",
  "Milewski",
  "Miller",
  "Misiak",
  "Misztal",
  "Miśkiewicz",
  "Modzelewski",
  "Molenda",
  "Morawski",
  "Motyka",
  "Mroczek",
  "Mroczkowski",
  "Mrozek",
  "Mróz",
  "Mucha",
  "Murawski",
  "Musiał",
  "Muszyński",
  "Młynarczyk",
  "Napierała",
  "Nawrocki",
  "Nawrot",
  "Niedziela",
  "Niedzielski",
  "Niedźwiecki",
  "Niemczyk",
  "Niemiec",
  "Niewiadomski",
  "Noga",
  "Nowacki",
  "Nowaczyk",
  "Nowak",
  "Nowakowski",
  "Nowicki",
  "Nowiński",
  "Olczak",
  "Olejniczak",
  "Olejnik",
  "Olszewski",
  "Orzechowski",
  "Orłowski",
  "Osiński",
  "Ossowski",
  "Ostrowski",
  "Owczarek",
  "Paczkowski",
  "Pająk",
  "Pakuła",
  "Paluch",
  "Panek",
  "Partyka",
  "Pasternak",
  "Paszkowski",
  "Pawelec",
  "Pawlak",
  "Pawlicki",
  "Pawlik",
  "Pawlikowski",
  "Pawłowski",
  "Pałka",
  "Piasecki",
  "Piechota",
  "Piekarski",
  "Pietras",
  "Pietruszka",
  "Pietrzak",
  "Pietrzyk",
  "Pilarski",
  "Pilch",
  "Piotrowicz",
  "Piotrowski",
  "Piwowarczyk",
  "Piórkowski",
  "Piątek",
  "Piątkowski",
  "Piłat",
  "Pluta",
  "Podgórski",
  "Polak",
  "Popławski",
  "Porębski",
  "Prokop",
  "Prus",
  "Przybylski",
  "Przybysz",
  "Przybył",
  "Przybyła",
  "Ptak",
  "Puchalski",
  "Pytel",
  "Płonka",
  "Raczyński",
  "Radecki",
  "Radomski",
  "Rak",
  "Rakowski",
  "Ratajczak",
  "Robak",
  "Rogala",
  "Rogalski",
  "Rogowski",
  "Rojek",
  "Romanowski",
  "Rosa",
  "Rosiak",
  "Rosiński",
  "Ruciński",
  "Rudnicki",
  "Rudziński",
  "Rudzki",
  "Rusin",
  "Rutkowski",
  "Rybak",
  "Rybarczyk",
  "Rybicki",
  "Rzepka",
  "Różański",
  "Różycki",
  "Sadowski",
  "Sawicki",
  "Serafin",
  "Siedlecki",
  "Sienkiewicz",
  "Sieradzki",
  "Sikora",
  "Sikorski",
  "Sitek",
  "Siwek",
  "Skalski",
  "Skiba",
  "Skibiński",
  "Skoczylas",
  "Skowron",
  "Skowronek",
  "Skowroński",
  "Skrzypczak",
  "Skrzypek",
  "Skóra",
  "Smoliński",
  "Sobczak",
  "Sobczyk",
  "Sobieraj",
  "Sobolewski",
  "Socha",
  "Sochacki",
  "Sokołowski",
  "Sokół",
  "Sosnowski",
  "Sowa",
  "Sowiński",
  "Sołtys",
  "Sołtysiak",
  "Sroka",
  "Stachowiak",
  "Stachowicz",
  "Stachura",
  "Stachurski",
  "Stanek",
  "Staniszewski",
  "Stanisławski",
  "Stankiewicz",
  "Stasiak",
  "Staszewski",
  "Stawicki",
  "Stec",
  "Stefaniak",
  "Stefański",
  "Stelmach",
  "Stolarczyk",
  "Stolarski",
  "Strzelczyk",
  "Strzelecki",
  "Stępień",
  "Stępniak",
  "Surma",
  "Suski",
  "Szafrański",
  "Szatkowski",
  "Szczepaniak",
  "Szczepanik",
  "Szczepański",
  "Szczerba",
  "Szcześniak",
  "Szczygieł",
  "Szczęsna",
  "Szczęsny",
  "Szeląg",
  "Szewczyk",
  "Szostak",
  "Szulc",
  "Szwarc",
  "Szwed",
  "Szydłowski",
  "Szymański",
  "Szymczak",
  "Szymczyk",
  "Szymkowiak",
  "Szyszka",
  "Sławiński",
  "Słowik",
  "Słowiński",
  "Tarnowski",
  "Tkaczyk",
  "Tokarski",
  "Tomala",
  "Tomaszewski",
  "Tomczak",
  "Tomczyk",
  "Tracz",
  "Trojanowski",
  "Trzciński",
  "Trzeciak",
  "Turek",
  "Twardowski",
  "Urban",
  "Urbanek",
  "Urbaniak",
  "Urbanowicz",
  "Urbańczyk",
  "Urbański",
  "Walczak",
  "Walkowiak",
  "Warchoł",
  "Wasiak",
  "Wasilewski",
  "Wawrzyniak",
  "Wesołowski",
  "Wieczorek",
  "Wierzbicki",
  "Wilczek",
  "Wilczyński",
  "Wilk",
  "Winiarski",
  "Witczak",
  "Witek",
  "Witkowski",
  "Wiącek",
  "Więcek",
  "Więckowski",
  "Wiśniewski",
  "Wnuk",
  "Wojciechowski",
  "Wojtas",
  "Wojtasik",
  "Wojtczak",
  "Wojtkowiak",
  "Wolak",
  "Woliński",
  "Wolny",
  "Wolski",
  "Woś",
  "Woźniak",
  "Wrona",
  "Wroński",
  "Wróbel",
  "Wróblewski",
  "Wypych",
  "Wysocki",
  "Wyszyński",
  "Wójcicki",
  "Wójcik",
  "Wójtowicz",
  "Wąsik",
  "Węgrzyn",
  "Włodarczyk",
  "Włodarski",
  "Zaborowski",
  "Zabłocki",
  "Zagórski",
  "Zając",
  "Zajączkowski",
  "Zakrzewski",
  "Zalewski",
  "Zaremba",
  "Zarzycki",
  "Zaręba",
  "Zawada",
  "Zawadzki",
  "Zdunek",
  "Zieliński",
  "Zielonka",
  "Ziółkowski",
  "Zięba",
  "Ziętek",
  "Zwoliński",
  "Zych",
  "Zygmunt",
  "Łapiński",
  "Łuczak",
  "Łukasiewicz",
  "Łukasik",
  "Łukaszewski",
  "Śliwa",
  "Śliwiński",
  "Ślusarczyk",
  "Świderski",
  "Świerczyński",
  "Świątek",
  "Żak",
  "Żebrowski",
  "Żmuda",
  "Żuk",
  "Żukowski",
  "Żurawski",
  "Żurek",
  "Żyła"
];
