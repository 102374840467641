module["exports"] = [
  "red",
  "green",
  "blue",
  "yellow",
  "purple",
  "mint green",
  "teal",
  "white",
  "black",
  "orange",
  "pink",
  "grey",
  "maroon",
  "violet",
  "turquoise",
  "tan",
  "sky blue",
  "salmon",
  "plum",
  "orchid",
  "olive",
  "magenta",
  "lime",
  "ivory",
  "indigo",
  "gold",
  "fuchsia",
  "cyan",
  "azure",
  "lavender",
  "silver"
];
