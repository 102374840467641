module["exports"] = [
  "Adaptativo",
  "Avanzado",
  "Asimilado",
  "Automatizado",
  "Equilibrado",
  "Centrado en el negocio",
  "Centralizado",
  "Clonado",
  "Compatible",
  "Configurable",
  "Multi grupo",
  "Multi plataforma",
  "Centrado en el usuario",
  "Configurable",
  "Descentralizado",
  "Digitalizado",
  "Distribuido",
  "Diverso",
  "Reducido",
  "Mejorado",
  "Para toda la empresa",
  "Ergonomico",
  "Exclusivo",
  "Expandido",
  "Extendido",
  "Cara a cara",
  "Enfocado",
  "Totalmente configurable",
  "Fundamental",
  "Orígenes",
  "Horizontal",
  "Implementado",
  "Innovador",
  "Integrado",
  "Intuitivo",
  "Inverso",
  "Gestionado",
  "Obligatorio",
  "Monitorizado",
  "Multi canal",
  "Multi lateral",
  "Multi capa",
  "En red",
  "Orientado a objetos",
  "Open-source",
  "Operativo",
  "Optimizado",
  "Opcional",
  "Organico",
  "Organizado",
  "Perseverando",
  "Persistente",
  "en fases",
  "Polarizado",
  "Pre-emptivo",
  "Proactivo",
  "Enfocado a benficios",
  "Profundo",
  "Programable",
  "Progresivo",
  "Public-key",
  "Enfocado en la calidad",
  "Reactivo",
  "Realineado",
  "Re-contextualizado",
  "Re-implementado",
  "Reducido",
  "Ingenieria inversa",
  "Robusto",
  "Fácil",
  "Seguro",
  "Auto proporciona",
  "Compartible",
  "Intercambiable",
  "Sincronizado",
  "Orientado a equipos",
  "Total",
  "Universal",
  "Mejorado",
  "Actualizable",
  "Centrado en el usuario",
  "Amigable",
  "Versatil",
  "Virtual",
  "Visionario"
];
