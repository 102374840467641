module["exports"] = [
  "com",
  "net",
  "org",
  "asia",
  "tv",
  "biz",
  "info",
  "in",
  "name",
  "co",
  "ac.id",
  "sch.id",
  "go.id",
  "mil.id",
  "co.id",
  "or.id",
  "web.id",
  "my.id",
  "biz.id",
  "desa.id"
];