module["exports"] = [
  "Bondi",
  "Burleigh Heads",
  "Carlton",
  "Fitzroy",
  "Fremantle",
  "Glenelg",
  "Manly",
  "Noosa",
  "Stones Corner",
  "St Kilda",
  "Surry Hills",
  "Yarra Valley"
];
