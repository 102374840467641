module["exports"] = [
  "Akrotiri və Dekeliya",
  "Aland adaları",
  "Albaniya",
  "Almaniya",
  "Amerika Samoası",
  "Andorra",
  "Angilya",
  "Anqola",
  "Antiqua və Barbuda",
  "Argentina",
  "Aruba",
  "Avstraliya",
  "Avstriya",
  "Azərbaycan",
  "Baham adaları",
  "Banqladeş",
  "Barbados",
  "Belçika",
  "Beliz",
  "Belarus",
  "Benin",
  "Bermud adaları",
  "BƏƏ",
  "ABŞ",
  "Boliviya",
  "Bolqarıstan",
  "Bosniya və Herseqovina",
  "Botsvana",
  "Böyük Britaniya",
  "Braziliya",
  "Bruney",
  "Burkina-Faso",
  "Burundi",
  "Butan",
  "Bəhreyn",
  "Cersi",
  "Cəbəli-Tariq",
  "CAR",
  "Cənubi Sudan",
  "Cənubi Koreya",
  "Cibuti",
  "Çad",
  "Çexiya",
  "Monteneqro",
  "Çili",
  "ÇXR",
  "Danimarka",
  "Dominika",
  "Dominikan Respublikası",
  "Efiopiya",
  "Ekvador",
  "Ekvatorial Qvineya",
  "Eritreya",
  "Ermənistan",
  "Estoniya",
  "Əfqanıstan",
  "Əlcəzair",
  "Farer adaları",
  "Fələstin Dövləti",
  "Fici",
  "Kot-d’İvuar",
  "Filippin",
  "Finlandiya",
  "Folklend adaları",
  "Fransa",
  "Fransa Polineziyası",
  "Gernsi",
  "Gürcüstan",
  "Haiti",
  "Hindistan",
  "Honduras",
  "Honkonq",
  "Xorvatiya",
  "İndoneziya",
  "İordaniya",
  "İraq",
  "İran",
  "İrlandiya",
  "İslandiya",
  "İspaniya",
  "İsrail",
  "İsveç",
  "İsveçrə",
  "İtaliya",
  "Kabo-Verde",
  "Kamboca",
  "Kamerun",
  "Kanada",
  "Kayman adaları",
  "Keniya",
  "Kipr",
  "Kiribati",
  "Kokos adaları",
  "Kolumbiya",
  "Komor adaları",
  "Konqo Respublikası",
  "KDR",
  "Kosovo",
  "Kosta-Rika",
  "Kuba",
  "Kuk adaları",
  "Küveyt",
  "Qabon",
  "Qambiya",
  "Qana",
  "Qətər",
  "Qayana",
  "Qazaxıstan",
  "Qərbi Sahara",
  "Qırğızıstan",
  "Qrenada",
  "Qrenlandiya",
  "Quam",
  "Qvatemala",
  "Qvineya",
  "Qvineya-Bisau",
  "Laos",
  "Latviya",
  "Lesoto",
  "Liberiya",
  "Litva",
  "Livan",
  "Liviya",
  "Lixtenşteyn",
  "Lüksemburq",
  "Macarıstan",
  "Madaqaskar",
  "Makao",
  "Makedoniya",
  "Malavi",
  "Malayziya",
  "Maldiv adaları",
  "Mali",
  "Malta",
  "Marşall adaları",
  "Mavriki",
  "Mavritaniya",
  "Mayotta",
  "Meksika",
  "Men adası",
  "Mərakeş",
  "MAR",
  "Mikroneziya",
  "Milad adası",
  "Misir",
  "Myanma",
  "Moldova",
  "Monako",
  "Monqolustan",
  "Montserrat",
  "Mozambik",
  "Müqəddəs Yelena, Askenson və Tristan-da-Kunya adaları",
  "Namibiya",
  "Nauru",
  "Nepal",
  "Niderland",
  "Niderland Antil adaları",
  "Niger",
  "Nigeriya",
  "Nikaraqua",
  "Niue",
  "Norfolk adası",
  "Norveç",
  "Oman",
  "Özbəkistan",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua-Yeni Qvineya",
  "Paraqvay",
  "Peru",
  "Pitkern adaları",
  "Polşa",
  "Portuqaliya",
  "Prednestroviya",
  "Puerto-Riko",
  "Ruanda",
  "Rumıniya",
  "Rusiya",
  "Salvador",
  "Samoa",
  "San-Marino",
  "San-Tome və Prinsipi",
  "Seneqal",
  "Sen-Bartelemi",
  "Sent-Kits və Nevis",
  "Sent-Lüsiya",
  "Sen-Marten",
  "Sen-Pyer və Mikelon",
  "Sent-Vinsent və Qrenadina",
  "Serbiya",
  "Seyşel adaları",
  "Səudiyyə Ərəbistanı",
  "Sinqapur",
  "Slovakiya",
  "Sloveniya",
  "Solomon adaları",
  "Somali",
  "Somalilend",
  "Sudan",
  "Surinam",
  "Suriya",
  "Svazilend",
  "Syerra-Leone",
  "Şərqi Timor",
  "Şimali Marian adaları",
  "Şpisbergen və Yan-Mayen",
  "Şri-Lanka",
  "Tacikistan",
  "Tanzaniya",
  "Tailand",
  "Çin Respublikası",
  "Törks və Kaykos adaları",
  "Tokelau",
  "Tonqa",
  "Toqo",
  "Trinidad və Tobaqo",
  "Tunis",
  "Tuvalu",
  "Türkiyə",
  "Türkmənistan",
  "Ukrayna",
  "Uollis və Futuna",
  "Uqanda",
  "Uruqvay",
  "Vanuatu",
  "Vatikan",
  "Venesuela",
  "Amerika Virgin adaları",
  "Britaniya Virgin adaları",
  "Vyetnam",
  "Yamayka",
  "Yaponiya",
  "Yeni Kaledoniya",
  "Yeni Zelandiya",
  "Yəmən",
  "Yunanıstan",
  "Zambiya",
  "Zimbabve"
];
