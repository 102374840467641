module["exports"] = [
  "a mare",
  "lido",
  "ligure",
  "del friuli",
  "salentino",
  "calabro",
  "veneto",
  "nell'emilia",
  "umbro",
  "laziale",
  "terme",
  "sardo"
];
