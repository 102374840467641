module["exports"] = [
  "Rua",
  "Avenida",
  "Travessa",
  "Ponte",
  "Alameda",
  "Marginal",
  "Viela",
  "Rodovia"
];
