module["exports"] = [
  "აბასთუმანი",
  "აბაშა",
  "ადიგენი",
  "ამბროლაური",
  "ანაკლია",
  "ასპინძა",
  "ახალგორი",
  "ახალქალაქი",
  "ახალციხე",
  "ახმეტა",
  "ბათუმი",
  "ბაკურიანი",
  "ბაღდათი",
  "ბახმარო",
  "ბოლნისი",
  "ბორჯომი",
  "გარდაბანი",
  "გონიო",
  "გორი",
  "გრიგოლეთი",
  "გუდაური",
  "გურჯაანი",
  "დედოფლისწყარო",
  "დმანისი",
  "დუშეთი",
  "ვანი",
  "ზესტაფონი",
  "ზუგდიდი",
  "თბილისი",
  "თეთრიწყარო",
  "თელავი",
  "თერჯოლა",
  "თიანეთი",
  "კასპი",
  "კვარიათი",
  "კიკეთი",
  "კოჯორი",
  "ლაგოდეხი",
  "ლანჩხუთი",
  "ლენტეხი",
  "მარნეული",
  "მარტვილი",
  "მესტია",
  "მცხეთა",
  "მწვანე კონცხი",
  "ნინოწმინდა",
  "ოზურგეთი",
  "ონი",
  "რუსთავი",
  "საგარეჯო",
  "საგურამო",
  "საირმე",
  "სამტრედია",
  "სარფი",
  "საჩხერე",
  "სენაკი",
  "სიღნაღი",
  "სტეფანწმინდა",
  "სურამი",
  "ტაბახმელა",
  "ტყიბული",
  "ურეკი",
  "ფოთი",
  "ქარელი",
  "ქედა",
  "ქობულეთი",
  "ქუთაისი",
  "ყვარელი",
  "შუახევი",
  "ჩაქვი",
  "ჩოხატაური",
  "ცაგერი",
  "ცხოროჭყუ",
  "წავკისი",
  "წალენჯიხა",
  "წალკა",
  "წაღვერი",
  "წეროვანი",
  "წნორი",
  "წყალტუბო",
  "წყნეთი",
  "ჭიათურა",
  "ხარაგაული",
  "ხაშური",
  "ხელვაჩაური",
  "ხობი",
  "ხონი",
  "ხულო"
];
