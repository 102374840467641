module["exports"] = [
  "Александровна",
  "Алексеевна",
  "Альбертовна",
  "Анатольевна",
  "Андреевна",
  "Антоновна",
  "Аркадьевна",
  "Арсеньевна",
  "Артёмовна",
  "Борисовна",
  "Вадимовна",
  "Валентиновна",
  "Валерьевна",
  "Васильевна",
  "Викторовна",
  "Витальевна",
  "Владимировна",
  "Владиславовна",
  "Вячеславовна",
  "Геннадьевна",
  "Георгиевна",
  "Германовна",
  "Григорьевна",
  "Данииловна",
  "Денисовна",
  "Дмитриевна",
  "Евгеньевна",
  "Егоровна",
  "Ивановна",
  "Игнатьевна",
  "Игоревна",
  "Ильинична",
  "Константиновна",
  "Лаврентьевна",
  "Леонидовна",
  "Макаровна",
  "Максимовна",
  "Матвеевна",
  "Михайловна",
  "Никитична",
  "Николаевна",
  "Олеговна",
  "Романовна",
  "Семёновна",
  "Сергеевна",
  "Станиславовна",
  "Степановна",
  "Фёдоровна",
  "Эдуардовна",
  "Юрьевна",
  "Ярославовна"
];
