module["exports"] = [
  "Blekinge",
  "Dalarna",
  "Gotland",
  "Gävleborg",
  "Göteborg",
  "Halland",
  "Jämtland",
  "Jönköping",
  "Kalmar",
  "Kronoberg",
  "Norrbotten",
  "Skaraborg",
  "Skåne",
  "Stockholm",
  "Södermanland",
  "Uppsala",
  "Värmland",
  "Västerbotten",
  "Västernorrland",
  "Västmanland",
  "Älvsborg",
  "Örebro",
  "Östergötland"
];
