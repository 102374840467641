module["exports"] = [
  "Pacífico/Midway",
  "Pacífico/Pago_Pago",
  "Pacífico/Honolulu",
  "America/Juneau",
  "America/Los_Angeles",
  "America/Tijuana",
  "America/Denver",
  "America/Phoenix",
  "America/Chihuahua",
  "America/Mazatlan",
  "America/Chicago",
  "America/Regina",
  "America/Mexico_City",
  "America/Mexico_City",
  "America/Monterrey",
  "America/Guatemala",
  "America/New_York",
  "America/Indiana/Indianapolis",
  "America/Bogota",
  "America/Lima",
  "America/Lima",
  "America/Halifax",
  "America/Caracas",
  "America/La_Paz",
  "America/Santiago",
  "America/St_Johns",
  "America/Sao_Paulo",
  "America/Argentina/Buenos_Aires",
  "America/Guyana",
  "America/Godthab",
  "Atlantic/South_Georgia",
  "Atlantic/Azores",
  "Atlantic/Cape_Verde",
  "Europa/Dublin",
  "Europa/London",
  "Europa/Lisbon",
  "Europa/London",
  "Africa/Casablanca",
  "Africa/Monrovia",
  "Etc/UTC",
  "Europa/Belgrade",
  "Europa/Bratislava",
  "Europa/Budapest",
  "Europa/Ljubljana",
  "Europa/Prague",
  "Europa/Sarajevo",
  "Europa/Skopje",
  "Europa/Warsaw",
  "Europa/Zagreb",
  "Europa/Brussels",
  "Europa/Copenhagen",
  "Europa/Madrid",
  "Europa/Paris",
  "Europa/Amsterdam",
  "Europa/Berlin",
  "Europa/Berlin",
  "Europa/Rome",
  "Europa/Stockholm",
  "Europa/Vienna",
  "Africa/Algiers",
  "Europa/Bucharest",
  "Africa/Cairo",
  "Europa/Helsinki",
  "Europa/Kiev",
  "Europa/Riga",
  "Europa/Sofia",
  "Europa/Tallinn",
  "Europa/Vilnius",
  "Europa/Athens",
  "Europa/Istanbul",
  "Europa/Minsk",
  "Asia/Jerusalen",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Europa/Moscú",
  "Europa/Moscú",
  "Europa/Moscú",
  "Asia/Kuwait",
  "Asia/Riyadh",
  "Africa/Nairobi",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Asia/Muscat",
  "Asia/Muscat",
  "Asia/Baku",
  "Asia/Tbilisi",
  "Asia/Yerevan",
  "Asia/Kabul",
  "Asia/Yekaterinburg",
  "Asia/Karachi",
  "Asia/Karachi",
  "Asia/Tashkent",
  "Asia/Kolkata",
  "Asia/Kolkata",
  "Asia/Kolkata",
  "Asia/Kolkata",
  "Asia/Kathmandu",
  "Asia/Dhaka",
  "Asia/Dhaka",
  "Asia/Colombo",
  "Asia/Almaty",
  "Asia/Novosibirsk",
  "Asia/Rangoon",
  "Asia/Bangkok",
  "Asia/Bangkok",
  "Asia/Jakarta",
  "Asia/Krasnoyarsk",
  "Asia/Shanghai",
  "Asia/Chongqing",
  "Asia/Hong_Kong",
  "Asia/Urumqi",
  "Asia/Kuala_Lumpur",
  "Asia/Singapore",
  "Asia/Taipei",
  "Australia/Perth",
  "Asia/Irkutsk",
  "Asia/Ulaanbaatar",
  "Asia/Seoul",
  "Asia/Tokyo",
  "Asia/Tokyo",
  "Asia/Tokyo",
  "Asia/Yakutsk",
  "Australia/Darwin",
  "Australia/Adelaide",
  "Australia/Melbourne",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Brisbane",
  "Australia/Hobart",
  "Asia/Vladivostok",
  "Pacífico/Guam",
  "Pacífico/Port_Moresby",
  "Asia/Magadan",
  "Asia/Magadan",
  "Pacífico/Noumea",
  "Pacífico/Fiji",
  "Asia/Kamchatka",
  "Pacífico/Majuro",
  "Pacífico/Auckland",
  "Pacífico/Auckland",
  "Pacífico/Tongatapu",
  "Pacífico/Fakaofo",
  "Pacífico/Apia"
];
