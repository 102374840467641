// Source: http://unicode.org/cldr/trac/browser/tags/release-27/common/main/en.xml#L1847
module["exports"] = {
  wide: [
    "Pondělí",
    "Úterý",
    "Středa",
    "čtvrtek",
    "Pátek",
    "Sobota",
    "Neděle"
  ],
  // Property "wide_context" is optional, if not set then "wide" will be used instead
  // It is used to specify a word in context, which may differ from a stand-alone word
  wide_context: [
    "Pondělí",
    "Úterý",
    "Středa",
    "čtvrtek",
    "Pátek",
    "Sobota",
    "Neděle"
  ],
  abbr: [
    "Po",
    "Út",
    "St",
    "čt",
    "Pá",
    "So",
    "Ne"
  ],
  // Property "abbr_context" is optional, if not set then "abbr" will be used instead
  // It is used to specify a word in context, which may differ from a stand-alone word
  abbr_context: [
    "Po",
    "Út",
    "St",
    "čt",
    "Pá",
    "So",
    "Ne"
  ]
};
