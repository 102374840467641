module["exports"] = [
  "#{prefix} #{man_first_name} #{man_last_name}",
  "#{prefix} #{woman_first_name} #{woman_last_name}",
  "#{man_first_name} #{man_last_name} #{suffix}",
  "#{woman_first_name} #{woman_last_name} #{suffix}",
  "#{man_first_name} #{man_last_name}",
  "#{man_first_name} #{man_last_name}",
  "#{man_first_name} #{man_last_name}",
  "#{woman_first_name} #{woman_last_name}",
  "#{woman_first_name} #{woman_last_name}",
  "#{woman_first_name} #{woman_last_name}"
];
