module["exports"] = [
  "Анна",
  "Алёна",
  "Алевтина",
  "Александра",
  "Алина",
  "Алла",
  "Анастасия",
  "Ангелина",
  "Анжела",
  "Анжелика",
  "Антонида",
  "Антонина",
  "Анфиса",
  "Арина",
  "Валентина",
  "Валерия",
  "Варвара",
  "Василиса",
  "Вера",
  "Вероника",
  "Виктория",
  "Галина",
  "Дарья",
  "Евгения",
  "Екатерина",
  "Елена",
  "Елизавета",
  "Жанна",
  "Зинаида",
  "Зоя",
  "Ирина",
  "Кира",
  "Клавдия",
  "Ксения",
  "Лариса",
  "Лидия",
  "Любовь",
  "Людмила",
  "Маргарита",
  "Марина",
  "Мария",
  "Надежда",
  "Наталья",
  "Нина",
  "Оксана",
  "Ольга",
  "Раиса",
  "Регина",
  "Римма",
  "Светлана",
  "София",
  "Таисия",
  "Тамара",
  "Татьяна",
  "Ульяна",
  "Юлия"
];
