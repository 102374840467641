module["exports"] = [
  "DŚ",
  "KP",
  "LB",
  "LS",
  "ŁD",
  "MP",
  "MZ",
  "OP",
  "PK",
  "PL",
  "PM",
  "ŚL",
  "ŚK",
  "WM",
  "WP",
  "ZP"
];
