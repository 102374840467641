module["exports"] = [
  "Jr.",
  "Sr.",
  "I",
  "II",
  "III",
  "IV",
  "V",
  "MD",
  "DDS",
  "PhD",
  "DVM",
  "Ing.",
  "Lic.",
  "Dr.",
  "Mtro."
];
