module["exports"] = [
  "de l'Abbaye",
  "Adolphe Mille",
  "d'Alésia",
  "d'Argenteuil",
  "d'Assas",
  "du Bac",
  "de Paris",
  "La Boétie",
  "Bonaparte",
  "de la Bûcherie",
  "de Caumartin",
  "Charlemagne",
  "du Chat-qui-Pêche",
  "de la Chaussée-d'Antin",
  "du Dahomey",
  "Dauphine",
  "Delesseux",
  "du Faubourg Saint-Honoré",
  "du Faubourg-Saint-Denis",
  "de la Ferronnerie",
  "des Francs-Bourgeois",
  "des Grands Augustins",
  "de la Harpe",
  "du Havre",
  "de la Huchette",
  "Joubert",
  "Laffitte",
  "Lepic",
  "des Lombards",
  "Marcadet",
  "Molière",
  "Monsieur-le-Prince",
  "de Montmorency",
  "Montorgueil",
  "Mouffetard",
  "de Nesle",
  "Oberkampf",
  "de l'Odéon",
  "d'Orsel",
  "de la Paix",
  "des Panoramas",
  "Pastourelle",
  "Pierre Charron",
  "de la Pompe",
  "de Presbourg",
  "de Provence",
  "de Richelieu",
  "de Rivoli",
  "des Rosiers",
  "Royale",
  "d'Abbeville",
  "Saint-Honoré",
  "Saint-Bernard",
  "Saint-Denis",
  "Saint-Dominique",
  "Saint-Jacques",
  "Saint-Séverin",
  "des Saussaies",
  "de Seine",
  "de Solférino",
  "Du Sommerard",
  "de Tilsitt",
  "Vaneau",
  "de Vaugirard",
  "de la Victoire",
  "Zadkine"
];
