module["exports"] = [
  "Aarav",
  "Ajita",
  "Amit",
  "Amita",
  "Amrit",
  "Arijit",
  "Ashmi",
  "Asmita",
  "Bibek",
  "Bijay",
  "Bikash",
  "Bina",
  "Bishal",
  "Bishnu",
  "Buddha",
  "Deepika",
  "Dipendra",
  "Gagan",
  "Ganesh",
  "Khem",
  "Krishna",
  "Laxmi",
  "Manisha",
  "Nabin",
  "Nikita",
  "Niraj",
  "Nischal",
  "Padam",
  "Pooja",
  "Prabin",
  "Prakash",
  "Prashant",
  "Prem",
  "Purna",
  "Rajendra",
  "Rajina",
  "Raju",
  "Rakesh",
  "Ranjan",
  "Ratna",
  "Sagar",
  "Sandeep",
  "Sanjay",
  "Santosh",
  "Sarita",
  "Shilpa",
  "Shirisha",
  "Shristi",
  "Siddhartha",
  "Subash",
  "Sumeet",
  "Sunita",
  "Suraj",
  "Susan",
  "Sushant"
];
