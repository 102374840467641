module["exports"] = [
  "town",
  "ton",
  "land",
  "ville",
  "berg",
  "burgh",
  "borough",
  "bury",
  "view",
  "port",
  "mouth",
  "stad",
  "furt",
  "chester",
  "mouth",
  "fort",
  "haven",
  "side",
  "shire"
];
