module["exports"] = [
  "SARL",
  "SA",
  "EURL",
  "SAS",
  "SEM",
  "SCOP",
  "GIE",
  "EI"
];
