module["exports"] = [
  "Erik",
  "Lars",
  "Karl",
  "Anders",
  "Per",
  "Johan",
  "Nils",
  "Lennart",
  "Emil",
  "Hans"
];
