module["exports"] = [
  "And",
  "Ara",
  "Ast",
  "Bal",
  "Can",
  "Cbr",
  "Man",
  "Leo",
  "Cat",
  "Com",
  "Ext",
  "Gal",
  "Rio",
  "Mad",
  "Nav",
  "Vas",
  "Mur"
];
