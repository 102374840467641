module["exports"] = [
  "Björk",
  "Järnvägs",
  "Ring",
  "Skol",
  "Skogs",
  "Ny",
  "Gran",
  "Idrotts",
  "Stor",
  "Kyrk",
  "Industri",
  "Park",
  "Strand",
  "Skol",
  "Trädgård",
  "Ängs",
  "Kyrko",
  "Villa",
  "Ek",
  "Kvarn",
  "Stations",
  "Back",
  "Furu",
  "Gen",
  "Fabriks",
  "Åker",
  "Bäck",
  "Asp"
];
