module["exports"] = [
  "강원",
  "경기",
  "경남",
  "경북",
  "광주",
  "대구",
  "대전",
  "부산",
  "서울",
  "울산",
  "인천",
  "전남",
  "전북",
  "제주",
  "충남",
  "충북",
  "세종"
];
