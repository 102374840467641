var sv = {};
module['exports'] = sv;
sv.title = "Swedish";
sv.address = require("./address");
sv.company = require("./company");
sv.internet = require("./internet");
sv.name = require("./name");
sv.phone_number = require("./phone_number");
sv.cell_phone = require("./cell_phone");
sv.commerce = require("./commerce");
sv.team = require("./team");
sv.date = require("./date");
