module["exports"] = [
  "Anna",
  "Adeliya",
  "Afaq",
  "Afət",
  "Afərim",
  "Aidə",
  "Aygün",
  "Aynur",
  "Alsu",
  "Ayan",
  "Aytən",
  "Aygül",
  "Aydan",
  "Aylin",
  "Bahar",
  "Banu",
  "Bəyaz",
  "Billurə",
  "Cansu",
  "Ceyla",
  "Damla",
  "Dəniz",
  "Diana",
  "Dilarə",
  "Ella",
  "Elza",
  "Elyanora",
  "Ellada",
  "Elvira",
  "Elnarə",
  "Esmira",
  "Estella",
  "Fatimə",
  "Fəxriyyə",
  "Fərəh",
  "Fərqanə",
  "Fidan",
  "Firuzə",
  "Gövhər",
  "Günay",
  "Gülay",
  "Gülçin",
  "Gülər",
  "Gülsüm",
  "Humay",
  "Hüriyə",
  "Hülya",
  "Jalə",
  "Jasmin",
  "Kübra",
  "Ləman",
  "Lamiyə",
  "Lalə",
  "Liliya",
  "Laura",
  "Leyla",
  "Maya",
  "Mehriban",
  "Mələk",
  "Nuray",
  "Nurgün",
  "Nərgiz",
  "Nigar",
  "Ofelya",
  "Pəri",
  "Röya",
  "Səbinə",
  "Selcan",
  "Tansu",
  "Tuba",
  "Ülviyyə",
  "Ulduz",
  "Ülkər"
];
