module["exports"] = [
  "Республика Адыгея",
  "Республика Башкортостан",
  "Республика Бурятия",
  "Республика Алтай Республика Дагестан",
  "Республика Ингушетия",
  "Кабардино-Балкарская Республика",
  "Республика Калмыкия",
  "Республика Карачаево-Черкессия",
  "Республика Карелия",
  "Республика Коми",
  "Республика Марий Эл",
  "Республика Мордовия",
  "Республика Саха (Якутия)",
  "Республика Северная Осетия-Алания",
  "Республика Татарстан",
  "Республика Тыва",
  "Удмуртская Республика",
  "Республика Хакасия",
  "Чувашская Республика",
  "Алтайский край",
  "Краснодарский край",
  "Красноярский край",
  "Приморский край",
  "Ставропольский край",
  "Хабаровский край",
  "Амурская область",
  "Архангельская область",
  "Астраханская область",
  "Белгородская область",
  "Брянская область",
  "Владимирская область",
  "Волгоградская область",
  "Вологодская область",
  "Воронежская область",
  "Ивановская область",
  "Иркутская область",
  "Калиниградская область",
  "Калужская область",
  "Камчатская область",
  "Кемеровская область",
  "Кировская область",
  "Костромская область",
  "Курганская область",
  "Курская область",
  "Ленинградская область",
  "Липецкая область",
  "Магаданская область",
  "Московская область",
  "Мурманская область",
  "Нижегородская область",
  "Новгородская область",
  "Новосибирская область",
  "Омская область",
  "Оренбургская область",
  "Орловская область",
  "Пензенская область",
  "Пермская область",
  "Псковская область",
  "Ростовская область",
  "Рязанская область",
  "Самарская область",
  "Саратовская область",
  "Сахалинская область",
  "Свердловская область",
  "Смоленская область",
  "Тамбовская область",
  "Тверская область",
  "Томская область",
  "Тульская область",
  "Тюменская область",
  "Ульяновская область",
  "Челябинская область",
  "Читинская область",
  "Ярославская область",
  "Еврейская автономная область",
  "Агинский Бурятский авт. округ",
  "Коми-Пермяцкий автономный округ",
  "Корякский автономный округ",
  "Ненецкий автономный округ",
  "Таймырский (Долгано-Ненецкий) автономный округ",
  "Усть-Ордынский Бурятский автономный округ",
  "Ханты-Мансийский автономный округ",
  "Чукотский автономный округ",
  "Эвенкийский автономный округ",
  "Ямало-Ненецкий автономный округ",
  "Чеченская Республика"
];
