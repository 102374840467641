module["exports"] = [
  "com",
  "com",
  "com",
  "net",
  "org",
  "it",
  "it",
  "it"
];
