module["exports"] = [
  "국가는",
  "법률이",
  "정하는",
  "바에",
  "의하여",
  "재외국민을",
  "보호할",
  "의무를",
  "진다.",
  "모든",
  "국민은",
  "신체의",
  "자유를",
  "가진다.",
  "국가는",
  "전통문화의",
  "계승·발전과",
  "민족문화의",
  "창달에",
  "노력하여야",
  "한다.",
  "통신·방송의",
  "시설기준과",
  "신문의",
  "기능을",
  "보장하기",
  "위하여",
  "필요한",
  "사항은",
  "법률로",
  "정한다.",
  "헌법에",
  "의하여",
  "체결·공포된",
  "조약과",
  "일반적으로",
  "승인된",
  "국제법규는",
  "국내법과",
  "같은",
  "효력을",
  "가진다.",
  "다만,",
  "현행범인인",
  "경우와",
  "장기",
  "3년",
  "이상의",
  "형에",
  "해당하는",
  "죄를",
  "범하고",
  "도피",
  "또는",
  "증거인멸의",
  "염려가",
  "있을",
  "때에는",
  "사후에",
  "영장을",
  "청구할",
  "수",
  "있다.",
  "저작자·발명가·과학기술자와",
  "예술가의",
  "권리는",
  "법률로써",
  "보호한다.",
  "형사피고인은",
  "유죄의",
  "판결이",
  "확정될",
  "때까지는",
  "무죄로",
  "추정된다.",
  "모든",
  "국민은",
  "행위시의",
  "법률에",
  "의하여",
  "범죄를",
  "구성하지",
  "아니하는",
  "행위로",
  "소추되지",
  "아니하며,",
  "동일한",
  "범죄에",
  "대하여",
  "거듭",
  "처벌받지",
  "아니한다.",
  "국가는",
  "평생교육을",
  "진흥하여야",
  "한다.",
  "모든",
  "국민은",
  "사생활의",
  "비밀과",
  "자유를",
  "침해받지",
  "아니한다.",
  "의무교육은",
  "무상으로",
  "한다.",
  "저작자·발명가·과학기술자와",
  "예술가의",
  "권리는",
  "법률로써",
  "보호한다.",
  "국가는",
  "모성의",
  "보호를",
  "위하여",
  "노력하여야",
  "한다.",
  "헌법에",
  "의하여",
  "체결·공포된",
  "조약과",
  "일반적으로",
  "승인된",
  "국제법규는",
  "국내법과",
  "같은",
  "효력을",
  "가진다."
];
