module["exports"] = [
  "Adriansyah",
  "Ardianto",
  "Anggriawan",
  "Budiman",
  "Budiyanto",
  "Damanik",
  "Dongoran",
  "Dabukke",
  "Firmansyah",
  "Firgantoro",
  "Gunarto",
  "Gunawan",
  "Hardiansyah",
  "Habibi",
  "Hakim",
  "Halim",
  "Haryanto",
  "Hidayat",
  "Hidayanto",
  "Hutagalung",
  "Hutapea",
  "Hutasoit",
  "Irawan",
  "Iswahyudi",
  "Kuswoyo",
  "Januar",
  "Jailani",
  "Kurniawan",
  "Kusumo",
  "Latupono",
  "Lazuardi",
  "Maheswara",
  "Mahendra",
  "Mustofa",
  "Mansur",
  "Mandala",
  "Megantara",
  "Maulana",
  "Maryadi",
  "Mangunsong",
  "Manullang",
  "Marpaung",
  "Marbun",
  "Narpati",
  "Natsir",
  "Nugroho",
  "Najmudin",
  "Nashiruddin",
  "Nainggolan",
  "Nababan",
  "Napitupulu",
  "Pangestu",
  "Putra",
  "Pranowo",
  "Prabowo",
  "Pratama",
  "Prasetya",
  "Prasetyo",
  "Pradana",
  "Pradipta",
  "Prakasa",
  "Permadi",
  "Prasasta",
  "Prayoga",
  "Ramadan",
  "Rajasa",
  "Rajata",
  "Saptono",
  "Santoso",
  "Saputra",
  "Saefullah",
  "Setiawan",
  "Suryono",
  "Suwarno",
  "Siregar",
  "Sihombing",
  "Salahudin",
  "Sihombing",
  "Samosir",
  "Saragih",
  "Sihotang",
  "Simanjuntak",
  "Sinaga",
  "Simbolon",
  "Sitompul",
  "Sitorus",
  "Sirait",
  "Siregar",
  "Situmorang",
  "Tampubolon",
  "Thamrin",
  "Tamba",
  "Tarihoran",
  "Utama",
  "Uwais",
  "Wahyudin",
  "Waluyo",
  "Wibowo",
  "Winarno",
  "Wibisono",
  "Wijaya",
  "Widodo",
  "Wacana",
  "Waskita",
  "Wasita",
  "Zulkarnain"
];