module["exports"] = [
  "Aleksandrów Kujawski",
  "Aleksandrów Łódzki",
  "Alwernia",
  "Andrychów",
  "Annopol",
  "Augustów",
  "Babimost",
  "Baborów",
  "Baranów Sandomierski",
  "Barcin",
  "Barczewo",
  "Bardo",
  "Barlinek",
  "Bartoszyce",
  "Barwice",
  "Bełchatów",
  "Bełżyce",
  "Będzin",
  "Biała",
  "Biała Piska",
  "Biała Podlaska",
  "Biała Rawska",
  "Białobrzegi",
  "Białogard",
  "Biały Bór",
  "Białystok",
  "Biecz",
  "Bielawa",
  "Bielsk Podlaski",
  "Bielsko-Biała",
  "Bieruń",
  "Bierutów",
  "Bieżuń",
  "Biłgoraj",
  "Biskupiec",
  "Bisztynek",
  "Blachownia",
  "Błaszki",
  "Błażowa",
  "Błonie",
  "Bobolice",
  "Bobowa",
  "Bochnia",
  "Bodzentyn",
  "Bogatynia",
  "Boguchwała",
  "Boguszów-Gorce",
  "Bojanowo",
  "Bolesławiec",
  "Bolków",
  "Borek Wielkopolski",
  "Borne Sulinowo",
  "Braniewo",
  "Brańsk",
  "Brodnica",
  "Brok",
  "Brusy",
  "Brwinów",
  "Brzeg",
  "Brzeg Dolny",
  "Brzesko",
  "Brzeszcze",
  "Brześć Kujawski",
  "Brzeziny",
  "Brzostek",
  "Brzozów",
  "Buk",
  "Bukowno",
  "Busko-Zdrój",
  "Bychawa",
  "Byczyna",
  "Bydgoszcz",
  "Bystrzyca Kłodzka",
  "Bytom",
  "Bytom Odrzański",
  "Bytów",
  "Cedynia",
  "Chełm",
  "Chełmek",
  "Chełmno",
  "Chełmża",
  "Chęciny",
  "Chmielnik",
  "Chocianów",
  "Chociwel",
  "Chodecz",
  "Chodzież",
  "Chojna",
  "Chojnice",
  "Chojnów",
  "Choroszcz",
  "Chorzele",
  "Chorzów",
  "Choszczno",
  "Chrzanów",
  "Ciechanowiec",
  "Ciechanów",
  "Ciechocinek",
  "Cieszanów",
  "Cieszyn",
  "Ciężkowice",
  "Cybinka",
  "Czaplinek",
  "Czarna Białostocka",
  "Czarna Woda",
  "Czarne",
  "Czarnków",
  "Czchów",
  "Czechowice-Dziedzice",
  "Czeladź",
  "Czempiń",
  "Czerniejewo",
  "Czersk",
  "Czerwieńsk",
  "Czerwionka-Leszczyny",
  "Częstochowa",
  "Człopa",
  "Człuchów",
  "Czyżew",
  "Ćmielów",
  "Daleszyce",
  "Darłowo",
  "Dąbie",
  "Dąbrowa Białostocka",
  "Dąbrowa Górnicza",
  "Dąbrowa Tarnowska",
  "Debrzno",
  "Dębica",
  "Dęblin",
  "Dębno",
  "Dobczyce",
  "Dobiegniew",
  "Dobra (powiat łobeski)",
  "Dobra (powiat turecki)",
  "Dobre Miasto",
  "Dobrodzień",
  "Dobrzany",
  "Dobrzyń nad Wisłą",
  "Dolsk",
  "Drawno",
  "Drawsko Pomorskie",
  "Drezdenko",
  "Drobin",
  "Drohiczyn",
  "Drzewica",
  "Dukla",
  "Duszniki-Zdrój",
  "Dynów",
  "Działdowo",
  "Działoszyce",
  "Działoszyn",
  "Dzierzgoń",
  "Dzierżoniów",
  "Dziwnów",
  "Elbląg",
  "Ełk",
  "Frampol",
  "Frombork",
  "Garwolin",
  "Gąbin",
  "Gdańsk",
  "Gdynia",
  "Giżycko",
  "Glinojeck",
  "Gliwice",
  "Głogów",
  "Głogów Małopolski",
  "Głogówek",
  "Głowno",
  "Głubczyce",
  "Głuchołazy",
  "Głuszyca",
  "Gniew",
  "Gniewkowo",
  "Gniezno",
  "Gogolin",
  "Golczewo",
  "Goleniów",
  "Golina",
  "Golub-Dobrzyń",
  "Gołańcz",
  "Gołdap",
  "Goniądz",
  "Gorlice",
  "Gorzów Śląski",
  "Gorzów Wielkopolski",
  "Gostynin",
  "Gostyń",
  "Gościno",
  "Gozdnica",
  "Góra",
  "Góra Kalwaria",
  "Górowo Iławeckie",
  "Górzno",
  "Grabów nad Prosną",
  "Grajewo",
  "Grodków",
  "Grodzisk Mazowiecki",
  "Grodzisk Wielkopolski",
  "Grójec",
  "Grudziądz",
  "Grybów",
  "Gryfice",
  "Gryfino",
  "Gryfów Śląski",
  "Gubin",
  "Hajnówka",
  "Halinów",
  "Hel",
  "Hrubieszów",
  "Iława",
  "Iłowa",
  "Iłża",
  "Imielin",
  "Inowrocław",
  "Ińsko",
  "Iwonicz-Zdrój",
  "Izbica Kujawska",
  "Jabłonowo Pomorskie",
  "Janikowo",
  "Janowiec Wielkopolski",
  "Janów Lubelski",
  "Jarocin",
  "Jarosław",
  "Jasień",
  "Jasło",
  "Jastarnia",
  "Jastrowie",
  "Jastrzębie-Zdrój",
  "Jawor",
  "Jaworzno",
  "Jaworzyna Śląska",
  "Jedlicze",
  "Jedlina-Zdrój",
  "Jedwabne",
  "Jelcz-Laskowice",
  "Jelenia Góra",
  "Jeziorany",
  "Jędrzejów",
  "Jordanów",
  "Józefów (powiat biłgorajski)",
  "Józefów (powiat otwocki)",
  "Jutrosin",
  "Kalety",
  "Kalisz",
  "Kalisz Pomorski",
  "Kalwaria Zebrzydowska",
  "Kałuszyn",
  "Kamienna Góra",
  "Kamień Krajeński",
  "Kamień Pomorski",
  "Kamieńsk",
  "Kańczuga",
  "Karczew",
  "Kargowa",
  "Karlino",
  "Karpacz",
  "Kartuzy",
  "Katowice",
  "Kazimierz Dolny",
  "Kazimierza Wielka",
  "Kąty Wrocławskie",
  "Kcynia",
  "Kędzierzyn-Koźle",
  "Kępice",
  "Kępno",
  "Kętrzyn",
  "Kęty",
  "Kielce",
  "Kietrz",
  "Kisielice",
  "Kleczew",
  "Kleszczele",
  "Kluczbork",
  "Kłecko",
  "Kłobuck",
  "Kłodawa",
  "Kłodzko",
  "Knurów",
  "Knyszyn",
  "Kobylin",
  "Kobyłka",
  "Kock",
  "Kolbuszowa",
  "Kolno",
  "Kolonowskie",
  "Koluszki",
  "Kołaczyce",
  "Koło",
  "Kołobrzeg",
  "Koniecpol",
  "Konin",
  "Konstancin-Jeziorna",
  "Konstantynów Łódzki",
  "Końskie",
  "Koprzywnica",
  "Korfantów",
  "Koronowo",
  "Korsze",
  "Kosów Lacki",
  "Kostrzyn",
  "Kostrzyn nad Odrą",
  "Koszalin",
  "Kościan",
  "Kościerzyna",
  "Kowal",
  "Kowalewo Pomorskie",
  "Kowary",
  "Koziegłowy",
  "Kozienice",
  "Koźmin Wielkopolski",
  "Kożuchów",
  "Kórnik",
  "Krajenka",
  "Kraków",
  "Krapkowice",
  "Krasnobród",
  "Krasnystaw",
  "Kraśnik",
  "Krobia",
  "Krosno",
  "Krosno Odrzańskie",
  "Krośniewice",
  "Krotoszyn",
  "Kruszwica",
  "Krynica Morska",
  "Krynica-Zdrój",
  "Krynki",
  "Krzanowice",
  "Krzepice",
  "Krzeszowice",
  "Krzywiń",
  "Krzyż Wielkopolski",
  "Książ Wielkopolski",
  "Kudowa-Zdrój",
  "Kunów",
  "Kutno",
  "Kuźnia Raciborska",
  "Kwidzyn",
  "Lądek-Zdrój",
  "Legionowo",
  "Legnica",
  "Lesko",
  "Leszno",
  "Leśna",
  "Leśnica",
  "Lewin Brzeski",
  "Leżajsk",
  "Lębork",
  "Lędziny",
  "Libiąż",
  "Lidzbark",
  "Lidzbark Warmiński",
  "Limanowa",
  "Lipiany",
  "Lipno",
  "Lipsk",
  "Lipsko",
  "Lubaczów",
  "Lubań",
  "Lubartów",
  "Lubawa",
  "Lubawka",
  "Lubień Kujawski",
  "Lubin",
  "Lublin",
  "Lubliniec",
  "Lubniewice",
  "Lubomierz",
  "Luboń",
  "Lubraniec",
  "Lubsko",
  "Lwówek",
  "Lwówek Śląski",
  "Łabiszyn",
  "Łańcut",
  "Łapy",
  "Łasin",
  "Łask",
  "Łaskarzew",
  "Łaszczów",
  "Łaziska Górne",
  "Łazy",
  "Łeba",
  "Łęczna",
  "Łęczyca",
  "Łęknica",
  "Łobez",
  "Łobżenica",
  "Łochów",
  "Łomianki",
  "Łomża",
  "Łosice",
  "Łowicz",
  "Łódź",
  "Łuków",
  "Maków Mazowiecki",
  "Maków Podhalański",
  "Malbork",
  "Małogoszcz",
  "Małomice",
  "Margonin",
  "Marki",
  "Maszewo",
  "Miasteczko Śląskie",
  "Miastko",
  "Michałowo",
  "Miechów",
  "Miejska Górka",
  "Mielec",
  "Mieroszów",
  "Mieszkowice",
  "Międzybórz",
  "Międzychód",
  "Międzylesie",
  "Międzyrzec Podlaski",
  "Międzyrzecz",
  "Międzyzdroje",
  "Mikołajki",
  "Mikołów",
  "Mikstat",
  "Milanówek",
  "Milicz",
  "Miłakowo",
  "Miłomłyn",
  "Miłosław",
  "Mińsk Mazowiecki",
  "Mirosławiec",
  "Mirsk",
  "Mława",
  "Młynary",
  "Mogielnica",
  "Mogilno",
  "Mońki",
  "Morąg",
  "Mordy",
  "Moryń",
  "Mosina",
  "Mrągowo",
  "Mrocza",
  "Mszana Dolna",
  "Mszczonów",
  "Murowana Goślina",
  "Muszyna",
  "Mysłowice",
  "Myszków",
  "Myszyniec",
  "Myślenice",
  "Myślibórz",
  "Nakło nad Notecią",
  "Nałęczów",
  "Namysłów",
  "Narol",
  "Nasielsk",
  "Nekla",
  "Nidzica",
  "Niemcza",
  "Niemodlin",
  "Niepołomice",
  "Nieszawa",
  "Nisko",
  "Nowa Dęba",
  "Nowa Ruda",
  "Nowa Sarzyna",
  "Nowa Sól",
  "Nowe",
  "Nowe Brzesko",
  "Nowe Miasteczko",
  "Nowe Miasto Lubawskie",
  "Nowe Miasto nad Pilicą",
  "Nowe Skalmierzyce",
  "Nowe Warpno",
  "Nowogard",
  "Nowogrodziec",
  "Nowogród",
  "Nowogród Bobrzański",
  "Nowy Dwór Gdański",
  "Nowy Dwór Mazowiecki",
  "Nowy Sącz",
  "Nowy Staw",
  "Nowy Targ",
  "Nowy Tomyśl",
  "Nowy Wiśnicz",
  "Nysa",
  "Oborniki",
  "Oborniki Śląskie",
  "Obrzycko",
  "Odolanów",
  "Ogrodzieniec",
  "Okonek",
  "Olecko",
  "Olesno",
  "Oleszyce",
  "Oleśnica",
  "Olkusz",
  "Olsztyn",
  "Olsztynek",
  "Olszyna",
  "Oława",
  "Opalenica",
  "Opatów",
  "Opoczno",
  "Opole",
  "Opole Lubelskie",
  "Orneta",
  "Orzesze",
  "Orzysz",
  "Osieczna",
  "Osiek",
  "Ostrołęka",
  "Ostroróg",
  "Ostrowiec Świętokrzyski",
  "Ostróda",
  "Ostrów Lubelski",
  "Ostrów Mazowiecka",
  "Ostrów Wielkopolski",
  "Ostrzeszów",
  "Ośno Lubuskie",
  "Oświęcim",
  "Otmuchów",
  "Otwock",
  "Ozimek",
  "Ozorków",
  "Ożarów",
  "Ożarów Mazowiecki",
  "Pabianice",
  "Paczków",
  "Pajęczno",
  "Pakość",
  "Parczew",
  "Pasłęk",
  "Pasym",
  "Pelplin",
  "Pełczyce",
  "Piaseczno",
  "Piaski",
  "Piastów",
  "Piechowice",
  "Piekary Śląskie",
  "Pieniężno",
  "Pieńsk",
  "Pieszyce",
  "Pilawa",
  "Pilica",
  "Pilzno",
  "Piła",
  "Piława Górna",
  "Pińczów",
  "Pionki",
  "Piotrków Kujawski",
  "Piotrków Trybunalski",
  "Pisz",
  "Piwniczna-Zdrój",
  "Pleszew",
  "Płock",
  "Płońsk",
  "Płoty",
  "Pniewy",
  "Pobiedziska",
  "Poddębice",
  "Podkowa Leśna",
  "Pogorzela",
  "Polanica-Zdrój",
  "Polanów",
  "Police",
  "Polkowice",
  "Połaniec",
  "Połczyn-Zdrój",
  "Poniatowa",
  "Poniec",
  "Poręba",
  "Poznań",
  "Prabuty",
  "Praszka",
  "Prochowice",
  "Proszowice",
  "Prószków",
  "Pruchnik",
  "Prudnik",
  "Prusice",
  "Pruszcz Gdański",
  "Pruszków",
  "Przasnysz",
  "Przecław",
  "Przedbórz",
  "Przedecz",
  "Przemków",
  "Przemyśl",
  "Przeworsk",
  "Przysucha",
  "Pszczyna",
  "Pszów",
  "Puck",
  "Puławy",
  "Pułtusk",
  "Puszczykowo",
  "Pyrzyce",
  "Pyskowice",
  "Pyzdry",
  "Rabka-Zdrój",
  "Raciąż",
  "Racibórz",
  "Radków",
  "Radlin",
  "Radłów",
  "Radom",
  "Radomsko",
  "Radomyśl Wielki",
  "Radymno",
  "Radziejów",
  "Radzionków",
  "Radzymin",
  "Radzyń Chełmiński",
  "Radzyń Podlaski",
  "Rajgród",
  "Rakoniewice",
  "Raszków",
  "Rawa Mazowiecka",
  "Rawicz",
  "Recz",
  "Reda",
  "Rejowiec Fabryczny",
  "Resko",
  "Reszel",
  "Rogoźno",
  "Ropczyce",
  "Różan",
  "Ruciane-Nida",
  "Ruda Śląska",
  "Rudnik nad Sanem",
  "Rumia",
  "Rybnik",
  "Rychwał",
  "Rydułtowy",
  "Rydzyna",
  "Ryglice",
  "Ryki",
  "Rymanów",
  "Ryn",
  "Rypin",
  "Rzepin",
  "Rzeszów",
  "Rzgów",
  "Sandomierz",
  "Sanok",
  "Sejny",
  "Serock",
  "Sędziszów",
  "Sędziszów Małopolski",
  "Sępopol",
  "Sępólno Krajeńskie",
  "Sianów",
  "Siechnice",
  "Siedlce",
  "Siemianowice Śląskie",
  "Siemiatycze",
  "Sieniawa",
  "Sieradz",
  "Sieraków",
  "Sierpc",
  "Siewierz",
  "Skalbmierz",
  "Skała",
  "Skarszewy",
  "Skaryszew",
  "Skarżysko-Kamienna",
  "Skawina",
  "Skępe",
  "Skierniewice",
  "Skoczów",
  "Skoki",
  "Skórcz",
  "Skwierzyna",
  "Sława",
  "Sławków",
  "Sławno",
  "Słomniki",
  "Słubice",
  "Słupca",
  "Słupsk",
  "Sobótka",
  "Sochaczew",
  "Sokołów Małopolski",
  "Sokołów Podlaski",
  "Sokółka",
  "Solec Kujawski",
  "Sompolno",
  "Sopot",
  "Sosnowiec",
  "Sośnicowice",
  "Stalowa Wola",
  "Starachowice",
  "Stargard Szczeciński",
  "Starogard Gdański",
  "Stary Sącz",
  "Staszów",
  "Stawiski",
  "Stawiszyn",
  "Stąporków",
  "Stęszew",
  "Stoczek Łukowski",
  "Stronie Śląskie",
  "Strumień",
  "Stryków",
  "Strzegom",
  "Strzelce Krajeńskie",
  "Strzelce Opolskie",
  "Strzelin",
  "Strzelno",
  "Strzyżów",
  "Sucha Beskidzka",
  "Suchań",
  "Suchedniów",
  "Suchowola",
  "Sulechów",
  "Sulejów",
  "Sulejówek",
  "Sulęcin",
  "Sulmierzyce",
  "Sułkowice",
  "Supraśl",
  "Suraż",
  "Susz",
  "Suwałki",
  "Swarzędz",
  "Syców",
  "Szadek",
  "Szamocin",
  "Szamotuły",
  "Szczawnica",
  "Szczawno-Zdrój",
  "Szczebrzeszyn",
  "Szczecin",
  "Szczecinek",
  "Szczekociny",
  "Szczucin",
  "Szczuczyn",
  "Szczyrk",
  "Szczytna",
  "Szczytno",
  "Szepietowo",
  "Szklarska Poręba",
  "Szlichtyngowa",
  "Szprotawa",
  "Sztum",
  "Szubin",
  "Szydłowiec",
  "Ścinawa",
  "Ślesin",
  "Śmigiel",
  "Śrem",
  "Środa Śląska",
  "Środa Wielkopolska",
  "Świątniki Górne",
  "Świdnica",
  "Świdnik",
  "Świdwin",
  "Świebodzice",
  "Świebodzin",
  "Świecie",
  "Świeradów-Zdrój",
  "Świerzawa",
  "Świętochłowice",
  "Świnoujście",
  "Tarczyn",
  "Tarnobrzeg",
  "Tarnogród",
  "Tarnowskie Góry",
  "Tarnów",
  "Tczew",
  "Terespol",
  "Tłuszcz",
  "Tolkmicko",
  "Tomaszów Lubelski",
  "Tomaszów Mazowiecki",
  "Toruń",
  "Torzym",
  "Toszek",
  "Trzcianka",
  "Trzciel",
  "Trzcińsko-Zdrój",
  "Trzebiatów",
  "Trzebinia",
  "Trzebnica",
  "Trzemeszno",
  "Tuchola",
  "Tuchów",
  "Tuczno",
  "Tuliszków",
  "Turek",
  "Tuszyn",
  "Twardogóra",
  "Tychowo",
  "Tychy",
  "Tyczyn",
  "Tykocin",
  "Tyszowce",
  "Ujazd",
  "Ujście",
  "Ulanów",
  "Uniejów",
  "Ustka",
  "Ustroń",
  "Ustrzyki Dolne",
  "Wadowice",
  "Wałbrzych",
  "Wałcz",
  "Warka",
  "Warszawa",
  "Warta",
  "Wasilków",
  "Wąbrzeźno",
  "Wąchock",
  "Wągrowiec",
  "Wąsosz",
  "Wejherowo",
  "Węgliniec",
  "Węgorzewo",
  "Węgorzyno",
  "Węgrów",
  "Wiązów",
  "Wieleń",
  "Wielichowo",
  "Wieliczka",
  "Wieluń",
  "Wieruszów",
  "Więcbork",
  "Wilamowice",
  "Wisła",
  "Witkowo",
  "Witnica",
  "Wleń",
  "Władysławowo",
  "Włocławek",
  "Włodawa",
  "Włoszczowa",
  "Wodzisław Śląski",
  "Wojcieszów",
  "Wojkowice",
  "Wojnicz",
  "Wolbórz",
  "Wolbrom",
  "Wolin",
  "Wolsztyn",
  "Wołczyn",
  "Wołomin",
  "Wołów",
  "Woźniki",
  "Wrocław",
  "Wronki",
  "Września",
  "Wschowa",
  "Wyrzysk",
  "Wysoka",
  "Wysokie Mazowieckie",
  "Wyszków",
  "Wyszogród",
  "Wyśmierzyce",
  "Zabłudów",
  "Zabrze",
  "Zagórów",
  "Zagórz",
  "Zakliczyn",
  "Zakopane",
  "Zakroczym",
  "Zalewo",
  "Zambrów",
  "Zamość",
  "Zator",
  "Zawadzkie",
  "Zawichost",
  "Zawidów",
  "Zawiercie",
  "Ząbki",
  "Ząbkowice Śląskie",
  "Zbąszynek",
  "Zbąszyń",
  "Zduny",
  "Zduńska Wola",
  "Zdzieszowice",
  "Zelów",
  "Zgierz",
  "Zgorzelec",
  "Zielona Góra",
  "Zielonka",
  "Ziębice",
  "Złocieniec",
  "Złoczew",
  "Złotoryja",
  "Złotów",
  "Złoty Stok",
  "Zwierzyniec",
  "Zwoleń",
  "Żabno",
  "Żagań",
  "Żarki",
  "Żarów",
  "Żary",
  "Żelechów",
  "Żerków",
  "Żmigród",
  "Żnin",
  "Żory",
  "Żukowo",
  "Żuromin",
  "Żychlin",
  "Żyrardów",
  "Żywiec"
];
