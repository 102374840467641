module["exports"] = [
  "0800 ### ###",
  "0800 ## ## ##",
  "0## ### ## ##",
  "0## ### ## ##",
  "+41 ## ### ## ##",
  "0900 ### ###",
  "076 ### ## ##",
  "+4178 ### ## ##",
  "0041 79 ### ## ##"
];
