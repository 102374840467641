module["exports"] = [
  "stad",
  "land",
  "sås",
  "ås",
  "holm",
  "tuna",
  "sta",
  "berg",
  "löv",
  "borg",
  "mora",
  "hamn",
  "fors",
  "köping",
  "by",
  "hult",
  "torp",
  "fred",
  "vik"
];
