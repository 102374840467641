module["exports"] = [
  "com",
  "net",
  "biz",
  "ch",
  "de",
  "li",
  "at",
  "ch",
  "ch"
];
