module["exports"] = [
  "Afganistan",
  "Afgánsky islamský štát",
  "Albánsko",
  "Albánska republika",
  "Alžírsko",
  "Alžírska demokratická ľudová republika",
  "Andorra",
  "Andorrské kniežatsvo",
  "Angola",
  "Angolská republika",
  "Antigua a Barbuda",
  "Antigua a Barbuda",
  "Argentína",
  "Argentínska republika",
  "Arménsko",
  "Arménska republika",
  "Austrália",
  "Austrálsky zväz",
  "Azerbajdžan",
  "Azerbajdžanská republika",
  "Bahamy",
  "Bahamské spoločenstvo",
  "Bahrajn",
  "Bahrajnské kráľovstvo",
  "Bangladéš",
  "Bangladéšska ľudová republika",
  "Barbados",
  "Barbados",
  "Belgicko",
  "Belgické kráľovstvo",
  "Belize",
  "Belize",
  "Benin",
  "Beninská republika",
  "Bhután",
  "Bhutánske kráľovstvo",
  "Bielorusko",
  "Bieloruská republika",
  "Bolívia",
  "Bolívijská republika",
  "Bosna a Hercegovina",
  "Republika Bosny a Hercegoviny",
  "Botswana",
  "Botswanská republika",
  "Brazília",
  "Brazílska federatívna republika",
  "Brunej",
  "Brunejský sultanát",
  "Bulharsko",
  "Bulharská republika",
  "Burkina Faso",
  "Burkina Faso",
  "Burundi",
  "Burundská republika",
  "Cyprus",
  "Cyperská republika",
  "Čad",
  "Republika Čad",
  "Česko",
  "Česká republika",
  "Čína",
  "Čínska ľudová republika",
  "Dánsko",
  "Dánsko kráľovstvo",
  "Dominika",
  "Spoločenstvo Dominika",
  "Dominikánska republika",
  "Dominikánska republika",
  "Džibutsko",
  "Džibutská republika",
  "Egypt",
  "Egyptská arabská republika",
  "Ekvádor",
  "Ekvádorská republika",
  "Eritrea",
  "Eritrejský štát",
  "Estónsko",
  "Estónska republika",
  "Etiópia",
  "Etiópska federatívna demokratická republika",
  "Fidži",
  "Republika ostrovy Fidži",
  "Filipíny",
  "Filipínska republika",
  "Fínsko",
  "Fínska republika",
  "Francúzsko",
  "Francúzska republika",
  "Gabon",
  "Gabonská republika",
  "Gambia",
  "Gambijská republika",
  "Ghana",
  "Ghanská republika",
  "Grécko",
  "Helénska republika",
  "Grenada",
  "Grenada",
  "Gruzínsko",
  "Gruzínsko",
  "Guatemala",
  "Guatemalská republika",
  "Guinea",
  "Guinejská republika",
  "Guinea-Bissau",
  "Republika Guinea-Bissau",
  "Guayana",
  "Guayanská republika",
  "Haiti",
  "Republika Haiti",
  "Holandsko",
  "Holandské kráľovstvo",
  "Honduras",
  "Honduraská republika",
  "Chile",
  "Čílska republika",
  "Chorvátsko",
  "Chorvátska republika",
  "India",
  "Indická republika",
  "Indonézia",
  "Indonézska republika",
  "Irak",
  "Iracká republika",
  "Irán",
  "Iránska islamská republika",
  "Island",
  "Islandská republika",
  "Izrael",
  "Štát Izrael",
  "Írsko",
  "Írska republika",
  "Jamajka",
  "Jamajka",
  "Japonsko",
  "Japonsko",
  "Jemen",
  "Jemenská republika",
  "Jordánsko",
  "Jordánske hášimovské kráľovstvo",
  "Južná Afrika",
  "Juhoafrická republika",
  "Kambodža",
  "Kambodžské kráľovstvo",
  "Kamerun",
  "Kamerunská republika",
  "Kanada",
  "Kanada",
  "Kapverdy",
  "Kapverdská republika",
  "Katar",
  "Štát Katar",
  "Kazachstan",
  "Kazašská republika",
  "Keňa",
  "Kenská republika",
  "Kirgizsko",
  "Kirgizská republika",
  "Kiribati",
  "Kiribatská republika",
  "Kolumbia",
  "Kolumbijská republika",
  "Komory",
  "Komorská únia",
  "Kongo",
  "Konžská demokratická republika",
  "Kongo (\"Brazzaville\")",
  "Konžská republika",
  "Kórea (\"Južná\")",
  "Kórejská republika",
  "Kórea (\"Severná\")",
  "Kórejská ľudovodemokratická republika",
  "Kostarika",
  "Kostarická republika",
  "Kuba",
  "Kubánska republika",
  "Kuvajt",
  "Kuvajtský štát",
  "Laos",
  "Laoská ľudovodemokratická republika",
  "Lesotho",
  "Lesothské kráľovstvo",
  "Libanon",
  "Libanonská republika",
  "Libéria",
  "Libérijská republika",
  "Líbya",
  "Líbyjská arabská ľudová socialistická džamáhírija",
  "Lichtenštajnsko",
  "Lichtenštajnské kniežatstvo",
  "Litva",
  "Litovská republika",
  "Lotyšsko",
  "Lotyšská republika",
  "Luxembursko",
  "Luxemburské veľkovojvodstvo",
  "Macedónsko",
  "Macedónska republika",
  "Madagaskar",
  "Madagaskarská republika",
  "Maďarsko",
  "Maďarská republika",
  "Malajzia",
  "Malajzia",
  "Malawi",
  "Malawijská republika",
  "Maldivy",
  "Maldivská republika",
  "Mali",
  "Malijská republika",
  "Malta",
  "Malta",
  "Maroko",
  "Marocké kráľovstvo",
  "Marshallove ostrovy",
  "Republika Marshallových ostrovy",
  "Mauritánia",
  "Mauritánska islamská republika",
  "Maurícius",
  "Maurícijská republika",
  "Mexiko",
  "Spojené štáty mexické",
  "Mikronézia",
  "Mikronézske federatívne štáty",
  "Mjanmarsko",
  "Mjanmarský zväz",
  "Moldavsko",
  "Moldavská republika",
  "Monako",
  "Monacké kniežatstvo",
  "Mongolsko",
  "Mongolsko",
  "Mozambik",
  "Mozambická republika",
  "Namíbia",
  "Namíbijská republika",
  "Nauru",
  "Naurská republika",
  "Nemecko",
  "Nemecká spolková republika",
  "Nepál",
  "Nepálske kráľovstvo",
  "Niger",
  "Nigerská republika",
  "Nigéria",
  "Nigérijská federatívna republika",
  "Nikaragua",
  "Nikaragujská republika",
  "Nový Zéland",
  "Nový Zéland",
  "Nórsko",
  "Nórske kráľovstvo",
  "Omán",
  "Ománsky sultanát",
  "Pakistan",
  "Pakistanská islamská republika",
  "Palau",
  "Palauská republika",
  "Panama",
  "Panamská republika",
  "Papua-Nová Guinea",
  "Nezávislý štát Papua-Nová Guinea",
  "Paraguaj",
  "Paraguajská republika",
  "Peru",
  "Peruánska republika",
  "Pobrežie Slonoviny",
  "Republika Pobrežie Slonoviny",
  "Poľsko",
  "Poľská republika",
  "Portugalsko",
  "Portugalská republika",
  "Rakúsko",
  "Rakúska republika",
  "Rovníková Guinea",
  "Republika Rovníková Guinea",
  "Rumunsko",
  "Rumunsko",
  "Rusko",
  "Ruská federácia",
  "Rwanda",
  "Rwandská republika",
  "Salvádor",
  "Salvádorská republika",
  "Samoa",
  "Nezávislý štát Samoa",
  "San Maríno",
  "Sanmarínska republika",
  "Saudská Arábia",
  "Kráľovstvo Saudskej Arábie",
  "Senegal",
  "Senegalská republika",
  "Seychely",
  "Seychelská republika",
  "Sierra Leone",
  "Republika Sierra Leone",
  "Singapur",
  "Singapurska republika",
  "Slovensko",
  "Slovenská republika",
  "Slovinsko",
  "Slovinská republika",
  "Somálsko",
  "Somálska demokratická republika",
  "Spojené arabské emiráty",
  "Spojené arabské emiráty",
  "Spojené štáty americké",
  "Spojené štáty americké",
  "Srbsko a Čierna Hora",
  "Srbsko a Čierna Hora",
  "Srí Lanka",
  "Demokratická socialistická republika Srí Lanka",
  "Stredoafrická republika",
  "Stredoafrická republika",
  "Sudán",
  "Sudánska republika",
  "Surinam",
  "Surinamská republika",
  "Svazijsko",
  "Svazijské kráľovstvo",
  "Svätá Lucia",
  "Svätá Lucia",
  "Svätý Krištof a Nevis",
  "Federácia Svätý Krištof a Nevis",
  "Sv. Tomáš a Princov Ostrov",
  "Demokratická republika Svätý Tomáš a Princov Ostrov",
  "Sv. Vincent a Grenadíny",
  "Svätý Vincent a Grenadíny",
  "Sýria",
  "Sýrska arabská republika",
  "Šalamúnove ostrovy",
  "Šalamúnove ostrovy",
  "Španielsko",
  "Španielske kráľovstvo",
  "Švajčiarsko",
  "Švajčiarska konfederácia",
  "Švédsko",
  "Švédske kráľovstvo",
  "Tadžikistan",
  "Tadžická republika",
  "Taliansko",
  "Talianska republika",
  "Tanzánia",
  "Tanzánijská zjednotená republika",
  "Thajsko",
  "Thajské kráľovstvo",
  "Togo",
  "Tožská republika",
  "Tonga",
  "Tonžské kráľovstvo",
  "Trinidad a Tobago",
  "Republika Trinidad a Tobago",
  "Tunisko",
  "Tuniská republika",
  "Turecko",
  "Turecká republika",
  "Turkménsko",
  "Turkménsko",
  "Tuvalu",
  "Tuvalu",
  "Uganda",
  "Ugandská republika",
  "Ukrajina",
  "Uruguaj",
  "Uruguajská východná republika",
  "Uzbekistan",
  "Vanuatu",
  "Vanuatská republika",
  "Vatikán",
  "Svätá Stolica",
  "Veľká Británia",
  "Spojené kráľovstvo Veľkej Británie a Severného Írska",
  "Venezuela",
  "Venezuelská bolívarovská republika",
  "Vietnam",
  "Vietnamská socialistická republika",
  "Východný Timor",
  "Demokratická republika Východný Timor",
  "Zambia",
  "Zambijská republika",
  "Zimbabwe",
  "Zimbabwianska republika"
];
