module["exports"] = [
  "01 #######",
  "021 #######",
  "022 #######",
  "023 #######",
  "024 #######",
  "025 #######",
  "026 #######",
  "027 #######",
  "028 #######",
  "029 #######",
  "0402 #######",
  "0404 #######",
  "041 #######",
  "042 #######",
  "043 #######",
  "044 #######",
  "045 #######",
  "046 #######",
  "047 #######",
  "049 #######",
  "0504 #######",
  "0505 #######",
  "051 #######",
  "052 #######",
  "053 #######",
  "056 #######",
  "057 #######",
  "058 #######",
  "059 #######",
  "061 #######",
  "062 #######",
  "063 #######",
  "064 #######",
  "065 #######",
  "066 #######",
  "067 #######",
  "068 #######",
  "069 #######",
  "071 #######",
  "074 #######",
  "090 #######",
  "091 #######",
  "093 #######",
  "094 #######",
  "095 #######",
  "096 #######",
  "097 #######",
  "098 #######",
  "099 #######"
];
