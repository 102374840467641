module["exports"] = [
  "Alberta",
  "Colombie-Britannique",
  "Manitoba",
  "Nouveau-Brunswick",
  "Terre-Neuve-et-Labrador",
  "Nouvelle-Écosse",
  "Territoires du Nord-Ouest",
  "Nunavut",
  "Ontario",
  "Île-du-Prince-Édouard",
  "Québec",
  "Saskatchewan",
  "Yukon"
];
