module["exports"] = [
  "50-###-##-##",
  "51-###-##-##",
  "53-###-##-##",
  "57-###-##-##",
  "60-###-##-##",
  "66-###-##-##",
  "69-###-##-##",
  "72-###-##-##",
  "73-###-##-##",
  "78-###-##-##",
  "79-###-##-##",
  "88-###-##-##"
];
