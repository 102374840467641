module["exports"] = [
  "habilidad",
  "acceso",
  "adaptador",
  "algoritmo",
  "alianza",
  "analista",
  "aplicación",
  "enfoque",
  "arquitectura",
  "archivo",
  "inteligencia artificial",
  "array",
  "actitud",
  "medición",
  "gestión presupuestaria",
  "capacidad",
  "desafío",
  "circuito",
  "colaboración",
  "complejidad",
  "concepto",
  "conglomeración",
  "contingencia",
  "núcleo",
  "fidelidad",
  "base de datos",
  "data-warehouse",
  "definición",
  "emulación",
  "codificar",
  "encriptar",
  "extranet",
  "firmware",
  "flexibilidad",
  "focus group",
  "previsión",
  "base de trabajo",
  "función",
  "funcionalidad",
  "Interfaz Gráfica",
  "groupware",
  "Interfaz gráfico de usuario",
  "hardware",
  "Soporte",
  "jerarquía",
  "conjunto",
  "implementación",
  "infraestructura",
  "iniciativa",
  "instalación",
  "conjunto de instrucciones",
  "interfaz",
  "intranet",
  "base del conocimiento",
  "red de area local",
  "aprovechar",
  "matrices",
  "metodologías",
  "middleware",
  "migración",
  "modelo",
  "moderador",
  "monitorizar",
  "arquitectura abierta",
  "sistema abierto",
  "orquestar",
  "paradigma",
  "paralelismo",
  "política",
  "portal",
  "estructura de precios",
  "proceso de mejora",
  "producto",
  "productividad",
  "proyecto",
  "proyección",
  "protocolo",
  "línea segura",
  "software",
  "solución",
  "estandardización",
  "estrategia",
  "estructura",
  "éxito",
  "superestructura",
  "soporte",
  "sinergia",
  "mediante",
  "marco de tiempo",
  "caja de herramientas",
  "utilización",
  "website",
  "fuerza de trabajo"
];
