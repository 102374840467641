module["exports"] = [
  "clicks-and-mortar",
  "value-added",
  "vertical",
  "proactive",
  "robust",
  "revolutionary",
  "scalable",
  "leading-edge",
  "innovative",
  "intuitive",
  "strategic",
  "e-business",
  "mission-critical",
  "sticky",
  "one-to-one",
  "24/7",
  "end-to-end",
  "global",
  "B2B",
  "B2C",
  "granular",
  "frictionless",
  "virtual",
  "viral",
  "dynamic",
  "24/365",
  "best-of-breed",
  "killer",
  "magnetic",
  "bleeding-edge",
  "web-enabled",
  "interactive",
  "dot-com",
  "sexy",
  "back-end",
  "real-time",
  "efficient",
  "front-end",
  "distributed",
  "seamless",
  "extensible",
  "turn-key",
  "world-class",
  "open-source",
  "cross-platform",
  "cross-media",
  "synergistic",
  "bricks-and-clicks",
  "out-of-the-box",
  "enterprise",
  "integrated",
  "impactful",
  "wireless",
  "transparent",
  "next-generation",
  "cutting-edge",
  "user-centric",
  "visionary",
  "customized",
  "ubiquitous",
  "plug-and-play",
  "collaborative",
  "compelling",
  "holistic",
  "rich",
  "synergies",
  "web-readiness",
  "paradigms",
  "markets",
  "partnerships",
  "infrastructures",
  "platforms",
  "initiatives",
  "channels",
  "eyeballs",
  "communities",
  "ROI",
  "solutions",
  "e-tailers",
  "e-services",
  "action-items",
  "portals",
  "niches",
  "technologies",
  "content",
  "vortals",
  "supply-chains",
  "convergence",
  "relationships",
  "architectures",
  "interfaces",
  "e-markets",
  "e-commerce",
  "systems",
  "bandwidth",
  "infomediaries",
  "models",
  "mindshare",
  "deliverables",
  "users",
  "schemas",
  "networks",
  "applications",
  "metrics",
  "e-business",
  "functionalities",
  "experiences",
  "web services",
  "methodologies"
];
