module["exports"] = [
  "co.kr",
  "com",
  "biz",
  "info",
  "ne.kr",
  "net",
  "or.kr",
  "org"
];
