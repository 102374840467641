module["exports"] = [
  "서연",
  "민서",
  "서현",
  "지우",
  "서윤",
  "지민",
  "수빈",
  "하은",
  "예은",
  "윤서",
  "민준",
  "지후",
  "지훈",
  "준서",
  "현우",
  "예준",
  "건우",
  "현준",
  "민재",
  "우진",
  "은주"
];
