module["exports"] = [
  "강릉",
  "양양",
  "인제",
  "광주",
  "구리",
  "부천",
  "밀양",
  "통영",
  "창원",
  "거창",
  "고성",
  "양산",
  "김천",
  "구미",
  "영주",
  "광산",
  "남",
  "북",
  "고창",
  "군산",
  "남원",
  "동작",
  "마포",
  "송파",
  "용산",
  "부평",
  "강화",
  "수성"
];
