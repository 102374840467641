module["exports"] = [
  "vit",
  "silver",
  "grå",
  "svart",
  "röd",
  "grön",
  "blå",
  "gul",
  "lila",
  "indigo",
  "guld",
  "brun",
  "rosa",
  "purpur",
  "korall"
];
