module["exports"] = [
   "sinergias",
   "web-readiness",
   "paradigmas",
   "mercados",
   "asociaciones",
   "infraestructuras",
   "plataformas",
   "iniciativas",
   "canales",
   "ojos",
   "comunidades",
   "ROI",
   "soluciones",
   "minoristas electrónicos",
   "e-servicios",
   "elementos de acción",
   "portales",
   "nichos",
   "tecnologías",
   "contenido",
   "vortales",
   "cadenas de suministro",
   "convergencia",
   "relaciones",
   "arquitecturas",
   "interfaces",
   "mercados electrónicos",
   "e-commerce",
   "sistemas",
   "ancho de banda",
   "infomediarios",
   "modelos",
   "Mindshare",
   "entregables",
   "usuarios",
   "esquemas",
   "redes",
   "aplicaciones",
   "métricas",
   "e-business",
   "funcionalidades",
   "experiencias",
   "servicios web",
   "metodologías"
];
