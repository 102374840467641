module["exports"] = [
  "Bhaktapur",
  "Biratnagar",
  "Birendranagar",
  "Birgunj",
  "Butwal",
  "Damak",
  "Dharan",
  "Gaur",
  "Gorkha",
  "Hetauda",
  "Itahari",
  "Janakpur",
  "Kathmandu",
  "Lahan",
  "Nepalgunj",
  "Pokhara"
];
