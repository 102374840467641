module["exports"] = [
  "#{male_first_name} #{male_last_name}",
  "#{male_last_name} #{male_first_name}",
  "#{male_first_name} #{male_middle_name} #{male_last_name}",
  "#{male_last_name} #{male_first_name} #{male_middle_name}",
  "#{female_first_name} #{female_last_name}",
  "#{female_last_name} #{female_first_name}",
  "#{female_first_name} #{female_middle_name} #{female_last_name}",
  "#{female_last_name} #{female_first_name} #{female_middle_name}"
];
