module["exports"] = {
  "descriptor": [
    "გენერალური",
    "მთავარი",
    "სტაჟიორ",
    "უმცროსი",
    "ყოფილი",
    "წამყვანი"
  ],
  "level": [
    "აღრიცხვების",
    "ბრენდინგის",
    "ბრენიდს",
    "ბუღალტერიის",
    "განყოფილების",
    "გაყიდვების",
    "გუნდის",
    "დახმარების",
    "დიზაინის",
    "თავდაცვის",
    "ინფორმაციის",
    "კვლევების",
    "კომუნიკაციების",
    "მარკეტინგის",
    "ოპერაციათა",
    "ოპტიმიზაციების",
    "პიარ",
    "პროგრამის",
    "საქმეთა",
    "ტაქტიკური",
    "უსაფრთხოების",
    "ფინანსთა",
    "ქსელის",
    "ხარისხის",
    "ჯგუფის"
  ],
  "job": [
    "აგენტი",
    "ადვოკატი",
    "ადმინისტრატორი",
    "არქიტექტორი",
    "ასისტენტი",
    "აღმასრულებელი დირექტორი",
    "დეველოპერი",
    "დეკანი",
    "დიზაინერი",
    "დირექტორი",
    "ელექტრიკოსი",
    "ექსპერტი",
    "ინჟინერი",
    "იურისტი",
    "კონსტრუქტორი",
    "კონსულტანტი",
    "კოორდინატორი",
    "ლექტორი",
    "მასაჟისტი",
    "მემანქანე",
    "მენეჯერი",
    "მძღოლი",
    "მწვრთნელი",
    "ოპერატორი",
    "ოფიცერი",
    "პედაგოგი",
    "პოლიციელი",
    "პროგრამისტი",
    "პროდიუსერი",
    "პრორექტორი",
    "ჟურნალისტი",
    "რექტორი",
    "სპეციალისტი",
    "სტრატეგისტი",
    "ტექნიკოსი",
    "ფოტოგრაფი",
    "წარმომადგენელი"
  ]
};
