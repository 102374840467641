module["exports"] = [
  "đã",
  "đang",
  "ừ",
  "ờ",
  "á",
  "không",
  "biết",
  "gì",
  "hết",
  "đâu",
  "nha",
  "thế",
  "thì",
  "là",
  "đánh",
  "đá",
  "đập",
  "phá",
  "viết",
  "vẽ",
  "tô",
  "thuê",
  "mướn",
  "mượn",
  "mua",
  "một",
  "hai",
  "ba",
  "bốn",
  "năm",
  "sáu",
  "bảy",
  "tám",
  "chín",
  "mười",
  "thôi",
  "việc",
  "nghỉ",
  "làm",
  "nhà",
  "cửa",
  "xe",
  "đạp",
  "ác",
  "độc",
  "khoảng",
  "khoan",
  "thuyền",
  "tàu",
  "bè",
  "lầu",
  "xanh",
  "đỏ",
  "tím",
  "vàng",
  "kim",
  "chỉ",
  "khâu",
  "may",
  "vá",
  "em",
  "anh",
  "yêu",
  "thương",
  "thích",
  "con",
  "cái",
  "bàn",
  "ghế",
  "tủ",
  "quần",
  "áo",
  "nón",
  "dép",
  "giày",
  "lỗi",
  "được",
  "ghét",
  "giết",
  "chết",
  "hết",
  "tôi",
  "bạn",
  "tui",
  "trời",
  "trăng",
  "mây",
  "gió",
  "máy",
  "hàng",
  "hóa",
  "leo",
  "núi",
  "bơi",
  "biển",
  "chìm",
  "xuồng",
  "nước",
  "ngọt",
  "ruộng",
  "đồng",
  "quê",
  "hương"
];
