module["exports"] = [
  "Андрухович",
  "Бабух",
  "Балабан",
  "Балабух",
  "Балакун",
  "Балицький",
  "Бамбула",
  "Бандера",
  "Барановський",
  "Бачей",
  "Башук",
  "Бердник",
  "Білич",
  "Бондаренко",
  "Борецький",
  "Боровський",
  "Борочко",
  "Боярчук",
  "Брицький",
  "Бурмило",
  "Бутько",
  "Василин",
  "Василишин",
  "Васильківський",
  "Вергун",
  "Вередун",
  "Верещук",
  "Витребенько",
  "Вітряк",
  "Волощук",
  "Гайдук",
  "Гайовий",
  "Гайчук",
  "Галаєнко",
  "Галатей",
  "Галаціон",
  "Гаман",
  "Гамула",
  "Ганич",
  "Гарай",
  "Гарун",
  "Гладківський",
  "Гладух",
  "Глинський",
  "Гнатишин",
  "Гойко",
  "Головець",
  "Горбач",
  "Гордійчук",
  "Горовий",
  "Городоцький",
  "Гречко",
  "Григоришин",
  "Гриневецький",
  "Гриневський",
  "Гришко",
  "Громико",
  "Данилишин",
  "Данилко",
  "Демків",
  "Демчишин",
  "Дзюб’як",
  "Дзюба",
  "Дідух",
  "Дмитришин",
  "Дмитрук",
  "Довгалевський",
  "Дурдинець",
  "Євенко",
  "Євпак",
  "Ємець",
  "Єрмак",
  "Забіла",
  "Зварич",
  "Зінкевич",
  "Зленко",
  "Іванишин",
  "Іванів",
  "Іванців",
  "Калач",
  "Кандиба",
  "Карпух",
  "Каськів",
  "Кивач",
  "Коваленко",
  "Ковальський",
  "Коломієць",
  "Коман",
  "Компанієць",
  "Кононець",
  "Кордун",
  "Корецький",
  "Корнїйчук",
  "Коров’як",
  "Коцюбинський",
  "Кулинич",
  "Кульчицький",
  "Лагойда",
  "Лазірко",
  "Лановий",
  "Латаний",
  "Латанський",
  "Лахман",
  "Левадовський",
  "Ликович",
  "Линдик",
  "Ліхно",
  "Лобачевський",
  "Ломовий",
  "Луговий",
  "Луцький",
  "Луцьків",
  "Лученко",
  "Лучко",
  "Лютий",
  "Лящук",
  "Магера",
  "Мазайло",
  "Мазило",
  "Мазун",
  "Майборода",
  "Майстренко",
  "Маковецький",
  "Малкович",
  "Мамій",
  "Маринич",
  "Марієвський",
  "Марків",
  "Махно",
  "Миклашевський",
  "Миклухо",
  "Милославський",
  "Михайлюк",
  "Міняйло",
  "Могилевський",
  "Москаль",
  "Москалюк",
  "Мотрієнко",
  "Негода",
  "Ногачевський",
  "Опенько",
  "Осадко",
  "Павленко",
  "Павлишин",
  "Павлів",
  "Пагутяк",
  "Паламарчук",
  "Палій",
  "Паращук",
  "Пасічник",
  "Пендик",
  "Петик",
  "Петлюра",
  "Петренко",
  "Петрин",
  "Петришин",
  "Петрів",
  "Плаксій",
  "Погиба",
  "Поліщук",
  "Пономарів",
  "Поривай",
  "Поривайло",
  "Потебенько",
  "Потоцький",
  "Пригода",
  "Приймак",
  "Притула",
  "Прядун",
  "Розпутній",
  "Романишин",
  "Романів",
  "Ромей",
  "Роменець",
  "Ромочко",
  "Савицький",
  "Саєнко",
  "Свидригайло",
  "Семеночко",
  "Семещук",
  "Сердюк",
  "Силецький",
  "Сідлецький",
  "Сідляк",
  "Сірко",
  "Скиба",
  "Скоропадський",
  "Слободян",
  "Сосюра",
  "Сплюх",
  "Спотикач",
  "Стахів",
  "Степанець",
  "Стецьків",
  "Стигайло",
  "Сторожук",
  "Сторчак",
  "Стоян",
  "Сучак",
  "Сушко",
  "Тарасюк",
  "Тиндарей",
  "Ткаченко",
  "Третяк",
  "Троян",
  "Трублаєвський",
  "Трясило",
  "Трясун",
  "Уманець",
  "Унич",
  "Усич",
  "Федоришин",
  "Хитрово",
  "Цимбалістий",
  "Цушко",
  "Червоній",
  "Шамрило",
  "Шевченко",
  "Шестак",
  "Шиндарей",
  "Шиян",
  "Шкараба",
  "Шудрик",
  "Шумило",
  "Шупик",
  "Шухевич",
  "Щербак",
  "Юрчишин",
  "Юхно",
  "Ющик",
  "Ющук",
  "Яворівський",
  "Яловий",
  "Ялюк",
  "Янюк",
  "Ярмак",
  "Яцишин",
  "Яцьків",
  "Ящук"
];
