module["exports"] = [
   "rojo",
   "verde",
   "azul",
   "amarillo",
   "morado",
   "Menta verde",
   "teal",
   "blanco",
   "negro",
   "Naranja",
   "Rosa",
   "gris",
   "marrón",
   "violeta",
   "turquesa",
   "tan",
   "cielo azul",
   "salmón",
   "ciruela",
   "orquídea",
   "aceituna",
   "magenta",
   "Lima",
   "marfil",
   "índigo",
   "oro",
   "fucsia",
   "cian",
   "azul",
   "lavanda",
   "plata"
];
