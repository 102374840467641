module["exports"] = [
  "вул.",
  "вулиця",
  "пр.",
  "проспект",
  "пл.",
  "площа",
  "пров.",
  "провулок"
];
