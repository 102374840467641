module["exports"] = [
  "Aba",
  "Abak",
  "Abaka",
  "Abakan",
  "Abakay",
  "Abar",
  "Abay",
  "Abı",
  "Abılay",
  "Abluç",
  "Abşar",
  "Açığ",
  "Açık",
  "Açuk",
  "Adalan",
  "Adaldı",
  "Adalmış",
  "Adar",
  "Adaş",
  "Adberilgen",
  "Adıgüzel",
  "Adık",
  "Adıkutlu",
  "Adıkutlutaş",
  "Adlı",
  "Adlıbeğ",
  "Adraman",
  "Adsız",
  "Afşar",
  "Afşın",
  "Ağabay",
  "Ağakağan",
  "Ağalak",
  "Ağlamış",
  "Ak",
  "Akaş",
  "Akata",
  "Akbaş",
  "Akbay",
  "Akboğa",
  "Akbörü",
  "Akbudak",
  "Akbuğra",
  "Akbulak",
  "Akça",
  "Akçakoca",
  "Akçora",
  "Akdemir",
  "Akdoğan",
  "Akı",
  "Akıbudak",
  "Akım",
  "Akın",
  "Akınçı",
  "Akkun",
  "Akkunlu",
  "Akkurt",
  "Akkuş",
  "Akpıra",
  "Aksungur",
  "Aktan",
  "Al",
  "Ala",
  "Alaban",
  "Alabörü",
  "Aladağ",
  "Aladoğan",
  "Alakurt",
  "Alayunt",
  "Alayuntlu",
  "Aldemir",
  "Aldıgerey",
  "Aldoğan",
  "Algu",
  "Alımga",
  "Alka",
  "Alkabölük",
  "Alkaevli",
  "Alkan",
  "Alkaşı",
  "Alkış",
  "Alp",
  "Alpagut",
  "Alpamış",
  "Alparsbeğ",
  "Alparslan",
  "Alpata",
  "Alpay",
  "Alpaya",
  "Alpaykağan",
  "Alpbamsı",
  "Alpbilge",
  "Alpdirek",
  "Alpdoğan",
  "Alper",
  "Alperen",
  "Alpertunga",
  "Alpgerey",
  "Alpış",
  "Alpilig",
  "Alpkara",
  "Alpkutlu",
  "Alpkülük",
  "Alpşalçı",
  "Alptegin",
  "Alptuğrul",
  "Alptunga",
  "Alpturan",
  "Alptutuk",
  "Alpuluğ",
  "Alpurungu",
  "Alpurungututuk",
  "Alpyörük",
  "Altan",
  "Altankağan",
  "Altankan",
  "Altay",
  "Altın",
  "Altınkağan",
  "Altınkan",
  "Altınoba",
  "Altıntamgan",
  "Altıntamgantarkan",
  "Altıntarkan",
  "Altıntay",
  "Altmışkara",
  "Altuga",
  "Amaç",
  "Amrak",
  "Amul",
  "Ançuk",
  "Andarıman",
  "Anıl",
  "Ant",
  "Apa",
  "Apak",
  "Apatarkan",
  "Aprançur",
  "Araboğa",
  "Arademir",
  "Aral",
  "Arbay",
  "Arbuz",
  "Arçuk",
  "Ardıç",
  "Argıl",
  "Argu",
  "Argun",
  "Arı",
  "Arıboğa",
  "Arık",
  "Arıkağan",
  "Arıkdoruk",
  "Arınç",
  "Arkın",
  "Arkış",
  "Armağan",
  "Arnaç",
  "Arpat",
  "Arsal",
  "Arsıl",
  "Arslan",
  "Arslanargun",
  "Arslanbörü",
  "Arslansungur",
  "Arslantegin",
  "Arslanyabgu",
  "Arşun",
  "Artıınal",
  "Artuk",
  "Artukaç",
  "Artut",
  "Aruk",
  "Asartegin",
  "Asığ",
  "Asrı",
  "Asuğ",
  "Aşan",
  "Aşanboğa",
  "Aşantuğrul",
  "Aşantudun",
  "Aşıkbulmuş",
  "Aşkın",
  "Aştaloğul",
  "Aşuk",
  "Ataç",
  "Atakağan",
  "Atakan",
  "Atalan",
  "Ataldı",
  "Atalmış",
  "Ataman",
  "Atasagun",
  "Atasu",
  "Atberilgen",
  "Atıgay",
  "Atıkutlu",
  "Atıkutlutaş",
  "Atıla",
  "Atılgan",
  "Atım",
  "Atımer",
  "Atış",
  "Atlı",
  "Atlıbeğ",
  "Atlıkağan",
  "Atmaca",
  "Atsız",
  "Atunçu",
  "Avar",
  "Avluç",
  "Avşar",
  "Ay",
  "Ayaçı",
  "Ayas",
  "Ayaş",
  "Ayaz",
  "Aybalta",
  "Ayban",
  "Aybars",
  "Aybeğ",
  "Aydarkağan",
  "Aydemir",
  "Aydın",
  "Aydınalp",
  "Aydoğan",
  "Aydoğdu",
  "Aydoğmuş",
  "Aygırak",
  "Ayıtmış",
  "Ayız",
  "Ayızdağ",
  "Aykağan",
  "Aykan",
  "Aykurt",
  "Ayluç",
  "Ayluçtarkan",
  "Ayma",
  "Ayruk",
  "Aysılığ",
  "Aytak",
  "Ayyıldız",
  "Azak",
  "Azban",
  "Azgan",
  "Azganaz",
  "Azıl",
  "Babır",
  "Babur",
  "Baçara",
  "Baççayman",
  "Baçman",
  "Badabul",
  "Badruk",
  "Badur",
  "Bağa",
  "Bağaalp",
  "Bağaışbara",
  "Bağan",
  "Bağaşatulu",
  "Bağatarkan",
  "Bağatengrikağan",
  "Bağatur",
  "Bağaturçigşi",
  "Bağaturgerey",
  "Bağaturipi",
  "Bağatursepi",
  "Bağış",
  "Bağtaş",
  "Bakağul",
  "Bakır",
  "Bakırsokum",
  "Baksı",
  "Bakşı",
  "Balaban",
  "Balaka",
  "Balakatay",
  "Balamır",
  "Balçar",
  "Baldu",
  "Balkık",
  "Balta",
  "Baltacı",
  "Baltar",
  "Baltır",
  "Baltur",
  "Bamsı",
  "Bangu",
  "Barak",
  "Baraktöre",
  "Baran",
  "Barbeğ",
  "Barboğa",
  "Barbol",
  "Barbulsun",
  "Barça",
  "Barçadoğdu",
  "Barçadoğmuş",
  "Barçadurdu",
  "Barçadurmuş",
  "Barçan",
  "Barçatoyun",
  "Bardıbay",
  "Bargan",
  "Barımtay",
  "Barın",
  "Barkan",
  "Barkdoğdu",
  "Barkdoğmuş",
  "Barkdurdu",
  "Barkdurmuş",
  "Barkın",
  "Barlas",
  "Barlıbay",
  "Barmaklak",
  "Barmaklı",
  "Barman",
  "Bars",
  "Barsbeğ",
  "Barsboğa",
  "Barsgan",
  "Barskan",
  "Barsurungu",
  "Bartu",
  "Basademir",
  "Basan",
  "Basanyalavaç",
  "Basar",
  "Basat",
  "Baskın",
  "Basmıl",
  "Bastı",
  "Bastuğrul",
  "Basu",
  "Basut",
  "Başak",
  "Başbuğ",
  "Başçı",
  "Başgan",
  "Başkırt",
  "Başkurt",
  "Baştar",
  "Batrak",
  "Batu",
  "Batuk",
  "Batur",
  "Baturalp",
  "Bay",
  "Bayançar",
  "Bayankağan",
  "Bayat",
  "Bayazıt",
  "Baybars",
  "Baybayık",
  "Baybiçen",
  "Bayboğa",
  "Baybora",
  "Baybüre",
  "Baydar",
  "Baydemir",
  "Baydur",
  "Bayık",
  "Bayınçur",
  "Bayındır",
  "Baykal",
  "Baykara",
  "Baykoca",
  "Baykuzu",
  "Baymünke",
  "Bayna",
  "Baynal",
  "Baypüre",
  "Bayrı",
  "Bayraç",
  "Bayrak",
  "Bayram",
  "Bayrın",
  "Bayruk",
  "Baysungur",
  "Baytara",
  "Baytaş",
  "Bayunçur",
  "Bayur",
  "Bayurku",
  "Bayutmuş",
  "Bayuttu",
  "Bazır",
  "Beçeapa",
  "Beçkem",
  "Beğ",
  "Beğarslan",
  "Beğbars",
  "Beğbilgeçikşin",
  "Beğboğa",
  "Beğçur",
  "Beğdemir",
  "Beğdilli",
  "Beğdurmuş",
  "Beğkulu",
  "Beğtaş",
  "Beğtegin",
  "Beğtüzün",
  "Begi",
  "Begil",
  "Begine",
  "Begitutuk",
  "Beglen",
  "Begni",
  "Bek",
  "Bekazıl",
  "Bekbekeç",
  "Bekeç",
  "Bekeçarslan",
  "Bekeçarslantegin",
  "Bekeçtegin",
  "Beker",
  "Beklemiş",
  "Bektür",
  "Belçir",
  "Belek",
  "Belgi",
  "Belgüc",
  "Beltir",
  "Bengi",
  "Bengü",
  "Benlidemir",
  "Berdibeğ",
  "Berendey",
  "Bergü",
  "Berginsenge",
  "Berk",
  "Berke",
  "Berkiş",
  "Berkyaruk",
  "Bermek",
  "Besentegin",
  "Betemir",
  "Beyizçi",
  "Beyrek",
  "Beyrem",
  "Bıçkı",
  "Bıçkıcı",
  "Bıdın",
  "Bıtaybıkı",
  "Bıtrı",
  "Biçek",
  "Bilge",
  "Bilgebayunçur",
  "Bilgebeğ",
  "Bilgeçikşin",
  "Bilgeışbara",
  "Bilgeışbaratamgan",
  "Bilgekağan",
  "Bilgekan",
  "Bilgekutluk",
  "Bilgekülüçur",
  "Bilgetaçam",
  "Bilgetamgacı",
  "Bilgetardu",
  "Bilgetegin",
  "Bilgetonyukuk",
  "Bilgez",
  "Bilgiç",
  "Bilgin",
  "Bilig",
  "Biligköngülsengün",
  "Bilik",
  "Binbeği",
  "Bindir",
  "Boğa",
  "Boğaç",
  "Boğaçuk",
  "Boldaz",
  "Bolmuş",
  "Bolsun",
  "Bolun",
  "Boncuk",
  "Bongul",
  "Bongulboğa",
  "Bora",
  "Boran",
  "Borçul",
  "Borlukçu",
  "Bornak",
  "Boyan",
  "Boyankulu",
  "Boylabağa",
  "Boylabağatarkan",
  "Boylakutlutarkan",
  "Bozan",
  "Bozbörü",
  "Bozdoğan",
  "Bozkurt",
  "Bozkuş",
  "Bozok",
  "Bögde",
  "Böge",
  "Bögü",
  "Bökde",
  "Bökde",
  "Böke",
  "Bölen",
  "Bölükbaşı",
  "Bönek",
  "Bönge",
  "Börü",
  "Börübars",
  "Börüsengün",
  "Börteçine",
  "Buçan",
  "Buçur",
  "Budağ",
  "Budak",
  "Budunlu",
  "Buğday",
  "Buğra",
  "Buğrakarakağan",
  "Bukak",
  "Bukaktutuk",
  "Bulaçapan",
  "Bulak",
  "Bulan",
  "Buldur",
  "Bulgak",
  "Bulmaz",
  "Bulmuş",
  "Buluç",
  "Buluğ",
  "Buluk",
  "Buluş",
  "Bulut",
  "Bumın",
  "Bunsuz",
  "Burçak",
  "Burguçan",
  "Burkay",
  "Burslan",
  "Burulday",
  "Burulgu",
  "Burunduk",
  "Buşulgan",
  "Butak",
  "Butuk",
  "Buyan",
  "Buyançuk",
  "Buyandemir",
  "Buyankara",
  "Buyat",
  "Buyraç",
  "Buyruç",
  "Buyruk",
  "Buzaç",
  "Buzaçtutuk",
  "Büdüs",
  "Büdüstudun",
  "Bügü",
  "Bügdüz",
  "Bügdüzemen",
  "Büge",
  "Büğübilge",
  "Bükdüz",
  "Büke",
  "Bükebuyraç",
  "Bükebuyruç",
  "Bükey",
  "Büktegin",
  "Büküşboğa",
  "Bümen",
  "Bünül",
  "Büre",
  "Bürgüt",
  "Bürkek",
  "Bürküt",
  "Bürlük",
  "Cebe",
  "Ceyhun",
  "Cılasun",
  "Çaba",
  "Çabdar",
  "Çablı",
  "Çabuş",
  "Çağan",
  "Çağatay",
  "Çağlar",
  "Çağlayan",
  "Çağrı",
  "Çağrıbeğ",
  "Çağrıtegin",
  "Çağru",
  "Çalapkulu",
  "Çankız",
  "Çemen",
  "Çemgen",
  "Çeykün",
  "Çıngır",
  "Çiçek",
  "Çiçem",
  "Çiğdem",
  "Çilenti",
  "Çimen",
  "Çobulmak",
  "Çocukbörü",
  "Çokramayul",
  "Çolman",
  "Çolpan",
  "Çölü",
  "Damla",
  "Deniz",
  "Dilek",
  "Diri",
  "Dizik",
  "Duru",
  "Dururbunsuz",
  "Duygu",
  "Ebin",
  "Ebkızı",
  "Ebren",
  "Edil",
  "Ediz",
  "Egemen",
  "Eğrim",
  "Ekeç",
  "Ekim",
  "Ekin",
  "Elkin",
  "Elti",
  "Engin",
  "Erdem",
  "Erdeni",
  "Erdeniözük",
  "Erdenikatun",
  "Erentüz",
  "Ergene",
  "Ergenekatun",
  "Erinç",
  "Erke",
  "Ermen",
  "Erten",
  "Ertenözük",
  "Esen",
  "Esenbike",
  "Eser",
  "Esin",
  "Etil",
  "Evin",
  "Eyiz",
  "Gelin",
  "Gelincik",
  "Gökbörü",
  "Gökçe",
  "Gökçegöl",
  "Gökçen",
  "Gökçiçek",
  "Gökşin",
  "Gönül",
  "Görün",
  "Gözde",
  "Gülegen",
  "Gülemen",
  "Güler",
  "Gülümser",
  "Gümüş",
  "Gün",
  "Günay",
  "Günçiçek",
  "Gündoğdu",
  "Gündoğmuş",
  "Güneş",
  "Günyaruk",
  "Gürbüz",
  "Güvercin",
  "Güzey",
  "Işığ",
  "Işık",
  "Işıl",
  "Işılay",
  "Ila",
  "Ilaçın",
  "Ilgın",
  "Inanç",
  "Irmak",
  "Isığ",
  "Isık",
  "Iyık",
  "Iyıktağ",
  "İdil",
  "İkeme",
  "İkiçitoyun",
  "İlbilge",
  "İldike",
  "İlgegü",
  "İmrem",
  "İnci",
  "İnç",
  "İrinç",
  "İrinçköl",
  "İrtiş",
  "İtil",
  "Kancı",
  "Kançı",
  "Kapgar",
  "Karaca",
  "Karaça",
  "Karak",
  "Kargılaç",
  "Karlıgaç",
  "Katun",
  "Katunkız",
  "Kayacık",
  "Kayaçık",
  "Kayça",
  "Kaynak",
  "Kazanç",
  "Kazkatun",
  "Kekik",
  "Keklik",
  "Kepez",
  "Kesme",
  "Keyken",
  "Kezlik",
  "Kımız",
  "Kımızın",
  "Kımızalma",
  "Kımızalmıla",
  "Kırçiçek",
  "Kırgavul",
  "Kırlangıç",
  "Kıvanç",
  "Kıvılcım",
  "Kızdurmuş",
  "Kızılalma"
];
