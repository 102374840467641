module["exports"] = [
  "Bánovce nad Bebravou",
  "Banská Bystrica",
  "Banská Štiavnica",
  "Bardejov",
  "Bratislava I",
  "Bratislava II",
  "Bratislava III",
  "Bratislava IV",
  "Bratislava V",
  "Brezno",
  "Bytča",
  "Čadca",
  "Detva",
  "Dolný Kubín",
  "Dunajská Streda",
  "Galanta",
  "Gelnica",
  "Hlohovec",
  "Humenné",
  "Ilava",
  "Kežmarok",
  "Komárno",
  "Košice I",
  "Košice II",
  "Košice III",
  "Košice IV",
  "Košice-okolie",
  "Krupina",
  "Kysucké Nové Mesto",
  "Levice",
  "Levoča",
  "Liptovský Mikuláš",
  "Lučenec",
  "Malacky",
  "Martin",
  "Medzilaborce",
  "Michalovce",
  "Myjava",
  "Námestovo",
  "Nitra",
  "Nové Mesto n.Váhom",
  "Nové Zámky",
  "Partizánske",
  "Pezinok",
  "Piešťany",
  "Poltár",
  "Poprad",
  "Považská Bystrica",
  "Prešov",
  "Prievidza",
  "Púchov",
  "Revúca",
  "Rimavská Sobota",
  "Rožňava",
  "Ružomberok",
  "Sabinov",
  "Šaľa",
  "Senec",
  "Senica",
  "Skalica",
  "Snina",
  "Sobrance",
  "Spišská Nová Ves",
  "Stará Ľubovňa",
  "Stropkov",
  "Svidník",
  "Topoľčany",
  "Trebišov",
  "Trenčín",
  "Trnava",
  "Turčianske Teplice",
  "Tvrdošín",
  "Veľký Krtíš",
  "Vranov nad Topľou",
  "Žarnovica",
  "Žiar nad Hronom",
  "Žilina",
  "Zlaté Moravce",
  "Zvolen"
];
