module["exports"] = [
  "TCP",
  "HTTP",
  "SDD",
  "RAM",
  "GB",
  "CSS",
  "SSL",
  "AGP",
  "SQL",
  "FTP",
  "PCI",
  "AI",
  "ADP",
  "RSS",
  "XML",
  "EXE",
  "COM",
  "HDD",
  "THX",
  "SMTP",
  "SMS",
  "USB",
  "PNG",
  "SAS",
  "IB",
  "SCSI",
  "JSON",
  "XSS",
  "JBOD"
];
