module["exports"] = [
  "Fet",
  "Gjes",
  "Høy",
  "Inn",
  "Fager",
  "Lille",
  "Lo",
  "Mal",
  "Nord",
  "Nær",
  "Sand",
  "Sme",
  "Stav",
  "Stor",
  "Tand",
  "Ut",
  "Vest"
];
