module["exports"] = {
  "descriptor": [
    "Головний",
    "Генеральний",
    "Провідний",
    "Національний",
    "Регіональний",
    "Обласний",
    "Районний",
    "Глобальний",
    "Міжнародний",
    "Центральний"
  ],
  "level": [
    "маркетинговий",
    "оптимізаційний",
    "страховий",
    "функціональний",
    "інтеграційний",
    "логістичний"
  ],
  "job": [
    "інженер",
    "агент",
    "адміністратор",
    "аналітик",
    "архітектор",
    "дизайнер",
    "керівник",
    "консультант",
    "координатор",
    "менеджер",
    "планувальник",
    "помічник",
    "розробник",
    "спеціаліст",
    "співробітник",
    "технік"
  ]
};
