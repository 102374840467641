module["exports"] = [
  "Abyasa",
  "Ade",
  "Adhiarja",
  "Adiarja",
  "Adika",
  "Adikara",
  "Adinata",
  "Aditya",
  "Agus",
  "Ajiman",
  "Ajimat",
  "Ajimin",
  "Ajiono",
  "Akarsana",
  "Alambana",
  "Among",
  "Anggabaya",
  "Anom",
  "Argono",
  "Aris",
  "Arta",
  "Artanto",
  "Artawan",
  "Arsipatra",
  "Asirwada",
  "Asirwanda",
  "Aslijan",
  "Asmadi",
  "Asman",
  "Asmianto",
  "Asmuni",
  "Aswani",
  "Atma",
  "Atmaja",
  "Bagas",
  "Bagiya",
  "Bagus",
  "Bagya",
  "Bahuraksa",
  "Bahuwarna",
  "Bahuwirya",
  "Bajragin",
  "Bakda",
  "Bakiadi",
  "Bakianto",
  "Bakidin",
  "Bakijan",
  "Bakiman",
  "Bakiono",
  "Bakti",
  "Baktiadi",
  "Baktianto",
  "Baktiono",
  "Bala",
  "Balamantri",
  "Balangga",
  "Balapati",
  "Balidin",
  "Balijan",
  "Bambang",
  "Banara",
  "Banawa",
  "Banawi",
  "Bancar",
  "Budi",
  "Cagak",
  "Cager",
  "Cahyadi",
  "Cahyanto",
  "Cahya",
  "Cahyo",
  "Cahyono",
  "Caket",
  "Cakrabirawa",
  "Cakrabuana",
  "Cakrajiya",
  "Cakrawala",
  "Cakrawangsa",
  "Candra",
  "Chandra",
  "Candrakanta",
  "Capa",
  "Caraka",
  "Carub",
  "Catur",
  "Caturangga",
  "Cawisadi",
  "Cawisono",
  "Cawuk",
  "Cayadi",
  "Cecep",
  "Cemani",
  "Cemeti",
  "Cemplunk",
  "Cengkal",
  "Cengkir",
  "Dacin",
  "Dadap",
  "Dadi",
  "Dagel",
  "Daliman",
  "Dalimin",
  "Daliono",
  "Damar",
  "Damu",
  "Danang",
  "Daniswara",
  "Danu",
  "Danuja",
  "Dariati",
  "Darijan",
  "Darimin",
  "Darmaji",
  "Darman",
  "Darmana",
  "Darmanto",
  "Darsirah",
  "Dartono",
  "Daru",
  "Daruna",
  "Daryani",
  "Dasa",
  "Digdaya",
  "Dimas",
  "Dimaz",
  "Dipa",
  "Dirja",
  "Drajat",
  "Dwi",
  "Dono",
  "Dodo",
  "Edi",
  "Eka",
  "Elon",
  "Eluh",
  "Eman",
  "Emas",
  "Embuh",
  "Emong",
  "Empluk",
  "Endra",
  "Enteng",
  "Estiawan",
  "Estiono",
  "Eko",
  "Edi",
  "Edison",
  "Edward",
  "Elvin",
  "Erik",
  "Emil",
  "Ega",
  "Emin",
  "Eja",
  "Gada",
  "Gadang",
  "Gaduh",
  "Gaiman",
  "Galak",
  "Galang",
  "Galar",
  "Galih",
  "Galiono",
  "Galuh",
  "Galur",
  "Gaman",
  "Gamani",
  "Gamanto",
  "Gambira",
  "Gamblang",
  "Ganda",
  "Gandewa",
  "Gandi",
  "Gandi",
  "Ganep",
  "Gangsa",
  "Gangsar",
  "Ganjaran",
  "Gantar",
  "Gara",
  "Garan",
  "Garang",
  "Garda",
  "Gatot",
  "Gatra",
  "Gilang",
  "Galih",
  "Ghani",
  "Gading",
  "Hairyanto",
  "Hardana",
  "Hardi",
  "Harimurti",
  "Harja",
  "Harjasa",
  "Harjaya",
  "Harjo",
  "Harsana",
  "Harsanto",
  "Harsaya",
  "Hartaka",
  "Hartana",
  "Harto",
  "Hasta",
  "Heru",
  "Himawan",
  "Hadi",
  "Halim",
  "Hasim",
  "Hasan",
  "Hendra",
  "Hendri",
  "Heryanto",
  "Hamzah",
  "Hari",
  "Imam",
  "Indra",
  "Irwan",
  "Irsad",
  "Ikhsan",
  "Irfan",
  "Ian",
  "Ibrahim",
  "Ibrani",
  "Ismail",
  "Irnanto",
  "Ilyas",
  "Ibun",
  "Ivan",
  "Ikin",
  "Ihsan",
  "Jabal",
  "Jaeman",
  "Jaga",
  "Jagapati",
  "Jagaraga",
  "Jail",
  "Jaiman",
  "Jaka",
  "Jarwa",
  "Jarwadi",
  "Jarwi",
  "Jasmani",
  "Jaswadi",
  "Jati",
  "Jatmiko",
  "Jaya",
  "Jayadi",
  "Jayeng",
  "Jinawi",
  "Jindra",
  "Joko",
  "Jumadi",
  "Jumari",
  "Jamal",
  "Jamil",
  "Jais",
  "Jefri",
  "Johan",
  "Jono",
  "Kacung",
  "Kajen",
  "Kambali",
  "Kamidin",
  "Kariman",
  "Karja",
  "Karma",
  "Karman",
  "Karna",
  "Karsa",
  "Karsana",
  "Karta",
  "Kasiran",
  "Kasusra",
  "Kawaca",
  "Kawaya",
  "Kayun",
  "Kemba",
  "Kenari",
  "Kenes",
  "Kuncara",
  "Kunthara",
  "Kusuma",
  "Kadir",
  "Kala",
  "Kalim",
  "Kurnia",
  "Kanda",
  "Kardi",
  "Karya",
  "Kasim",
  "Kairav",
  "Kenzie",
  "Kemal",
  "Kamal",
  "Koko",
  "Labuh",
  "Laksana",
  "Lamar",
  "Lanang",
  "Langgeng",
  "Lanjar",
  "Lantar",
  "Lega",
  "Legawa",
  "Lembah",
  "Liman",
  "Limar",
  "Luhung",
  "Lukita",
  "Luluh",
  "Lulut",
  "Lurhur",
  "Luwar",
  "Luwes",
  "Latif",
  "Lasmanto",
  "Lukman",
  "Luthfi",
  "Leo",
  "Luis",
  "Lutfan",
  "Lasmono",
  "Laswi",
  "Mahesa",
  "Makara",
  "Makuta",
  "Manah",
  "Maras",
  "Margana",
  "Mariadi",
  "Marsudi",
  "Martaka",
  "Martana",
  "Martani",
  "Marwata",
  "Maryadi",
  "Maryanto",
  "Mitra",
  "Mujur",
  "Mulya",
  "Mulyanto",
  "Mulyono",
  "Mumpuni",
  "Muni",
  "Mursita",
  "Murti",
  "Mustika",
  "Maman",
  "Mahmud",
  "Mahdi",
  "Mahfud",
  "Malik",
  "Muhammad",
  "Mustofa",
  "Marsito",
  "Mursinin",
  "Nalar",
  "Naradi",
  "Nardi",
  "Niyaga",
  "Nrima",
  "Nugraha",
  "Nyana",
  "Narji",
  "Nasab",
  "Nasrullah",
  "Nasim",
  "Najib",
  "Najam",
  "Nyoman",
  "Olga",
  "Ozy",
  "Omar",
  "Opan",
  "Oskar",
  "Oman",
  "Okto",
  "Okta",
  "Opung",
  "Paiman",
  "Panca",
  "Pangeran",
  "Pangestu",
  "Pardi",
  "Parman",
  "Perkasa",
  "Praba",
  "Prabu",
  "Prabawa",
  "Prabowo",
  "Prakosa",
  "Pranata",
  "Pranawa",
  "Prasetya",
  "Prasetyo",
  "Prayitna",
  "Prayoga",
  "Prayogo",
  "Purwadi",
  "Purwa",
  "Purwanto",
  "Panji",
  "Pandu",
  "Paiman",
  "Prima",
  "Putu",
  "Raden",
  "Raditya",
  "Raharja",
  "Rama",
  "Rangga",
  "Reksa",
  "Respati",
  "Rusman",
  "Rosman",
  "Rahmat",
  "Rahman",
  "Rendy",
  "Reza",
  "Rizki",
  "Ridwan",
  "Rudi",
  "Raden",
  "Radit",
  "Radika",
  "Rafi",
  "Rafid",
  "Raihan",
  "Salman",
  "Saadat",
  "Saiful",
  "Surya",
  "Slamet",
  "Samsul",
  "Soleh",
  "Simon",
  "Sabar",
  "Sabri",
  "Sidiq",
  "Satya",
  "Setya",
  "Saka",
  "Sakti",
  "Taswir",
  "Tedi",
  "Teddy",
  "Taufan",
  "Taufik",
  "Tomi",
  "Tasnim",
  "Teguh",
  "Tasdik",
  "Timbul",
  "Tirta",
  "Tirtayasa",
  "Tri",
  "Tugiman",
  "Umar",
  "Usman",
  "Uda",
  "Umay",
  "Unggul",
  "Utama",
  "Umaya",
  "Upik",
  "Viktor",
  "Vino",
  "Vinsen",
  "Vero",
  "Vega",
  "Viman",
  "Virman",
  "Wahyu",
  "Wira",
  "Wisnu",
  "Wadi",
  "Wardi",
  "Warji",
  "Waluyo",
  "Wakiman",
  "Wage",
  "Wardaya",
  "Warsa",
  "Warsita",
  "Warta",
  "Wasis",
  "Wawan",
  "Xanana",
  "Yahya",
  "Yusuf",
  "Yosef",
  "Yono",
  "Yoga"
];