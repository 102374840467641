module["exports"] = [
  "S.Ked",
  "S.Gz",
  "S.Pt",
  "S.IP",
  "S.E.I",
  "S.E.",
  "S.Kom",
  "S.H.",
  "S.T.",
  "S.Pd",
  "S.Psi",
  "S.I.Kom",
  "S.Sos",
  "S.Farm",
  "M.M.",
  "M.Kom.",
  "M.TI.",
  "M.Pd",
  "M.Farm",
  "M.Ak"
];