module["exports"] = [
  "ability",
  "access",
  "adapter",
  "algorithm",
  "alliance",
  "analyzer",
  "application",
  "approach",
  "architecture",
  "archive",
  "artificial intelligence",
  "array",
  "attitude",
  "benchmark",
  "budgetary management",
  "capability",
  "capacity",
  "challenge",
  "circuit",
  "collaboration",
  "complexity",
  "concept",
  "conglomeration",
  "contingency",
  "core",
  "customer loyalty",
  "database",
  "data-warehouse",
  "definition",
  "emulation",
  "encoding",
  "encryption",
  "extranet",
  "firmware",
  "flexibility",
  "focus group",
  "forecast",
  "frame",
  "framework",
  "function",
  "functionalities",
  "Graphic Interface",
  "groupware",
  "Graphical User Interface",
  "hardware",
  "help-desk",
  "hierarchy",
  "hub",
  "implementation",
  "info-mediaries",
  "infrastructure",
  "initiative",
  "installation",
  "instruction set",
  "interface",
  "internet solution",
  "intranet",
  "knowledge user",
  "knowledge base",
  "local area network",
  "leverage",
  "matrices",
  "matrix",
  "methodology",
  "middleware",
  "migration",
  "model",
  "moderator",
  "monitoring",
  "moratorium",
  "neural-net",
  "open architecture",
  "open system",
  "orchestration",
  "paradigm",
  "parallelism",
  "policy",
  "portal",
  "pricing structure",
  "process improvement",
  "product",
  "productivity",
  "project",
  "projection",
  "protocol",
  "secured line",
  "service-desk",
  "software",
  "solution",
  "standardization",
  "strategy",
  "structure",
  "success",
  "superstructure",
  "support",
  "synergy",
  "system engine",
  "task-force",
  "throughput",
  "time-frame",
  "toolset",
  "utilisation",
  "website",
  "workforce"
];
