module["exports"] = [
  "Anar",
  "Amid",
  "Afəl",
  "Abbas",
  "Abdulla",
  "Adil",
  "Akif",
  "Aqil",
  "Bəhram",
  "Nurlan",
  "Rafiq",
  "Tərlan",
  "Zaur",
  "Emin",
  "Emil",
  "Kamran",
  "Elnur",
  "Natiq",
  "Rəşad",
  "Rəşid",
  "Tahir",
  "Əhməd",
  "Zahir",
  "İlham",
  "İlqar",
  "Nahid",
  "Nihad",
  "Faiq",
  "İxtiyar",
  "Şəhriyar",
  "Şaiq",
  "Bəxtiyar",
  "Bəhruz",
  "Tunar",
  "Nadir"
];
