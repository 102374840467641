module["exports"] = [
  "Amato",
  "Barbieri",
  "Barone",
  "Basile",
  "Battaglia",
  "Bellini",
  "Benedetti",
  "Bernardi",
  "Bianc",
  "Bianchi",
  "Bruno",
  "Caputo",
  "Carbon",
  "Caruso",
  "Cattaneo",
  "Colombo",
  "Cont",
  "Conte",
  "Coppola",
  "Costa",
  "Costantin",
  "D'amico",
  "D'angelo",
  "Damico",
  "De Angelis",
  "De luca",
  "De rosa",
  "De Santis",
  "Donati",
  "Esposito",
  "Fabbri",
  "Farin",
  "Ferrara",
  "Ferrari",
  "Ferraro",
  "Ferretti",
  "Ferri",
  "Fior",
  "Fontana",
  "Galli",
  "Gallo",
  "Gatti",
  "Gentile",
  "Giordano",
  "Giuliani",
  "Grassi",
  "Grasso",
  "Greco",
  "Guerra",
  "Leone",
  "Lombardi",
  "Lombardo",
  "Longo",
  "Mancini",
  "Marchetti",
  "Marian",
  "Marini",
  "Marino",
  "Martinelli",
  "Martini",
  "Martino",
  "Mazza",
  "Messina",
  "Milani",
  "Montanari",
  "Monti",
  "Morelli",
  "Moretti",
  "Negri",
  "Neri",
  "Orlando",
  "Pagano",
  "Palmieri",
  "Palumbo",
  "Parisi",
  "Pellegrini",
  "Pellegrino",
  "Piras",
  "Ricci",
  "Rinaldi",
  "Riva",
  "Rizzi",
  "Rizzo",
  "Romano",
  "Ross",
  "Rossetti",
  "Ruggiero",
  "Russo",
  "Sala",
  "Sanna",
  "Santoro",
  "Sartori",
  "Serr",
  "Silvestri",
  "Sorrentino",
  "Testa",
  "Valentini",
  "Villa",
  "Vitale",
  "Vitali"
];
