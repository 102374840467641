module["exports"] = [
  "NSW",
  "QLD",
  "NT",
  "SA",
  "WA",
  "TAS",
  "ACT",
  "VIC"
];
