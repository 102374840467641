module["exports"] = [
  "valore aggiunto",
  "verticalizzate",
  "proattive",
  "forti",
  "rivoluzionari",
  "scalabili",
  "innovativi",
  "intuitivi",
  "strategici",
  "e-business",
  "mission-critical",
  "24/7",
  "globali",
  "B2B",
  "B2C",
  "granulari",
  "virtuali",
  "virali",
  "dinamiche",
  "magnetiche",
  "web",
  "interattive",
  "sexy",
  "back-end",
  "real-time",
  "efficienti",
  "front-end",
  "distributivi",
  "estensibili",
  "mondiali",
  "open-source",
  "cross-platform",
  "sinergiche",
  "out-of-the-box",
  "enterprise",
  "integrate",
  "di impatto",
  "wireless",
  "trasparenti",
  "next-generation",
  "cutting-edge",
  "visionari",
  "plug-and-play",
  "collaborative",
  "olistiche",
  "ricche"
];
