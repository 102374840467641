module["exports"] = [
  "Afganistán",
  "Albania",
  "Argelia",
  "Andorra",
  "Angola",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbayán",
  "Bahamas",
  "Barein",
  "Bangladesh",
  "Barbados",
  "Bielorusia",
  "Bélgica",
  "Belice",
  "Bermuda",
  "Bután",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brasil",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Camboya",
  "Camerún",
  "Canada",
  "Cabo Verde",
  "Islas Caimán",
  "Chad",
  "Chile",
  "China",
  "Isla de Navidad",
  "Colombia",
  "Comodos",
  "Congo",
  "Costa Rica",
  "Costa de Marfil",
  "Croacia",
  "Cuba",
  "Chipre",
  "República Checa",
  "Dinamarca",
  "Dominica",
  "República Dominicana",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Guinea Ecuatorial",
  "Eritrea",
  "Estonia",
  "Etiopía",
  "Islas Faro",
  "Fiji",
  "Finlandia",
  "Francia",
  "Gabón",
  "Gambia",
  "Georgia",
  "Alemania",
  "Ghana",
  "Grecia",
  "Groenlandia",
  "Granada",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bisau",
  "Guayana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungria",
  "Islandia",
  "India",
  "Indonesia",
  "Iran",
  "Irak",
  "Irlanda",
  "Italia",
  "Jamaica",
  "Japón",
  "Jordania",
  "Kazajistan",
  "Kenia",
  "Kiribati",
  "Corea",
  "Kuwait",
  "Letonia",
  "Líbano",
  "Liberia",
  "Liechtenstein",
  "Lituania",
  "Luxemburgo",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malasia",
  "Maldivas",
  "Mali",
  "Malta",
  "Martinica",
  "Mauritania",
  "México",
  "Micronesia",
  "Moldavia",
  "Mónaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Marruecos",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Holanda",
  "Nueva Zelanda",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Noruega",
  "Omán",
  "Pakistan",
  "Panamá",
  "Papúa Nueva Guinea",
  "Paraguay",
  "Perú",
  "Filipinas",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Rusia",
  "Ruanda",
  "Samoa",
  "San Marino",
  "Santo Tomé y Principe",
  "Arabia Saudí",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leona",
  "Singapur",
  "Eslovaquia",
  "Eslovenia",
  "Somalia",
  "España",
  "Sri Lanka",
  "Sudán",
  "Suriname",
  "Suecia",
  "Suiza",
  "Siria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Tailandia",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad y Tobago",
  "Tunez",
  "Turquia",
  "Uganda",
  "Ucrania",
  "Emiratos Árabes Unidos",
  "Reino Unido",
  "Estados Unidos de América",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];
