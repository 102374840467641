var ge = {};
module['exports'] = ge;
ge.title = "Georgian";
ge.separator = " და ";
ge.name = require("./name");
ge.address = require("./address");
ge.internet = require("./internet");
ge.company = require("./company");
ge.phone_number = require("./phone_number");
ge.cell_phone = require("./cell_phone");
