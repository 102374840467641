module["exports"] = [
  "Abilità",
  "Access",
  "Adattatore",
  "Algoritmo",
  "Alleanza",
  "Analizzatore",
  "Applicazione",
  "Approccio",
  "Architettura",
  "Archivio",
  "Intelligenza artificiale",
  "Array",
  "Attitudine",
  "Benchmark",
  "Capacità",
  "Sfida",
  "Circuito",
  "Collaborazione",
  "Complessità",
  "Concetto",
  "Conglomerato",
  "Contingenza",
  "Core",
  "Database",
  "Data-warehouse",
  "Definizione",
  "Emulazione",
  "Codifica",
  "Criptazione",
  "Firmware",
  "Flessibilità",
  "Previsione",
  "Frame",
  "framework",
  "Funzione",
  "Funzionalità",
  "Interfaccia grafica",
  "Hardware",
  "Help-desk",
  "Gerarchia",
  "Hub",
  "Implementazione",
  "Infrastruttura",
  "Iniziativa",
  "Installazione",
  "Set di istruzioni",
  "Interfaccia",
  "Soluzione internet",
  "Intranet",
  "Conoscenza base",
  "Matrici",
  "Matrice",
  "Metodologia",
  "Middleware",
  "Migrazione",
  "Modello",
  "Moderazione",
  "Monitoraggio",
  "Moratoria",
  "Rete",
  "Architettura aperta",
  "Sistema aperto",
  "Orchestrazione",
  "Paradigma",
  "Parallelismo",
  "Policy",
  "Portale",
  "Struttura di prezzo",
  "Prodotto",
  "Produttività",
  "Progetto",
  "Proiezione",
  "Protocollo",
  "Servizio clienti",
  "Software",
  "Soluzione",
  "Standardizzazione",
  "Strategia",
  "Struttura",
  "Successo",
  "Sovrastruttura",
  "Supporto",
  "Sinergia",
  "Task-force",
  "Finestra temporale",
  "Strumenti",
  "Utilizzazione",
  "Sito web",
  "Forza lavoro"
];
