module["exports"] = [
  "巷",
  "街",
  "路",
  "桥",
  "侬",
  "旁",
  "中心",
  "栋"
];
