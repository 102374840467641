module["exports"] = [
  "id",
  "title",
  "name",
  "email",
  "phone",
  "token",
  "group",
  "category",
  "password",
  "comment",
  "avatar",
  "status",
  "createdAt",
  "updatedAt"
];
