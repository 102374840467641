module["exports"] = [
  "Afghánistán",
  "Albánie",
  "Alžírsko",
  "Andorra",
  "Angola",
  "Antigua a Barbuda",
  "Argentina",
  "Arménie",
  "Austrálie",
  "Ázerbájdžán",
  "Bahamy",
  "Bahrajn",
  "Bangladéš",
  "Barbados",
  "Belgie",
  "Belize",
  "Benin",
  "Bělorusko",
  "Bhútán",
  "Bolívie",
  "Bosna a Hercegovina",
  "Botswana",
  "Brazílie",
  "Brunej",
  "Bulharsko",
  "Burkina Faso",
  "Burundi",
  "Čad",
  "Černá Hora",
  "Česko",
  "Čína",
  "Dánsko",
  "DR Kongo",
  "Dominika",
  "Dominik",
  "Džibutsko",
  "Egypt",
  "Ekvádor",
  "Eritrea",
  "Estonsko",
  "Etiopie",
  "Fidži",
  "Filipíny",
  "Finsko",
  "Francie",
  "Gabon",
  "Gambie",
  "Gruzie",
  "Německo",
  "Ghana",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Chile",
  "Chorvatsko",
  "Indie",
  "Indonésie",
  "Irák",
  "Írán",
  "Irsko",
  "Island",
  "Itálie",
  "Izrael",
  "Jamajka",
  "Japonsko",
  "Jemen",
  "Jihoaf",
  "Jižní Korea",
  "Jižní Súdán",
  "Jordánsko",
  "Kambodža",
  "Kamerun",
  "Kanada",
  "Kapverdy",
  "Katar",
  "Kazachstán",
  "Keňa",
  "Kiribati",
  "Kolumbie",
  "Komory",
  "Kongo",
  "Kostarika",
  "Kuba",
  "Kuvajt",
  "Kypr",
  "Kyrgyzstán",
  "Laos",
  "Lesotho",
  "Libanon",
  "Libérie",
  "Libye",
  "Lichtenštejnsko",
  "Litva",
  "Lotyšsko",
  "Lucembursko",
  "Madagaskar",
  "Maďarsko",
  "Makedonie",
  "Malajsie",
  "Malawi",
  "Maledivy",
  "Mali",
  "Malta",
  "Maroko",
  "Marshallovy ostrovy",
  "Mauritánie",
  "Mauricius",
  "Mexiko",
  "Mikronésie",
  "Moldavsko",
  "Monako",
  "Mongolsko",
  "Mosambik",
  "Myanmar (Barma)",
  "Namibie",
  "Nauru",
  "Nepál",
  "Niger",
  "Nigérie",
  "Nikaragua",
  "Nizozemsko",
  "Norsko",
  "Nový Zéland",
  "Omán",
  "Pákistán",
  "Palau",
  "Palestina",
  "Panama",
  "Papua-Nová Guinea",
  "Paraguay",
  "Peru",
  "Pobřeží slonoviny",
  "Polsko",
  "Portugalsko",
  "Rakousko",
  "Rovníková Guinea",
  "Rumunsko",
  "Rusko",
  "Rwanda",
  "Řecko",
  "Salvador",
  "Samoa",
  "San Marino",
  "Saúdská Arábie",
  "Senegal",
  "Severní Korea",
  "Seychely",
  "Sierra Leone",
  "Singapur",
  "Slovensko",
  "Slovinsko",
  "Srbsko",
  "Středo",
  "Somálsko",
  "Surinam",
  "Súdán",
  "Svatá Lucie",
  "Svatý Kryštof a Nevis",
  "Svatý Tomáš a Princův ostrov",
  "Svatý Vincenc a Grenadiny",
  "Svazijsko",
  "Spojené arabské emiráty",
  "Spojené království",
  "Spojené státy americké",
  "Sýrie",
  "Šalamounovy ostrovy",
  "Španělsko",
  "Srí Lanka",
  "Švédsko",
  "Švýcarsko",
  "Tádžikistán",
  "Tanzanie",
  "Thajsko",
  "Togo",
  "Tonga",
  "Trinidad a Tobago",
  "Tunisko",
  "Turecko",
  "Turkmenistán",
  "Tuvalu",
  "Uganda",
  "Ukrajina",
  "Uruguay",
  "Uzbekistán",
  "Vanuatu",
  "Vatikán",
  "Venezuela",
  "Vietnam",
  "Východní Timor",
  "Zambie",
  "Zimbabwe",
];
