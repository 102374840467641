module["exports"] = [
  "cherkassy.ua",
  "cherkasy.ua",
  "ck.ua",
  "cn.ua",
  "com.ua",
  "crimea.ua",
  "cv.ua",
  "dn.ua",
  "dnepropetrovsk.ua",
  "dnipropetrovsk.ua",
  "donetsk.ua",
  "dp.ua",
  "if.ua",
  "in.ua",
  "ivano-frankivsk.ua",
  "kh.ua",
  "kharkiv.ua",
  "kharkov.ua",
  "kherson.ua",
  "khmelnitskiy.ua",
  "kiev.ua",
  "kirovograd.ua",
  "km.ua",
  "kr.ua",
  "ks.ua",
  "lg.ua",
  "lt.ua",
  "lugansk.ua",
  "lutsk.ua",
  "lutsk.net",
  "lviv.ua",
  "mk.ua",
  "net.ua",
  "nikolaev.ua",
  "od.ua",
  "odessa.ua",
  "org.ua",
  "pl.ua",
  "pl.ua",
  "poltava.ua",
  "rovno.ua",
  "rv.ua",
  "sebastopol.ua",
  "sm.ua",
  "sumy.ua",
  "te.ua",
  "ternopil.ua",
  "ua",
  "uz.ua",
  "uzhgorod.ua",
  "vinnica.ua",
  "vn.ua",
  "volyn.net",
  "volyn.ua",
  "yalta.ua",
  "zaporizhzhe.ua",
  "zhitomir.ua",
  "zp.ua",
  "zt.ua",
  "укр"
];
