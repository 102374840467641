module["exports"] = [
  "Atatürk Bulvarı",
  "Alparslan Türkeş Bulvarı",
  "Ali Çetinkaya Caddesi",
  "Tevfik Fikret Caddesi",
  "Kocatepe Caddesi",
  "İsmet Paşa Caddesi",
  "30 Ağustos Caddesi",
  "İsmet Attila Caddesi",
  "Namık Kemal Caddesi",
  "Lütfi Karadirek Caddesi",
  "Sarıkaya Caddesi",
  "Yunus Emre Sokak",
  "Dar Sokak",
  "Fatih Sokak ",
  "Harman Yolu Sokak ",
  "Ergenekon Sokak  ",
  "Ülkü Sokak",
  "Sağlık Sokak",
  "Okul Sokak",
  "Harman Altı Sokak",
  "Kaldırım Sokak",
  "Mevlana Sokak",
  "Gül Sokak",
  "Sıran Söğüt Sokak",
  "Güven Yaka Sokak",
  "Saygılı Sokak",
  "Menekşe Sokak",
  "Dağınık Evler Sokak",
  "Sevgi Sokak",
  "Afyon Kaya Sokak",
  "Oğuzhan Sokak",
  "İbn-i Sina Sokak",
  "Okul Sokak",
  "Bahçe Sokak",
  "Köypınar Sokak",
  "Kekeçoğlu Sokak",
  "Barış Sokak",
  "Bayır Sokak",
  "Kerimoğlu Sokak",
  "Nalbant Sokak",
  "Bandak Sokak"
];
