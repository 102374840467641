module["exports"] = [
  "大翔",
  "蓮",
  "颯太",
  "樹",
  "大和",
  "陽翔",
  "陸斗",
  "太一",
  "海翔",
  "蒼空",
  "翼",
  "陽菜",
  "結愛",
  "結衣",
  "杏",
  "莉子",
  "美羽",
  "結菜",
  "心愛",
  "愛菜",
  "美咲"
];
