module["exports"] = [
  "Maria",
  "Anna",
  "Margareta",
  "Elisabeth",
  "Eva",
  "Birgitta",
  "Kristina",
  "Karin",
  "Elisabet",
  "Marie"
];
