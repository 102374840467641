module["exports"] = [
  "красный",
  "зеленый",
  "синий",
  "желтый",
  "багровый",
  "мятный",
  "зеленовато-голубой",
  "белый",
  "черный",
  "оранжевый",
  "розовый",
  "серый",
  "красно-коричневый",
  "фиолетовый",
  "бирюзовый",
  "желто-коричневый",
  "небесно голубой",
  "оранжево-розовый",
  "темно-фиолетовый",
  "орхидный",
  "оливковый",
  "пурпурный",
  "лимонный",
  "кремовый",
  "сине-фиолетовый",
  "золотой",
  "красно-пурпурный",
  "голубой",
  "лазурный",
  "лиловый",
  "серебряный"
];
