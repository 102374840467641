module["exports"] = [
  "Советская",
  "Молодежная",
  "Центральная",
  "Школьная",
  "Новая",
  "Садовая",
  "Лесная",
  "Набережная",
  "Ленина",
  "Мира",
  "Октябрьская",
  "Зеленая",
  "Комсомольская",
  "Заречная",
  "Первомайская",
  "Гагарина",
  "Полевая",
  "Луговая",
  "Пионерская",
  "Кирова",
  "Юбилейная",
  "Северная",
  "Пролетарская",
  "Степная",
  "Пушкина",
  "Калинина",
  "Южная",
  "Колхозная",
  "Рабочая",
  "Солнечная",
  "Железнодорожная",
  "Восточная",
  "Заводская",
  "Чапаева",
  "Нагорная",
  "Строителей",
  "Береговая",
  "Победы",
  "Горького",
  "Кооперативная",
  "Красноармейская",
  "Совхозная",
  "Речная",
  "Школьный",
  "Спортивная",
  "Озерная",
  "Строительная",
  "Парковая",
  "Чкалова",
  "Мичурина",
  "речень улиц",
  "Подгорная",
  "Дружбы",
  "Почтовая",
  "Партизанская",
  "Вокзальная",
  "Лермонтова",
  "Свободы",
  "Дорожная",
  "Дачная",
  "Маяковского",
  "Западная",
  "Фрунзе",
  "Дзержинского",
  "Московская",
  "Свердлова",
  "Некрасова",
  "Гоголя",
  "Красная",
  "Трудовая",
  "Шоссейная",
  "Чехова",
  "Коммунистическая",
  "Труда",
  "Комарова",
  "Матросова",
  "Островского",
  "Сосновая",
  "Клубная",
  "Куйбышева",
  "Крупской",
  "Березовая",
  "Карла Маркса",
  "8 Марта",
  "Больничная",
  "Садовый",
  "Интернациональная",
  "Суворова",
  "Цветочная",
  "Трактовая",
  "Ломоносова",
  "Горная",
  "Космонавтов",
  "Энергетиков",
  "Шевченко",
  "Весенняя",
  "Механизаторов",
  "Коммунальная",
  "Лесной",
  "40 лет Победы",
  "Майская"
];
