module["exports"] = [
  "Antalová",
  "Babková",
  "Bahnová",
  "Balážová",
  "Baranová",
  "Baranková",
  "Bartovičová",
  "Bartošová",
  "Bačová",
  "Bernoláková",
  "Beňová",
  "Biceková",
  "Bieliková",
  "Blahová",
  "Bondrová",
  "Bosáková",
  "Bošková",
  "Brezinová",
  "Bukovská",
  "Chalupková",
  "Chudíková",
  "Cibulová",
  "Cibulková",
  "Cyprichová",
  "Cígerová",
  "Danková",
  "Daňková",
  "Daňová",
  "Debnárová",
  "Dejová",
  "Dekýšová",
  "Doležalová",
  "Dočolomanská",
  "Droppová",
  "Dubovská",
  "Dudeková",
  "Dulová",
  "Dullová",
  "Dusíková",
  "Dvončová",
  "Dzurjaninová",
  "Dávidová",
  "Fabianová",
  "Fabiánová",
  "Fajnorová",
  "Farkašovská",
  "Ficová",
  "Filcová",
  "Filipová",
  "Finková",
  "Ftoreková",
  "Gašparová",
  "Gašparovičová",
  "Gocníková",
  "Gregorová",
  "Gregušová",
  "Grznárová",
  "Habláková",
  "Habšudová",
  "Haldová",
  "Halušková",
  "Haláková",
  "Hanková",
  "Hanzalová",
  "Haščáková",
  "Heretiková",
  "Hečková",
  "Hlaváčeková",
  "Hlinková",
  "Holubová",
  "Holubyová",
  "Hossová",
  "Hozová",
  "Hrašková",
  "Hricová",
  "Hrmová",
  "Hrušovská",
  "Hubová",
  "Ihnačáková",
  "Janečeková",
  "Janošková",
  "Jantošovičová",
  "Janíková",
  "Jančeková",
  "Jedľovská",
  "Jendeková",
  "Jonatová",
  "Jurinová",
  "Jurkovičová",
  "Juríková",
  "Jánošíková",
  "Kafendová",
  "Kaliská",
  "Karulová",
  "Kenížová",
  "Klapková",
  "Kmeťová",
  "Kolesárová",
  "Kollárová",
  "Kolniková",
  "Kolníková",
  "Kolárová",
  "Korecová",
  "Kostkaová",
  "Kostrecová",
  "Kováčová",
  "Kováčiková",
  "Kozová",
  "Kočišová",
  "Krajíčeková",
  "Krajčová",
  "Krajčovičová",
  "Krajčírová",
  "Králiková",
  "Krúpová",
  "Kubíková",
  "Kyseľová",
  "Kállayová",
  "Labudová",
  "Lepšíková",
  "Liptáková",
  "Lisická",
  "Lubinová",
  "Lukáčová",
  "Luptáková",
  "Líšková",
  "Madejová",
  "Majeská",
  "Malachovská",
  "Malíšeková",
  "Mamojková",
  "Marcinková",
  "Mariánová",
  "Masaryková",
  "Maslová",
  "Matiašková",
  "Medveďová",
  "Melcerová",
  "Mečiarová",
  "Michalíková",
  "Mihaliková",
  "Mihálová",
  "Miháliková",
  "Miklošková",
  "Mikulíková",
  "Mikušová",
  "Mikúšová",
  "Milotová",
  "Mináčová",
  "Mišíková",
  "Mojžišová",
  "Mokrošová",
  "Morová",
  "Moravčíková",
  "Mydlová",
  "Nemcová",
  "Nováková",
  "Obšutová",
  "Ondrušová",
  "Otčenášová",
  "Pauková",
  "Pavlikovská",
  "Pavúková",
  "Pašeková",
  "Pašková",
  "Pelikánová",
  "Petrovická",
  "Petrušková",
  "Pešková",
  "Plchová",
  "Plekanecová",
  "Podhradská",
  "Podkonická",
  "Poliaková",
  "Pupáková",
  "Raková",
  "Repiská",
  "Romančíková",
  "Rusová",
  "Ružičková",
  "Rybníčeková",
  "Rybárová",
  "Rybáriková",
  "Samsonová",
  "Sedliaková",
  "Senková",
  "Sklenková",
  "Skokanová",
  "Skutecká",
  "Slašťanová",
  "Slobodová",
  "Slobodníková",
  "Slotová",
  "Slováková",
  "Smreková",
  "Stodolová",
  "Straková",
  "Strnisková",
  "Svrbíková",
  "Sámelová",
  "Sýkorová",
  "Tatarová",
  "Tatarková",
  "Tatárová",
  "Tatárkaová",
  "Thomková",
  "Tomečeková",
  "Tomková",
  "Trubenová",
  "Turčoková",
  "Uramová",
  "Urblíková",
  "Vajcíková",
  "Vajdová",
  "Valachová",
  "Valachovičová",
  "Valentová",
  "Valušková",
  "Vaneková",
  "Veselová",
  "Vicenová",
  "Višňovská",
  "Vlachová",
  "Vojteková",
  "Vydarená",
  "Zajacová",
  "Zimová",
  "Zimková",
  "Záborská",
  "Zúbriková",
  "Čapkovičová",
  "Čaplovičová",
  "Čarnogurská",
  "Čierná",
  "Čobrdová",
  "Ďaďová",
  "Ďuricová",
  "Ďurišová",
  "Šidlová",
  "Šimonovičová",
  "Škriniarová",
  "Škultétyová",
  "Šmajdová",
  "Šoltésová",
  "Šoltýsová",
  "Štefanová",
  "Štefanková",
  "Šulcová",
  "Šurková",
  "Švehlová",
  "Šťastná"
];
