module["exports"] = [
  "长",
  "上",
  "南",
  "西",
  "北",
  "诸",
  "宁",
  "珠",
  "武",
  "衡",
  "成",
  "福",
  "厦",
  "贵",
  "吉",
  "海",
  "太",
  "济",
  "安",
  "吉",
  "包"
];
