module["exports"] = [
  "Abacı",
  "Abadan",
  "Aclan",
  "Adal",
  "Adan",
  "Adıvar",
  "Akal",
  "Akan",
  "Akar ",
  "Akay",
  "Akaydın",
  "Akbulut",
  "Akgül",
  "Akışık",
  "Akman",
  "Akyürek",
  "Akyüz",
  "Akşit",
  "Alnıaçık",
  "Alpuğan",
  "Alyanak",
  "Arıcan",
  "Arslanoğlu",
  "Atakol",
  "Atan",
  "Avan",
  "Ayaydın",
  "Aybar",
  "Aydan",
  "Aykaç",
  "Ayverdi",
  "Ağaoğlu",
  "Aşıkoğlu",
  "Babacan",
  "Babaoğlu",
  "Bademci",
  "Bakırcıoğlu",
  "Balaban",
  "Balcı",
  "Barbarosoğlu",
  "Baturalp",
  "Baykam",
  "Başoğlu",
  "Berberoğlu",
  "Beşerler",
  "Beşok",
  "Biçer",
  "Bolatlı",
  "Dalkıran",
  "Dağdaş",
  "Dağlaroğlu",
  "Demirbaş",
  "Demirel",
  "Denkel",
  "Dizdar ",
  "Doğan ",
  "Durak ",
  "Durmaz",
  "Duygulu",
  "Düşenkalkar",
  "Egeli",
  "Ekici",
  "Ekşioğlu",
  "Eliçin",
  "Elmastaşoğlu",
  "Elçiboğa",
  "Erbay",
  "Erberk",
  "Erbulak",
  "Erdoğan",
  "Erez",
  "Erginsoy",
  "Erkekli",
  "Eronat",
  "Ertepınar",
  "Ertürk",
  "Erçetin",
  "Evliyaoğlu",
  "Gönültaş",
  "Gümüşpala",
  "Günday",
  "Gürmen",
  "Hakyemez",
  "Hamzaoğlu",
  "Ilıcalı",
  "Kahveci",
  "Kaplangı",
  "Karabulut",
  "Karaböcek",
  "Karadaş",
  "Karaduman",
  "Karaer",
  "Kasapoğlu",
  "Kavaklıoğlu",
  "Kaya ",
  "Keseroğlu",
  "Keçeci",
  "Kılıççı",
  "Kıraç ",
  "Kocabıyık",
  "Korol",
  "Koyuncu",
  "Koç",
  "Koçoğlu",
  "Koçyiğit",
  "Kuday",
  "Kulaksızoğlu",
  "Kumcuoğlu",
  "Kunt",
  "Kunter",
  "Kurutluoğlu",
  "Kutlay",
  "Kuzucu",
  "Körmükçü",
  "Köybaşı",
  "Köylüoğlu",
  "Küçükler",
  "Limoncuoğlu",
  "Mayhoş",
  "Menemencioğlu",
  "Mertoğlu",
  "Nalbantoğlu",
  "Nebioğlu",
  "Numanoğlu",
  "Okumuş",
  "Okur",
  "Oraloğlu",
  "Orbay",
  "Ozansoy",
  "Paksüt",
  "Pekkan",
  "Pektemek",
  "Polat",
  "Poyrazoğlu",
  "Poçan",
  "Sadıklar",
  "Samancı",
  "Sandalcı",
  "Sarıoğlu",
  "Saygıner",
  "Sepetçi",
  "Sezek",
  "Sinanoğlu",
  "Solmaz",
  "Sözeri",
  "Süleymanoğlu",
  "Tahincioğlu",
  "Tanrıkulu",
  "Tazegül",
  "Taşlı",
  "Taşçı",
  "Tekand",
  "Tekelioğlu",
  "Tokatlıoğlu",
  "Tokgöz",
  "Topaloğlu",
  "Topçuoğlu",
  "Toraman",
  "Tunaboylu",
  "Tunçeri",
  "Tuğlu",
  "Tuğluk",
  "Türkdoğan",
  "Türkyılmaz",
  "Tütüncü",
  "Tüzün",
  "Uca",
  "Uluhan",
  "Velioğlu",
  "Yalçın",
  "Yazıcı",
  "Yetkiner",
  "Yeşilkaya",
  "Yıldırım ",
  "Yıldızoğlu",
  "Yılmazer",
  "Yorulmaz",
  "Çamdalı",
  "Çapanoğlu",
  "Çatalbaş",
  "Çağıran",
  "Çetin",
  "Çetiner",
  "Çevik",
  "Çörekçi",
  "Önür",
  "Örge",
  "Öymen",
  "Özberk",
  "Özbey",
  "Özbir",
  "Özdenak",
  "Özdoğan",
  "Özgörkey",
  "Özkara",
  "Özkök ",
  "Öztonga",
  "Öztuna"
];
