module["exports"] = [
  "عارف",
  "عاشوری",
  "عالی",
  "عبادی",
  "عبدالکریمی",
  "عبدالملکی",
  "عراقی",
  "عزیزی",
  "عصار",
  "عقیلی",
  "علم",
  "علم‌الهدی",
  "علی عسگری",
  "علی‌آبادی",
  "علیا",
  "علی‌پور",
  "علی‌زمانی",
  "عنایت",
  "غضنفری",
  "غنی",
  "فارسی",
  "فاطمی",
  "فانی",
  "فتاحی",
  "فرامرزی",
  "فرج",
  "فرشیدورد",
  "فرمانفرمائیان",
  "فروتن",
  "فرهنگ",
  "فریاد",
  "فنایی",
  "فنی‌زاده",
  "فولادوند",
  "فهمیده",
  "قاضی",
  "قانعی",
  "قانونی",
  "قمیشی",
  "قنبری",
  "قهرمان",
  "قهرمانی",
  "قهرمانیان",
  "قهستانی",
  "کاشی",
  "کاکاوند",
  "کامکار",
  "کاملی",
  "کاویانی",
  "کدیور",
  "کردبچه",
  "کرمانی",
  "کریمی",
  "کلباسی",
  "کمالی",
  "کوشکی",
  "کهنمویی",
  "کیان",
  "کیانی (نام خانوادگی)",
  "کیمیایی",
  "گل محمدی",
  "گلپایگانی",
  "گنجی",
  "لاجوردی",
  "لاچینی",
  "لاهوتی",
  "لنکرانی",
  "لوکس",
  "مجاهد",
  "مجتبایی",
  "مجتبوی",
  "مجتهد شبستری",
  "مجتهدی",
  "مجرد",
  "محجوب",
  "محجوبی",
  "محدثی",
  "محمدرضایی",
  "محمدی",
  "مددی",
  "مرادخانی",
  "مرتضوی",
  "مستوفی",
  "مشا",
  "مصاحب",
  "مصباح",
  "مصباح‌زاده",
  "مطهری",
  "مظفر",
  "معارف",
  "معروف",
  "معین",
  "مفتاح",
  "مفتح",
  "مقدم",
  "ملایری",
  "ملک",
  "ملکیان",
  "منوچهری",
  "موحد",
  "موسوی",
  "موسویان",
  "مهاجرانی",
  "مهدی‌پور",
  "میرباقری",
  "میردامادی",
  "میرزاده",
  "میرسپاسی",
  "میزبانی",
  "ناظری",
  "نامور",
  "نجفی",
  "ندوشن",
  "نراقی",
  "نعمت‌زاده",
  "نقدی",
  "نقیب‌زاده",
  "نواب",
  "نوبخت",
  "نوبختی",
  "نهاوندی",
  "نیشابوری",
  "نیلوفری",
  "واثقی",
  "واعظ",
  "واعظ‌زاده",
  "واعظی",
  "وکیلی",
  "هاشمی",
  "هاشمی رفسنجانی",
  "هاشمیان",
  "هامون",
  "هدایت",
  "هراتی",
  "هروی",
  "همایون",
  "همت",
  "همدانی",
  "هوشیار",
  "هومن",
  "یاحقی",
  "یادگار",
  "یثربی",
  "یلدا"
];
