module["exports"] = [
  "Afganistan",
  "Almanya",
  "Amerika Birleşik Devletleri",
  "Amerikan Samoa",
  "Andorra",
  "Angola",
  "Anguilla, İngiltere",
  "Antigua ve Barbuda",
  "Arjantin",
  "Arnavutluk",
  "Aruba, Hollanda",
  "Avustralya",
  "Avusturya",
  "Azerbaycan",
  "Bahama Adaları",
  "Bahreyn",
  "Bangladeş",
  "Barbados",
  "Belçika",
  "Belize",
  "Benin",
  "Bermuda, İngiltere",
  "Beyaz Rusya",
  "Bhutan",
  "Birleşik Arap Emirlikleri",
  "Birmanya (Myanmar)",
  "Bolivya",
  "Bosna Hersek",
  "Botswana",
  "Brezilya",
  "Brunei",
  "Bulgaristan",
  "Burkina Faso",
  "Burundi",
  "Cape Verde",
  "Cayman Adaları, İngiltere",
  "Cebelitarık, İngiltere",
  "Cezayir",
  "Christmas Adası , Avusturalya",
  "Cibuti",
  "Çad",
  "Çek Cumhuriyeti",
  "Çin",
  "Danimarka",
  "Doğu Timor",
  "Dominik Cumhuriyeti",
  "Dominika",
  "Ekvator",
  "Ekvator Ginesi",
  "El Salvador",
  "Endonezya",
  "Eritre",
  "Ermenistan",
  "Estonya",
  "Etiyopya",
  "Fas",
  "Fiji",
  "Fildişi Sahili",
  "Filipinler",
  "Filistin",
  "Finlandiya",
  "Folkland Adaları, İngiltere",
  "Fransa",
  "Fransız Guyanası",
  "Fransız Güney Eyaletleri (Kerguelen Adaları)",
  "Fransız Polinezyası",
  "Gabon",
  "Galler",
  "Gambiya",
  "Gana",
  "Gine",
  "Gine-Bissau",
  "Grenada",
  "Grönland",
  "Guadalup, Fransa",
  "Guam, Amerika",
  "Guatemala",
  "Guyana",
  "Güney Afrika",
  "Güney Georgia ve Güney Sandviç Adaları, İngiltere",
  "Güney Kıbrıs Rum Yönetimi",
  "Güney Kore",
  "Gürcistan H",
  "Haiti",
  "Hırvatistan",
  "Hindistan",
  "Hollanda",
  "Hollanda Antilleri",
  "Honduras",
  "Irak",
  "İngiltere",
  "İran",
  "İrlanda",
  "İspanya",
  "İsrail",
  "İsveç",
  "İsviçre",
  "İtalya",
  "İzlanda",
  "Jamaika",
  "Japonya",
  "Johnston Atoll, Amerika",
  "K.K.T.C.",
  "Kamboçya",
  "Kamerun",
  "Kanada",
  "Kanarya Adaları",
  "Karadağ",
  "Katar",
  "Kazakistan",
  "Kenya",
  "Kırgızistan",
  "Kiribati",
  "Kolombiya",
  "Komorlar",
  "Kongo",
  "Kongo Demokratik Cumhuriyeti",
  "Kosova",
  "Kosta Rika",
  "Kuveyt",
  "Kuzey İrlanda",
  "Kuzey Kore",
  "Kuzey Maryana Adaları",
  "Küba",
  "Laos",
  "Lesotho",
  "Letonya",
  "Liberya",
  "Libya",
  "Liechtenstein",
  "Litvanya",
  "Lübnan",
  "Lüksemburg",
  "Macaristan",
  "Madagaskar",
  "Makau (Makao)",
  "Makedonya",
  "Malavi",
  "Maldiv Adaları",
  "Malezya",
  "Mali",
  "Malta",
  "Marşal Adaları",
  "Martinik, Fransa",
  "Mauritius",
  "Mayotte, Fransa",
  "Meksika",
  "Mısır",
  "Midway Adaları, Amerika",
  "Mikronezya",
  "Moğolistan",
  "Moldavya",
  "Monako",
  "Montserrat",
  "Moritanya",
  "Mozambik",
  "Namibia",
  "Nauru",
  "Nepal",
  "Nijer",
  "Nijerya",
  "Nikaragua",
  "Niue, Yeni Zelanda",
  "Norveç",
  "Orta Afrika Cumhuriyeti",
  "Özbekistan",
  "Pakistan",
  "Palau Adaları",
  "Palmyra Atoll, Amerika",
  "Panama",
  "Papua Yeni Gine",
  "Paraguay",
  "Peru",
  "Polonya",
  "Portekiz",
  "Porto Riko, Amerika",
  "Reunion, Fransa",
  "Romanya",
  "Ruanda",
  "Rusya Federasyonu",
  "Saint Helena, İngiltere",
  "Saint Martin, Fransa",
  "Saint Pierre ve Miquelon, Fransa",
  "Samoa",
  "San Marino",
  "Santa Kitts ve Nevis",
  "Santa Lucia",
  "Santa Vincent ve Grenadinler",
  "Sao Tome ve Principe",
  "Senegal",
  "Seyşeller",
  "Sırbistan",
  "Sierra Leone",
  "Singapur",
  "Slovakya",
  "Slovenya",
  "Solomon Adaları",
  "Somali",
  "Sri Lanka",
  "Sudan",
  "Surinam",
  "Suriye",
  "Suudi Arabistan",
  "Svalbard, Norveç",
  "Svaziland",
  "Şili",
  "Tacikistan",
  "Tanzanya",
  "Tayland",
  "Tayvan",
  "Togo",
  "Tonga",
  "Trinidad ve Tobago",
  "Tunus",
  "Turks ve Caicos Adaları, İngiltere",
  "Tuvalu",
  "Türkiye",
  "Türkmenistan",
  "Uganda",
  "Ukrayna",
  "Umman",
  "Uruguay",
  "Ürdün",
  "Vallis ve Futuna, Fransa",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Adaları, Amerika",
  "Virgin Adaları, İngiltere",
  "Wake Adaları, Amerika",
  "Yemen",
  "Yeni Kaledonya, Fransa",
  "Yeni Zelanda",
  "Yunanistan",
  "Zambiya",
  "Zimbabve"
];
