module["exports"] = [
  "Böcker",
  "Filmer",
  "Musik",
  "Spel",
  "Elektronik",
  "Datorer",
  "Hem",
  "Trädgård",
  "Verktyg",
  "Livsmedel",
  "Hälsa",
  "Skönhet",
  "Leksaker",
  "Klädsel",
  "Skor",
  "Smycken",
  "Sport"
];
