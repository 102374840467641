module["exports"] = [
  "აბაშიძის",
  "აბესაძის",
  "აბულაძის",
  "აგლაძის",
  "ადლერის",
  "ავიაქიმიის",
  "ავლაბრის",
  "ათარბეგოვის",
  "ათონელის",
  "ალავერდოვის",
  "ალექსიძის",
  "ალილუევის",
  "ალმასიანის",
  "ამაღლების",
  "ამირეჯიბის",
  "ანაგის",
  "ანდრონიკაშვილის",
  "ანთელავას",
  "ანჯაფარიძის",
  "არაგვის",
  "არდონის",
  "არეშიძის",
  "ასათიანის",
  "ასკურავას",
  "ასლანიდის",
  "ატენის",
  "აფხაზი",
  "აღმაშენებლის",
  "ახალშენის",
  "ახვლედიანის",
  "ბააზოვის",
  "ბაბისხევის",
  "ბაბუშკინის",
  "ბაგრატიონის",
  "ბალანჩივაძეების",
  "ბალანჩივაძის",
  "ბალანჩინის",
  "ბალმაშევის",
  "ბარამიძის",
  "ბარნოვის",
  "ბაშალეიშვილის",
  "ბევრეთის",
  "ბელინსკის",
  "ბელოსტოკის",
  "ბენაშვილის",
  "ბეჟანიშვილის",
  "ბერიძის",
  "ბოლქვაძის",
  "ბოცვაძის",
  "ბოჭორიშვილის",
  "ბოჭორიძის",
  "ბუაჩიძის",
  "ბუდაპეშტის",
  "ბურკიაშვილის",
  "ბურძგლას",
  "გაბესკირიას",
  "გაგარინის",
  "გაზაფხულის",
  "გამრეკელის",
  "გამსახურდიას",
  "გარეჯელის",
  "გეგეჭკორის",
  "გედაურის",
  "გელოვანი",
  "გელოვანის",
  "გერცენის",
  "გლდანის",
  "გოგებაშვილის",
  "გოგიბერიძის",
  "გოგოლის",
  "გონაშვილის",
  "გორგასლის",
  "გრანელის",
  "გრიზოდუბოვას",
  "გრინევიცკის",
  "გრომოვას",
  "გრუზინსკის",
  "გუდიაშვილის",
  "გულრიფშის",
  "გულუას",
  "გურამიშვილის",
  "გურგენიძის",
  "დადიანის",
  "დავითაშვილის",
  "დამაკავშირებელი",
  "დარიალის",
  "დედოფლისწყაროს",
  "დეპუტატის",
  "დიდგორის",
  "დიდი",
  "დიდუბის",
  "დიუმას",
  "დიღმის",
  "დიღომში",
  "დოლიძის",
  "დუნდუას",
  "დურმიშიძის",
  "ელიავას",
  "ენგელსის",
  "ენგურის",
  "ეპისკოპოსის",
  "ერისთავი",
  "ერისთავის",
  "ვაზისუბნის",
  "ვაკელის",
  "ვართაგავას",
  "ვატუტინის",
  "ვაჩნაძის",
  "ვაცეკის",
  "ვეკუას",
  "ვეშაპურის",
  "ვირსალაძის",
  "ვოლოდარსკის",
  "ვორონინის",
  "ზაარბრიუკენის",
  "ზაზიაშვილის",
  "ზაზიშვილის",
  "ზაკომოლდინის",
  "ზანდუკელის",
  "ზაქარაიას",
  "ზაქარიაძის",
  "ზახაროვის",
  "ზაჰესის",
  "ზნაურის",
  "ზურაბაშვილის",
  "ზღვის",
  "თაბუკაშვილის",
  "თავაძის",
  "თავისუფლების",
  "თამარაშვილის",
  "თაქთაქიშვილის",
  "თბილელის",
  "თელიას",
  "თორაძის",
  "თოფურიძის",
  "იალბუზის",
  "იამანიძის",
  "იაშვილის",
  "იბერიის",
  "იერუსალიმის",
  "ივანიძის",
  "ივერიელის",
  "იზაშვილის",
  "ილურიძის",
  "იმედაშვილის",
  "იმედაძის",
  "იმედის",
  "ინანიშვილის",
  "ინგოროყვას",
  "ინდუსტრიალიზაციის",
  "ინჟინრის",
  "ინწკირველის",
  "ირბახის",
  "ირემაშვილის",
  "ისაკაძის",
  "ისპასჰანლის",
  "იტალიის",
  "იუნკერთა",
  "კათალიკოსის",
  "კაიროს",
  "კაკაბაძის",
  "კაკაბეთის",
  "კაკლიანის",
  "კალანდაძის",
  "კალიაევის",
  "კალინინის",
  "კამალოვის",
  "კამოს",
  "კაშენის",
  "კახოვკის",
  "კედიას",
  "კელაპტრიშვილის",
  "კერესელიძის",
  "კეცხოველის",
  "კიბალჩიჩის",
  "კიკნაძის",
  "კიროვის",
  "კობარეთის",
  "კოლექტივიზაციის",
  "კოლმეურნეობის",
  "კოლხეთის",
  "კომკავშირის",
  "კომუნისტური",
  "კონსტიტუციის",
  "კოოპერაციის",
  "კოსტავას",
  "კოტეტიშვილის",
  "კოჩეტკოვის",
  "კოჯრის",
  "კრონშტადტის",
  "კროპოტკინის",
  "კრუპსკაიას",
  "კუიბიშევის",
  "კურნატოვსკის",
  "კურტანოვსკის",
  "კუტუზოვის",
  "ლაღიძის",
  "ლელაშვილის",
  "ლენინაშენის",
  "ლენინგრადის",
  "ლენინის",
  "ლენის",
  "ლეონიძის",
  "ლვოვის",
  "ლორთქიფანიძის",
  "ლოტკინის",
  "ლუბლიანის",
  "ლუბოვსკის",
  "ლუნაჩარსკის",
  "ლუქსემბურგის",
  "მაგნიტოგორსკის",
  "მაზნიაშვილის",
  "მაისურაძის",
  "მამარდაშვილის",
  "მამაცაშვილის",
  "მანაგაძის",
  "მანჯგალაძის",
  "მარის",
  "მარუაშვილის",
  "მარქსის",
  "მარჯანის",
  "მატროსოვის",
  "მაჭავარიანი",
  "მახალდიანის",
  "მახარაძის",
  "მებაღიშვილის",
  "მეგობრობის",
  "მელაანის",
  "მერკვილაძის",
  "მესხიას",
  "მესხის",
  "მეტეხის",
  "მეტრეველი",
  "მეჩნიკოვის",
  "მთავარანგელოზის",
  "მიასნიკოვის",
  "მილორავას",
  "მიმინოშვილის",
  "მიროტაძის",
  "მიქატაძის",
  "მიქელაძის",
  "მონტინის",
  "მორეტის",
  "მოსკოვის",
  "მრევლიშვილის",
  "მუშკორის",
  "მუჯირიშვილის",
  "მშვიდობის",
  "მცხეთის",
  "ნადირაძის",
  "ნაკაშიძის",
  "ნარიმანოვის",
  "ნასიძის",
  "ნაფარეულის",
  "ნეკრასოვის",
  "ნიაღვრის",
  "ნინიძის",
  "ნიშნიანიძის",
  "ობოლაძის",
  "ონიანის",
  "ოჟიოს",
  "ორახელაშვილის",
  "ორბელიანის",
  "ორჯონიკიძის",
  "ოქტომბრის",
  "ოცდაექვსი",
  "პავლოვის",
  "პარალელურის",
  "პარიზის",
  "პეკინის",
  "პეროვსკაიას",
  "პეტეფის",
  "პიონერის",
  "პირველი",
  "პისარევის",
  "პლეხანოვის",
  "პრავდის",
  "პროლეტარიატის",
  "ჟელიაბოვის",
  "ჟვანიას",
  "ჟორდანიას",
  "ჟღენტი",
  "ჟღენტის",
  "რადიანის",
  "რამიშვილი",
  "რასკოვას",
  "რენინგერის",
  "რინგის",
  "რიჟინაშვილის",
  "რობაქიძის",
  "რობესპიერის",
  "რუსის",
  "რუხაძის",
  "რჩეულიშვილის",
  "სააკაძის",
  "საბადურის",
  "საბაშვილის",
  "საბურთალოს",
  "საბჭოს",
  "საგურამოს",
  "სამრეკლოს",
  "სამღერეთის",
  "სანაკოევის",
  "სარაჯიშვილის",
  "საჯაიას",
  "სევასტოპოლის",
  "სერგი",
  "სვანიძის",
  "სვერდლოვის",
  "სტახანოვის",
  "სულთნიშნის",
  "სურგულაძის",
  "სხირტლაძის",
  "ტაბიძის",
  "ტატიშვილის",
  "ტელმანის",
  "ტერევერკოს",
  "ტეტელაშვილის",
  "ტოვსტონოგოვის",
  "ტოროშელიძის",
  "ტრაქტორის",
  "ტრიკოტაჟის",
  "ტურბინის",
  "უბილავას",
  "უბინაშვილის",
  "უზნაძის",
  "უკლებას",
  "ულიანოვის",
  "ურიდიას",
  "ფაბრიციუსის",
  "ფაღავას",
  "ფერისცვალების",
  "ფიგნერის",
  "ფიზკულტურის",
  "ფიოლეტოვის",
  "ფიფიების",
  "ფოცხიშვილის",
  "ქართველიშვილის",
  "ქართლელიშვილის",
  "ქინქლაძის",
  "ქიქოძის",
  "ქსოვრელის",
  "ქუთათელაძის",
  "ქუთათელის",
  "ქურდიანის",
  "ღოღობერიძის",
  "ღუდუშაურის",
  "ყავლაშვილის",
  "ყაზბეგის",
  "ყარყარაშვილის",
  "ყიფიანის",
  "ყუშიტაშვილის",
  "შანიძის",
  "შარტავას",
  "შატილოვის",
  "შაუმიანის",
  "შენგელაიას",
  "შერვაშიძის",
  "შეროზიას",
  "შირშოვის",
  "შმიდტის",
  "შრომის",
  "შუშინის",
  "შჩორსის",
  "ჩალაუბნის",
  "ჩანტლაძის",
  "ჩაპაევის",
  "ჩაჩავას",
  "ჩელუსკინელების",
  "ჩერნიახოვსკის",
  "ჩერქეზიშვილი",
  "ჩერქეზიშვილის",
  "ჩვიდმეტი",
  "ჩიტაიას",
  "ჩიტაძის",
  "ჩიქვანაიას",
  "ჩიქობავას",
  "ჩიხლაძის",
  "ჩოდრიშვილის",
  "ჩოლოყაშვილის",
  "ჩუღურეთის",
  "ცაბაძის",
  "ცაგარელის",
  "ცეტკინის",
  "ცინცაძის",
  "ცისკარიშვილის",
  "ცურტაველის",
  "ცქიტიშვილის",
  "ცხაკაიას",
  "ძმობის",
  "ძნელაძის",
  "წერეთლის",
  "წითელი",
  "წითელწყაროს",
  "წინამძღვრიშვილის",
  "წულაძის",
  "წულუკიძის",
  "ჭაბუკიანის",
  "ჭავჭავაძის",
  "ჭანტურიას",
  "ჭოველიძის",
  "ჭონქაძის",
  "ჭყონდიდელის",
  "ხანძთელის",
  "ხვამლის",
  "ხვინგიას",
  "ხვიჩიას",
  "ხიმშიაშვილის",
  "ხმელნიცკის",
  "ხორნაბუჯის",
  "ხრამჰესის",
  "ხუციშვილის",
  "ჯავახიშვილის",
  "ჯაფარიძის",
  "ჯიბლაძის",
  "ჯორჯიაშვილის"
];
