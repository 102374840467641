module["exports"] = [
  "Eike",
  "Bjørke",
  "Gran",
  "Vass",
  "Furu",
  "Litj",
  "Lille",
  "Høy",
  "Fosse",
  "Elve",
  "Ku",
  "Konvall",
  "Soldugg",
  "Hestemyr",
  "Granitt",
  "Hegge",
  "Rogne",
  "Fiol",
  "Sol",
  "Ting",
  "Malm",
  "Klokker",
  "Preste",
  "Dam",
  "Geiterygg",
  "Bekke",
  "Berg",
  "Kirke",
  "Kors",
  "Bru",
  "Blåveis",
  "Torg",
  "Sjø"
];
