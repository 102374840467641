module["exports"] = [
   "Libros",
   "Películas",
   "Música",
   "Juegos",
   "Electrónica",
   "Ordenadores",
   "Hogar",
   "Jardín",
   "Herramientas",
   "Ultramarinos",
   "Salud",
   "Belleza",
   "Juguetes",
   "Kids",
   "Baby",
   "Ropa",
   "Zapatos",
   "Joyería",
   "Deportes",
   "Aire libre",
   "Automoción",
   "Industrial"
];
