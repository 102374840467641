module["exports"] = [
  "Kitablar",
  "Filmlər",
  "musiqi",
  "oyunlar",
  "Elektronika",
  "Kompyuterlər",
  "Ev",
  "садинструмент",
  "Səhiyyə",
  "gözəllik",
  "Oyuncaqlar",
  "uşaq üçün",
  "Geyim",
  "Ayyaqqabı",
  "bəzək",
  "İdman",
  "turizm",
  "Avtomobil",
];
