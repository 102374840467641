module["exports"] = [
  "Abbas Fətullayev",
  "Abbas Mirzə Şərifzadə",
  "Abbas Səhhət",
  "Abdulla Şaiq",
  "Afiyəddin Cəlilov",
  "Axundov",
  "Ağa Nemətulla",
  "Ağadadaş Qurbanov",
  "Akademik Həsən Əliyev",
  "Akademik Lətif İmanov",
  "Alı Mustafayev",
  "Almas İldırım",
  "Asəf Zeynallı",
  "Asif Əsədullayev",
  "Aşıq Alı",
  "Aşıq Ələsgər",
  "Azadlıq prospekti",
  "Bakıxanov",
  "Balababa Məcidov",
  "Balaəmi Dadaşov",
  "Behbud Şaxtantinski",
  "Bəkir Çobanzadə",
  "Bəsti Bağırova",
  "Bəşir Səfəroğlu",
  "Böyük Qala",
  "Cabir Əliyev",
  "Camal Hacıəliyev",
  "Cavadxan",
  "Cavanşir",
  "Ceyhun Səlimov",
  "Ceyhunbəy Hacıbəyli",
  "Cəbiyev",
  "Cəfər Xəndan",
  "Cəfər Cabbarlı",
  "Cəlal Qurbanov",
  "Cəlil Məmmədquluzadə",
  "Çingiz Mustafayev",
  "Çobanzadə",
  "Dadaş Bünyadzadə",
  "Dağlı Yunus",
  "Dilarə Əliyeva",
  "Elçin Əzimov",
  "Eldar və Abdulla Əlibəyovlar",
  "Elxan Həsənov",
  "Elşən Mehdiyev",
  "Elşən Süleymanov",
  "Etibar Bəkirov",
  "Əbdüləzəl Dəmirçizadə",
  "Əbdülhəsən Anaplı",
  "Əbdülkərim Əlizadə",
  "Əhməd bəy Ağaoğlu",
  "Əhməd Cavad",
  "Əhməd Cəmil",
  "Əhməd Mehbalıyev",
  "Əhməd Rəcəbli",
  "Əjdər Xanbabayev",
  "Əkrəm Cəfərov",
  "Ələsgər Qayıbov",
  "Əliağa Vahid",
  "Əli Bəy Hüseynzadə",
  "Əlimərdan bəy Topçubaşov",
  "Əliyar Əliyev",
  "Əlövsət Abdulrəhimov",
  "Əlövsət Quliyev",
  "Əmir Bağırov",
  "Əsəd Əhmədov",
  "Əşrəf Yunusov",
  "Əzim Əzimzadə",
  "Əziz Əliyev",
  "Heybət Heybətov",
  "Həqiqət Rzayeva",
  "Həmid Araslı",
  "Hənifə Ələsgərova",
  "Hərbçilər",
  "Həsənoğu",
  "Həsən Seyidbəyli",
  "Hətəm Allahverdiyev",
  "Həzi Aslanov",
  "Hüsü Hacıyev",
  "Hüseynqulu Sarabski",
  "Fətəli xan Xoyski",
  "Fəzail Bayramov",
  "Fikrət Əmirov",
  "Fuad İbrahimbəyov",
  "Fuad Yusifov",
  "General Əliağa Şıxlinski",
  "Gülayə Qədirbəyova",
  "Gənclik",
  "Xaqani",
  "Xan Şuşinski",
  "Xanlar",
  "Xudu Məmmədov",
  "İbrahimpaşa Dadaşov",
  "İdris Süleymanov",
  "İlqar Abbasov",
  "İlqar İsmayılov",
  "İmran Qasımov",
  "İnqilab İsmayılov",
  "İsfəndiyar Zülalov",
  "İslam Abışov",
  "İslam Səfərli",
  "İsmayıl bəy Qutqaşınlı",
  "İsmayıl Mirzəgülov",
  "İstiqlaliyyət",
  "28 May",
  "İsgəndərov",
  "İvan Turgenev",
  "İzmir",
  "İzzət Həmidov",
  "İzzət Orucova",
  "Kamal Rəhimov",
  "Kazım Kazımzadə",
  "Kazımağa Kərimov",
  "Kərəm İsmayılov",
  "Kiçik Qala",
  "Koroğlu Rəhimov",
  "Qaçaq Nəbi",
  "Qarabağ",
  "Qədirbəyov",
  "Qəzənfər Musabəyov",
  "Qəzənfər Vəliyev",
  "Leyla Məmmədbəyova",
  "Mahmud İbrahimov",
  "Malik Məmmədov",
  "Mehdi Abbasov",
  "Mehdi Mehdizadə",
  "Məhəmməd Əmin Rəsulzadə",
  "Məhəmməd Hadi",
  "Məhəmməd Xiyabani",
  "Məhəmməd ibn Hinduşah Naxçıvani",
  "Məhsəti Gəncəvi",
  "Məmmədyarov",
  "Mərdanov qardaşları",
  "Mətləb Ağayev",
  "Məşədi Hilal",
  "Məzahir Rüstəmov",
  "Mikayıl Müşviq",
  "Mingəçevir",
  "Mirəli Qaşqay",
  "Mirəli Seyidov",
  "Mirzağa Əliyev",
  "Mirzə İbrahimov",
  "Mirzə Mənsur",
  "Mirzə Mustafayev",
  "Murtuza Muxtarov",
  "Mustafa Topçubaşov",
  "Müqtədir Aydınbəyov",
  "Müslüm Maqomayev",
  "Müzəffər Həsənov",
  "Nabat Aşurbəyova",
  "Naxçıvani",
  "Naximov",
  "Nazim İsmaylov",
  "Neapol",
  "Neftçi Qurban Abbasov",
  "Neftçilər prospekti",
  "Nəcəfbəy Vəzirov",
  "Nəcəfqulu Rəfiyev",
  "Nəriman Nərimanov",
  "Nəsirəddin Tusi",
  "Nigar Rəfibəyli",
  "Niyazi",
  "Nizami",
  "Nizami Abdullayev",
  "Nobel prospekti",
  "Novruz",
  "Novruzov qardaşları",
  "Oqtay Vəliyev",
  "Parlament",
  "Puşkin",
  "Rafiq Ağayev",
  "Ramiz Qəmbərov",
  "Rəşid Behbudov",
  "Rəşid Məcidov",
  "Ruhulla Axundov",
  "Ruslan Allahverdiyev",
  "Rüstəm Rüstəmov",
  "Tahir Bağırov",
  "Tarzan Hacı Məmmədov",
  "Tbilisi prospekti",
  "Təbriz (Bakı)",
  "Təbriz Xəlilbəyli",
  "Tofiq Məmmədov",
  "Tolstoy",
  "Sabit Orucov",
  "Sabit Rəhman",
  "Sahib Hümmətov",
  "Salatın Əsgərova",
  "Sarayevo",
  "Seyid Əzim Şirvani",
  "Seyid Şuşinski",
  "Seyidov",
  "Səməd bəy Mehmandarov",
  "Səməd Vurğun",
  "Səttar Bəhlulzadə",
  "Sona xanım Vəlixanlı",
  "Sübhi Salayev",
  "Süleyman Əhmədov",
  "Süleyman Rəhimov",
  "Süleyman Rüstəm",
  "Süleyman Sani Axundov",
  "Süleyman Vəzirov",
  "Şahin Səmədov",
  "Şamil Əzizbəyov",
  "Şamil Kamilov",
  "Şeyx Şamil",
  "Şəfayət Mehdiyev",
  "Şəmsi Bədəlbəyli",
  "Şirin Mirzəyev",
  "Şıxəli Qurbanov",
  "Şövkət Ələkbərova",
  "Ülvi Bünyadzadə",
  "Üzeyir Hacıbəyov",
  "Vasif Əliyev",
  "Vəli Məmmədov",
  "Vladislav Plotnikov",
  "Vüqar Quliyev",
  "Vunq Tau",
  "Yaqub Əliyev",
  "Yaşar Abdullayev",
  "Yaşar Əliyev",
  "Yavər Əliyev",
  "Yesenin",
  "Yəhya Hüseynov",
  "Yılmaz Axundzadə",
  "Yüsif Eyvazov",
  "Yusif Qasımov",
  "Yusif Məmmədəliyev",
  "Yusif Səfərov",
  "Yusif Vəzir Çəmənzəminli",
  "Zahid Əliyev",
  "Zahid Xəlilov",
  "Zaur Kərimov",
  "Zavod",
  "Zərgərpalan"
];
