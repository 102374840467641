module["exports"] = [
  "01 #######",
  "01#######",
  "+43-1-#######",
  "+431#######",
  "0#### ####",
  "0#########",
  "+43-####-####",
  "+43 ########"
];
