module["exports"] = [
  "Москва",
  "Владимир",
  "Санкт-Петербург",
  "Новосибирск",
  "Екатеринбург",
  "Нижний Новгород",
  "Самара",
  "Казань",
  "Омск",
  "Челябинск",
  "Ростов-на-Дону",
  "Уфа",
  "Волгоград",
  "Пермь",
  "Красноярск",
  "Воронеж",
  "Саратов",
  "Краснодар",
  "Тольятти",
  "Ижевск",
  "Барнаул",
  "Ульяновск",
  "Тюмень",
  "Иркутск",
  "Владивосток",
  "Ярославль",
  "Хабаровск",
  "Махачкала",
  "Оренбург",
  "Новокузнецк",
  "Томск",
  "Кемерово",
  "Рязань",
  "Астрахань",
  "Пенза",
  "Липецк",
  "Тула",
  "Киров",
  "Чебоксары",
  "Курск",
  "Брянскm Магнитогорск",
  "Иваново",
  "Тверь",
  "Ставрополь",
  "Белгород",
  "Сочи"
];
