module["exports"] = [
  "ants",
  "bats",
  "bears",
  "bees",
  "birds",
  "buffalo",
  "cats",
  "chickens",
  "cattle",
  "dogs",
  "dolphins",
  "ducks",
  "elephants",
  "fishes",
  "foxes",
  "frogs",
  "geese",
  "goats",
  "horses",
  "kangaroos",
  "lions",
  "monkeys",
  "owls",
  "oxen",
  "penguins",
  "people",
  "pigs",
  "rabbits",
  "sheep",
  "tigers",
  "whales",
  "wolves",
  "zebras",
  "banshees",
  "crows",
  "black cats",
  "chimeras",
  "ghosts",
  "conspirators",
  "dragons",
  "dwarves",
  "elves",
  "enchanters",
  "exorcists",
  "sons",
  "foes",
  "giants",
  "gnomes",
  "goblins",
  "gooses",
  "griffins",
  "lycanthropes",
  "nemesis",
  "ogres",
  "oracles",
  "prophets",
  "sorcerors",
  "spiders",
  "spirits",
  "vampires",
  "warlocks",
  "vixens",
  "werewolves",
  "witches",
  "worshipers",
  "zombies",
  "druids"
];
