module["exports"] = [
  "partnerships",
  "comunità",
  "ROI",
  "soluzioni",
  "e-services",
  "nicchie",
  "tecnologie",
  "contenuti",
  "supply-chains",
  "convergenze",
  "relazioni",
  "architetture",
  "interfacce",
  "mercati",
  "e-commerce",
  "sistemi",
  "modelli",
  "schemi",
  "reti",
  "applicazioni",
  "metriche",
  "e-business",
  "funzionalità",
  "esperienze",
  "webservices",
  "metodologie"
];
