module["exports"] = [
  "Avenue",
  "Boulevard",
  "Circle",
  "Circuit",
  "Court",
  "Crescent",
  "Crest",
  "Drive",
  "Estate Dr",
  "Grove",
  "Hill",
  "Island",
  "Junction",
  "Knoll",
  "Lane",
  "Loop",
  "Mall",
  "Manor",
  "Meadow",
  "Mews",
  "Parade",
  "Parkway",
  "Pass",
  "Place",
  "Plaza",
  "Ridge",
  "Road",
  "Run",
  "Square",
  "Station St",
  "Street",
  "Summit",
  "Terrace",
  "Track",
  "Trail",
  "View Rd",
  "Way"
];
