module["exports"] = [
  "京",
  "沪",
  "津",
  "渝",
  "黑",
  "吉",
  "辽",
  "蒙",
  "冀",
  "新",
  "甘",
  "青",
  "陕",
  "宁",
  "豫",
  "鲁",
  "晋",
  "皖",
  "鄂",
  "湘",
  "苏",
  "川",
  "黔",
  "滇",
  "桂",
  "藏",
  "浙",
  "赣",
  "粤",
  "闽",
  "台",
  "琼",
  "港",
  "澳"
];
