module["exports"] = [
  "01########",
  "02########",
  "03########",
  "04########",
  "05########",
  "06########",
  "07########",
  "+33 1########",
  "+33 2########",
  "+33 3########",
  "+33 4########",
  "+33 5########",
  "+33 6########",
  "+33 7########"
];
