module["exports"] = [
  "Книги",
  "Фильмы",
  "музыка",
  "игры",
  "Электроника",
  "компьютеры",
  "Дом",
  "садинструмент",
  "Бакалея",
  "здоровье",
  "красота",
  "Игрушки",
  "детское",
  "для малышей",
  "Одежда",
  "обувь",
  "украшения",
  "Спорт",
  "туризм",
  "Автомобильное",
  "промышленное"
];
