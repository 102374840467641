module["exports"] = [
  "Bgld.",
  "Ktn.",
  "NÖ",
  "OÖ",
  "Sbg.",
  "Stmk.",
  "T",
  "Vbg.",
  "W"
];
