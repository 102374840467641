module["exports"] = [
  "Markus",
  "Mathias",
  "Kristian",
  "Jonas",
  "Andreas",
  "Alexander",
  "Martin",
  "Sander",
  "Daniel",
  "Magnus",
  "Henrik",
  "Tobias",
  "Kristoffer",
  "Emil",
  "Adrian",
  "Sebastian",
  "Marius",
  "Elias",
  "Fredrik",
  "Thomas",
  "Sondre",
  "Benjamin",
  "Jakob",
  "Oliver",
  "Lucas",
  "Oskar",
  "Nikolai",
  "Filip",
  "Mats",
  "William",
  "Erik",
  "Simen",
  "Ole",
  "Eirik",
  "Isak",
  "Kasper",
  "Noah",
  "Lars",
  "Joakim",
  "Johannes",
  "Håkon",
  "Sindre",
  "Jørgen",
  "Herman",
  "Anders",
  "Jonathan",
  "Even",
  "Theodor",
  "Mikkel",
  "Aksel"
];
