module["exports"] = [
  "Alexandra",
  "Karina",
  "Daniela",
  "Andrea",
  "Antónia",
  "Bohuslava",
  "Dáša",
  "Malvína",
  "Kristína",
  "Nataša",
  "Bohdana",
  "Drahomíra",
  "Sára",
  "Zora",
  "Tamara",
  "Ema",
  "Tatiana",
  "Erika",
  "Veronika",
  "Agáta",
  "Dorota",
  "Vanda",
  "Zoja",
  "Gabriela",
  "Perla",
  "Ida",
  "Liana",
  "Miloslava",
  "Vlasta",
  "Lívia",
  "Eleonóra",
  "Etela",
  "Romana",
  "Zlatica",
  "Anežka",
  "Bohumila",
  "Františka",
  "Angela",
  "Matilda",
  "Svetlana",
  "Ľubica",
  "Alena",
  "Soňa",
  "Vieroslava",
  "Zita",
  "Miroslava",
  "Irena",
  "Milena",
  "Estera",
  "Justína",
  "Dana",
  "Danica",
  "Jela",
  "Jaroslava",
  "Jarmila",
  "Lea",
  "Anastázia",
  "Galina",
  "Lesana",
  "Hermína",
  "Monika",
  "Ingrida",
  "Viktória",
  "Blažena",
  "Žofia",
  "Sofia",
  "Gizela",
  "Viola",
  "Gertrúda",
  "Zina",
  "Júlia",
  "Juliana",
  "Želmíra",
  "Ela",
  "Vanesa",
  "Iveta",
  "Vilma",
  "Petronela",
  "Žaneta",
  "Xénia",
  "Karolína",
  "Lenka",
  "Laura",
  "Stanislava",
  "Margaréta",
  "Dobroslava",
  "Blanka",
  "Valéria",
  "Paulína",
  "Sidónia",
  "Adriána",
  "Beáta",
  "Petra",
  "Melánia",
  "Diana",
  "Berta",
  "Patrícia",
  "Lujza",
  "Amália",
  "Milota",
  "Nina",
  "Margita",
  "Kamila",
  "Dušana",
  "Magdaléna",
  "Oľga",
  "Anna",
  "Hana",
  "Božena",
  "Marta",
  "Libuša",
  "Božidara",
  "Dominika",
  "Hortenzia",
  "Jozefína",
  "Štefánia",
  "Ľubomíra",
  "Zuzana",
  "Darina",
  "Marcela",
  "Milica",
  "Elena",
  "Helena",
  "Lýdia",
  "Anabela",
  "Jana",
  "Silvia",
  "Nikola",
  "Ružena",
  "Nora",
  "Drahoslava",
  "Linda",
  "Melinda",
  "Rebeka",
  "Rozália",
  "Regína",
  "Alica",
  "Marianna",
  "Miriama",
  "Martina",
  "Mária",
  "Jolana",
  "Ľudomila",
  "Ľudmila",
  "Olympia",
  "Eugénia",
  "Ľuboslava",
  "Zdenka",
  "Edita",
  "Michaela",
  "Stela",
  "Viera",
  "Natália",
  "Eliška",
  "Brigita",
  "Valentína",
  "Terézia",
  "Vladimíra",
  "Hedviga",
  "Uršuľa",
  "Alojza",
  "Kvetoslava",
  "Sabína",
  "Dobromila",
  "Klára",
  "Simona",
  "Aurélia",
  "Denisa",
  "Renáta",
  "Irma",
  "Agnesa",
  "Klaudia",
  "Alžbeta",
  "Elvíra",
  "Cecília",
  "Emília",
  "Katarína",
  "Henrieta",
  "Bibiána",
  "Barbora",
  "Marína",
  "Izabela",
  "Hilda",
  "Otília",
  "Lucia",
  "Branislava",
  "Bronislava",
  "Ivica",
  "Albína",
  "Kornélia",
  "Sláva",
  "Slávka",
  "Judita",
  "Dagmara",
  "Adela",
  "Nadežda",
  "Eva",
  "Filoména",
  "Ivana",
  "Milada"
];
