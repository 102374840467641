module["exports"] = [
  "adattiva",
  "avanzata",
  "migliorata",
  "assimilata",
  "automatizzata",
  "bilanciata",
  "centralizzata",
  "compatibile",
  "configurabile",
  "cross-platform",
  "decentralizzata",
  "digitalizzata",
  "distribuita",
  "piccola",
  "ergonomica",
  "esclusiva",
  "espansa",
  "estesa",
  "configurabile",
  "fondamentale",
  "orizzontale",
  "implementata",
  "innovativa",
  "integrata",
  "intuitiva",
  "inversa",
  "gestita",
  "obbligatoria",
  "monitorata",
  "multi-canale",
  "multi-laterale",
  "open-source",
  "operativa",
  "ottimizzata",
  "organica",
  "persistente",
  "polarizzata",
  "proattiva",
  "programmabile",
  "progressiva",
  "reattiva",
  "riallineata",
  "ricontestualizzata",
  "ridotta",
  "robusta",
  "sicura",
  "condivisibile",
  "stand-alone",
  "switchabile",
  "sincronizzata",
  "sinergica",
  "totale",
  "universale",
  "user-friendly",
  "versatile",
  "virtuale",
  "visionaria"
];
