module["exports"] = [
  "Александр",
  "Алексей",
  "Альберт",
  "Анатолий",
  "Андрей",
  "Антон",
  "Аркадий",
  "Арсений",
  "Артём",
  "Борис",
  "Вадим",
  "Валентин",
  "Валерий",
  "Василий",
  "Виктор",
  "Виталий",
  "Владимир",
  "Владислав",
  "Вячеслав",
  "Геннадий",
  "Георгий",
  "Герман",
  "Григорий",
  "Даниил",
  "Денис",
  "Дмитрий",
  "Евгений",
  "Егор",
  "Иван",
  "Игнатий",
  "Игорь",
  "Илья",
  "Константин",
  "Лаврентий",
  "Леонид",
  "Лука",
  "Макар",
  "Максим",
  "Матвей",
  "Михаил",
  "Никита",
  "Николай",
  "Олег",
  "Роман",
  "Семён",
  "Сергей",
  "Станислав",
  "Степан",
  "Фёдор",
  "Эдуард",
  "Юрий",
  "Ярослав"
];
