module["exports"] = [
  "in",
  "com",
  "biz",
  "info",
  "name",
  "net",
  "org",
  "co.in"
];
