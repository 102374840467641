module["exports"] = [
  "Əhmədov",
  "Ələkbərov",
  "Əliyev",
  "Vəliyev",
  "Soltanov",
  "Quliyev",
  "Məmmədov",
  "Xəlilov",
  "Nəzərov",
  "Rəhimov"
];
