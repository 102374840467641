module["exports"] = {
"adjective": [
     "Pequeño",
     "Ergonómico",
     "Rústico",
     "Inteligente",
     "Gorgeous",
     "Increíble",
     "Fantástico",
     "Práctica",
     "Elegante",
     "Increíble",
     "Genérica",
     "Artesanal",
     "Hecho a mano",
     "Licencia",
     "Refinado",
     "Sin marca",
     "Sabrosa"
   ],
"material": [
     "Acero",
     "Madera",
     "Hormigón",
     "Plástico",
     "Cotton",
     "Granito",
     "Caucho",
     "Metal",
     "Soft",
     "Fresco",
     "Frozen"
   ],
"product": [
     "Presidente",
     "Auto",
     "Computadora",
     "Teclado",
     "Ratón",
     "Bike",
     "Pelota",
     "Guantes",
     "Pantalones",
     "Camisa",
     "Mesa",
     "Zapatos",
     "Sombrero",
     "Toallas",
     "Jabón",
     "Tuna",
     "Pollo",
     "Pescado",
     "Queso",
     "Tocino",
     "Pizza",
     "Ensalada",
     "Embutidos"
  ]
};
