module["exports"] = [
  "Antal",
  "Babka",
  "Bahna",
  "Bahno",
  "Baláž",
  "Baran",
  "Baranka",
  "Bartovič",
  "Bartoš",
  "Bača",
  "Bernolák",
  "Beňo",
  "Bicek",
  "Bielik",
  "Blaho",
  "Bondra",
  "Bosák",
  "Boška",
  "Brezina",
  "Bukovský",
  "Chalupka",
  "Chudík",
  "Cibula",
  "Cibulka",
  "Cibuľa",
  "Cyprich",
  "Cíger",
  "Danko",
  "Daňko",
  "Daňo",
  "Debnár",
  "Dej",
  "Dekýš",
  "Doležal",
  "Dočolomanský",
  "Droppa",
  "Dubovský",
  "Dudek",
  "Dula",
  "Dulla",
  "Dusík",
  "Dvonč",
  "Dzurjanin",
  "Dávid",
  "Fabian",
  "Fabián",
  "Fajnor",
  "Farkašovský",
  "Fico",
  "Filc",
  "Filip",
  "Finka",
  "Ftorek",
  "Gašpar",
  "Gašparovič",
  "Gocník",
  "Gregor",
  "Greguš",
  "Grznár",
  "Hablák",
  "Habšuda",
  "Halda",
  "Haluška",
  "Halák",
  "Hanko",
  "Hanzal",
  "Haščák",
  "Heretik",
  "Hečko",
  "Hlaváček",
  "Hlinka",
  "Holub",
  "Holuby",
  "Hossa",
  "Hoza",
  "Hraško",
  "Hric",
  "Hrmo",
  "Hrušovský",
  "Huba",
  "Ihnačák",
  "Janeček",
  "Janoška",
  "Jantošovič",
  "Janík",
  "Janček",
  "Jedľovský",
  "Jendek",
  "Jonata",
  "Jurina",
  "Jurkovič",
  "Jurík",
  "Jánošík",
  "Kafenda",
  "Kaliský",
  "Karul",
  "Keníž",
  "Klapka",
  "Kmeť",
  "Kolesár",
  "Kollár",
  "Kolnik",
  "Kolník",
  "Kolár",
  "Korec",
  "Kostka",
  "Kostrec",
  "Kováč",
  "Kováčik",
  "Koza",
  "Kočiš",
  "Krajíček",
  "Krajči",
  "Krajčo",
  "Krajčovič",
  "Krajčír",
  "Králik",
  "Krúpa",
  "Kubík",
  "Kyseľ",
  "Kállay",
  "Labuda",
  "Lepšík",
  "Lipták",
  "Lisický",
  "Lubina",
  "Lukáč",
  "Lupták",
  "Líška",
  "Madej",
  "Majeský",
  "Malachovský",
  "Malíšek",
  "Mamojka",
  "Marcinko",
  "Marián",
  "Masaryk",
  "Maslo",
  "Matiaško",
  "Medveď",
  "Melcer",
  "Mečiar",
  "Michalík",
  "Mihalik",
  "Mihál",
  "Mihálik",
  "Mikloško",
  "Mikulík",
  "Mikuš",
  "Mikúš",
  "Milota",
  "Mináč",
  "Mišík",
  "Mojžiš",
  "Mokroš",
  "Mora",
  "Moravčík",
  "Mydlo",
  "Nemec",
  "Nitra",
  "Novák",
  "Obšut",
  "Ondruš",
  "Otčenáš",
  "Pauko",
  "Pavlikovský",
  "Pavúk",
  "Pašek",
  "Paška",
  "Paško",
  "Pelikán",
  "Petrovický",
  "Petruška",
  "Peško",
  "Plch",
  "Plekanec",
  "Podhradský",
  "Podkonický",
  "Poliak",
  "Pupák",
  "Rak",
  "Repiský",
  "Romančík",
  "Rus",
  "Ružička",
  "Rybníček",
  "Rybár",
  "Rybárik",
  "Samson",
  "Sedliak",
  "Senko",
  "Sklenka",
  "Skokan",
  "Skutecký",
  "Slašťan",
  "Sloboda",
  "Slobodník",
  "Slota",
  "Slovák",
  "Smrek",
  "Stodola",
  "Straka",
  "Strnisko",
  "Svrbík",
  "Sámel",
  "Sýkora",
  "Tatar",
  "Tatarka",
  "Tatár",
  "Tatárka",
  "Thomka",
  "Tomeček",
  "Tomka",
  "Tomko",
  "Truben",
  "Turčok",
  "Uram",
  "Urblík",
  "Vajcík",
  "Vajda",
  "Valach",
  "Valachovič",
  "Valent",
  "Valuška",
  "Vanek",
  "Vesel",
  "Vicen",
  "Višňovský",
  "Vlach",
  "Vojtek",
  "Vydarený",
  "Zajac",
  "Zima",
  "Zimka",
  "Záborský",
  "Zúbrik",
  "Čapkovič",
  "Čaplovič",
  "Čarnogurský",
  "Čierny",
  "Čobrda",
  "Ďaďo",
  "Ďurica",
  "Ďuriš",
  "Šidlo",
  "Šimonovič",
  "Škriniar",
  "Škultéty",
  "Šmajda",
  "Šoltés",
  "Šoltýs",
  "Štefan",
  "Štefanka",
  "Šulc",
  "Šurka",
  "Švehla",
  "Šťastný"
];
