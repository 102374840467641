module["exports"] = [
  "Bakker",
  "Beek",
  "Berg",
  "Boer",
  "Bos",
  "Bosch",
  "Brink",
  "Broek",
  "Brouwer",
  "Bruin",
  "Dam",
  "Dekker",
  "Dijk",
  "Dijkstra",
  "Graaf",
  "Groot",
  "Haan",
  "Hendriks",
  "Heuvel",
  "Hoek",
  "Jacobs",
  "Jansen",
  "Janssen",
  "Jong",
  "Klein",
  "Kok",
  "Koning",
  "Koster",
  "Leeuwen",
  "Linden",
  "Maas",
  "Meer",
  "Meijer",
  "Mulder",
  "Peters",
  "Ruiter",
  "Schouten",
  "Smit",
  "Smits",
  "Stichting",
  "Veen",
  "Ven",
  "Vermeulen",
  "Visser",
  "Vliet",
  "Vos",
  "Vries",
  "Wal",
  "Willems",
  "Wit"
];
