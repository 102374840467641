module["exports"] = [
  " aan de IJssel",
  " aan de Rijn",
  "ambacht",
  "beek",
  "berg",
  "bergen",
  "bosch",
  "broek",
  "brug",
  "buren",
  "burg",
  "buurt",
  "dam",
  "dijk",
  "dijke",
  "donk",
  "dorp",
  "eind",
  "enmaes",
  "gat",
  "geest",
  "heide",
  "hoek",
  "horst",
  "hout",
  "hoven",
  "huizen",
  "ingen",
  "kerk",
  "laar",
  "land",
  "meer",
  "recht",
  "schoten",
  "sluis",
  "stroom",
  "swaerd",
  "veen",
  "veld",
  "vliet",
  "weer",
  "wier",
  "wijk",
  "woud",
  "woude",
  "zijl",
  ""
];
