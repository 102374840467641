module["exports"] = [
  "آبان دخت",
  "آبتین",
  "آتوسا",
  "آفر",
  "آفره دخت",
  "آذرنوش‌",
  "آذین",
  "آراه",
  "آرزو",
  "آرش",
  "آرتین",
  "آرتام",
  "آرتمن",
  "آرشام",
  "آرمان",
  "آرمین",
  "آرمیتا",
  "آریا فر",
  "آریا",
  "آریا مهر",
  "آرین",
  "آزاده",
  "آزرم",
  "آزرمدخت",
  "آزیتا",
  "آناهیتا",
  "آونگ",
  "آهو",
  "آیدا",
  "اتسز",
  "اختر",
  "ارد",
  "ارد شیر",
  "اردوان",
  "ارژن",
  "ارژنگ",
  "ارسلان",
  "ارغوان",
  "ارمغان",
  "ارنواز",
  "اروانه",
  "استر",
  "اسفندیار",
  "اشکان",
  "اشکبوس",
  "افسانه",
  "افسون",
  "افشین",
  "امید",
  "انوش (‌ آنوشا )",
  "انوشروان",
  "اورنگ",
  "اوژن",
  "اوستا",
  "اهورا",
  "ایاز",
  "ایران",
  "ایراندخت",
  "ایرج",
  "ایزدیار",
  "بابک",
  "باپوک",
  "باربد",
  "بارمان",
  "بامداد",
  "بامشاد",
  "بانو",
  "بختیار",
  "برانوش",
  "بردیا",
  "برزو",
  "برزویه",
  "برزین",
  "برمک",
  "بزرگمهر",
  "بنفشه",
  "بوژان",
  "بویان",
  "بهار",
  "بهارک",
  "بهاره",
  "بهتاش",
  "بهداد",
  "بهرام",
  "بهدیس",
  "بهرخ",
  "بهرنگ",
  "بهروز",
  "بهزاد",
  "بهشاد",
  "بهمن",
  "بهناز",
  "بهنام",
  "بهنود",
  "بهنوش",
  "بیتا",
  "بیژن",
  "پارسا",
  "پاکان",
  "پاکتن",
  "پاکدخت",
  "پانته آ",
  "پدرام",
  "پرتو",
  "پرشنگ",
  "پرتو",
  "پرستو",
  "پرویز",
  "پردیس",
  "پرهام",
  "پژمان",
  "پژوا",
  "پرنیا",
  "پشنگ",
  "پروانه",
  "پروین",
  "پری",
  "پریچهر",
  "پریدخت",
  "پریسا",
  "پرناز",
  "پریوش",
  "پریا",
  "پوپک",
  "پوران",
  "پوراندخت",
  "پوریا",
  "پولاد",
  "پویا",
  "پونه",
  "پیام",
  "پیروز",
  "پیمان",
  "تابان",
  "تاباندخت",
  "تاجی",
  "تارا",
  "تاویار",
  "ترانه",
  "تناز",
  "توران",
  "توراندخت",
  "تورج",
  "تورتک",
  "توفان",
  "توژال",
  "تیر داد",
  "تینا",
  "تینو",
  "جابان",
  "جامین",
  "جاوید",
  "جریره",
  "جمشید",
  "جوان",
  "جویا",
  "جهان",
  "جهانبخت",
  "جهانبخش",
  "جهاندار",
  "جهانگیر",
  "جهان بانو",
  "جهاندخت",
  "جهان ناز",
  "جیران",
  "چابک",
  "چالاک",
  "چاوش",
  "چترا",
  "چوبین",
  "چهرزاد",
  "خاوردخت",
  "خداداد",
  "خدایار",
  "خرم",
  "خرمدخت",
  "خسرو",
  "خشایار",
  "خورشید",
  "دادمهر",
  "دارا",
  "داراب",
  "داریا",
  "داریوش",
  "دانوش",
  "داور‌",
  "دایان",
  "دریا",
  "دل آرا",
  "دل آویز",
  "دلارام",
  "دل انگیز",
  "دلبر",
  "دلبند",
  "دلربا",
  "دلشاد",
  "دلکش",
  "دلناز",
  "دلنواز",
  "دورشاسب",
  "دنیا",
  "دیااکو",
  "دیانوش",
  "دیبا",
  "دیبا دخت",
  "رابو",
  "رابین",
  "رادبانو",
  "رادمان",
  "رازبان",
  "راژانه",
  "راسا",
  "رامتین",
  "رامش",
  "رامشگر",
  "رامونا",
  "رامیار",
  "رامیلا",
  "رامین",
  "راویار",
  "رژینا",
  "رخپاک",
  "رخسار",
  "رخشانه",
  "رخشنده",
  "رزمیار",
  "رستم",
  "رکسانا",
  "روبینا",
  "رودابه",
  "روزبه",
  "روشنک",
  "روناک",
  "رهام",
  "رهی",
  "ریبار",
  "راسپینا",
  "زادبخت",
  "زاد به",
  "زاد چهر",
  "زاد فر",
  "زال",
  "زادماسب",
  "زاوا",
  "زردشت",
  "زرنگار",
  "زری",
  "زرین",
  "زرینه",
  "زمانه",
  "زونا",
  "زیبا",
  "زیبار",
  "زیما",
  "زینو",
  "ژاله",
  "ژالان",
  "ژیار",
  "ژینا",
  "ژیوار",
  "سارا",
  "سارک",
  "سارنگ",
  "ساره",
  "ساسان",
  "ساغر",
  "سام",
  "سامان",
  "سانا",
  "ساناز",
  "سانیار",
  "ساویز",
  "ساهی",
  "ساینا",
  "سایه",
  "سپنتا",
  "سپند",
  "سپهر",
  "سپهرداد",
  "سپیدار",
  "سپید بانو",
  "سپیده",
  "ستاره",
  "ستی",
  "سرافراز",
  "سرور",
  "سروش",
  "سرور",
  "سوبا",
  "سوبار",
  "سنبله",
  "سودابه",
  "سوری",
  "سورن",
  "سورنا",
  "سوزان",
  "سوزه",
  "سوسن",
  "سومار",
  "سولان",
  "سولماز",
  "سوگند",
  "سهراب",
  "سهره",
  "سهند",
  "سیامک",
  "سیاوش",
  "سیبوبه ‌",
  "سیما",
  "سیمدخت",
  "سینا",
  "سیمین",
  "سیمین دخت",
  "شاپرک",
  "شادی",
  "شادمهر",
  "شاران",
  "شاهپور",
  "شاهدخت",
  "شاهرخ",
  "شاهین",
  "شاهیندخت",
  "شایسته",
  "شباهنگ",
  "شب بو",
  "شبدیز",
  "شبنم",
  "شراره",
  "شرمین",
  "شروین",
  "شکوفه",
  "شکفته",
  "شمشاد",
  "شمین",
  "شوان",
  "شمیلا",
  "شورانگیز",
  "شوری",
  "شهاب",
  "شهبار",
  "شهباز",
  "شهبال",
  "شهپر",
  "شهداد",
  "شهرآرا",
  "شهرام",
  "شهربانو",
  "شهرزاد",
  "شهرناز",
  "شهرنوش",
  "شهره",
  "شهریار",
  "شهرزاد",
  "شهلا",
  "شهنواز",
  "شهین",
  "شیبا",
  "شیدا",
  "شیده",
  "شیردل",
  "شیرزاد",
  "شیرنگ",
  "شیرو",
  "شیرین دخت",
  "شیما",
  "شینا",
  "شیرین",
  "شیوا",
  "طوس",
  "طوطی",
  "طهماسب",
  "طهمورث",
  "غوغا",
  "غنچه",
  "فتانه",
  "فدا",
  "فراز",
  "فرامرز",
  "فرانک",
  "فراهان",
  "فربد",
  "فربغ",
  "فرجاد",
  "فرخ",
  "فرخ پی",
  "فرخ داد",
  "فرخ رو",
  "فرخ زاد",
  "فرخ لقا",
  "فرخ مهر",
  "فرداد",
  "فردیس",
  "فرین",
  "فرزاد",
  "فرزام",
  "فرزان",
  "فرزانه",
  "فرزین",
  "فرشاد",
  "فرشته",
  "فرشید",
  "فرمان",
  "فرناز",
  "فرنگیس",
  "فرنود",
  "فرنوش",
  "فرنیا",
  "فروتن",
  "فرود",
  "فروز",
  "فروزان",
  "فروزش",
  "فروزنده",
  "فروغ",
  "فرهاد",
  "فرهنگ",
  "فرهود",
  "فربار",
  "فریبا",
  "فرید",
  "فریدخت",
  "فریدون",
  "فریمان",
  "فریناز",
  "فرینوش",
  "فریوش",
  "فیروز",
  "فیروزه",
  "قابوس",
  "قباد",
  "قدسی",
  "کابان",
  "کابوک",
  "کارا",
  "کارو",
  "کاراکو",
  "کامبخت",
  "کامبخش",
  "کامبیز",
  "کامجو",
  "کامدین",
  "کامران",
  "کامراوا",
  "کامک",
  "کامنوش",
  "کامیار",
  "کانیار",
  "کاووس",
  "کاوه",
  "کتایون",
  "کرشمه",
  "کسری",
  "کلاله",
  "کمبوجیه",
  "کوشا",
  "کهبد",
  "کهرام",
  "کهزاد",
  "کیارش",
  "کیان",
  "کیانا",
  "کیانچهر",
  "کیاندخت",
  "کیانوش",
  "کیاوش",
  "کیخسرو",
  "کیقباد",
  "کیکاووس",
  "کیوان",
  "کیوان دخت",
  "کیومرث",
  "کیهان",
  "کیاندخت",
  "کیهانه",
  "گرد آفرید",
  "گردان",
  "گرشا",
  "گرشاسب",
  "گرشین",
  "گرگین",
  "گزل",
  "گشتاسب",
  "گشسب",
  "گشسب بانو",
  "گل",
  "گل آذین",
  "گل آرا‌",
  "گلاره",
  "گل افروز",
  "گلاله",
  "گل اندام",
  "گلاویز",
  "گلباد",
  "گلبار",
  "گلبام",
  "گلبان",
  "گلبانو",
  "گلبرگ",
  "گلبو",
  "گلبهار",
  "گلبیز",
  "گلپاره",
  "گلپر",
  "گلپری",
  "گلپوش",
  "گل پونه",
  "گلچین",
  "گلدخت",
  "گلدیس",
  "گلربا",
  "گلرخ",
  "گلرنگ",
  "گلرو",
  "گلشن",
  "گلریز",
  "گلزاد",
  "گلزار",
  "گلسا",
  "گلشید",
  "گلنار",
  "گلناز",
  "گلنسا",
  "گلنواز",
  "گلنوش",
  "گلی",
  "گودرز",
  "گوماتو",
  "گهر چهر",
  "گوهر ناز",
  "گیتی",
  "گیسو",
  "گیلدا",
  "گیو",
  "لادن",
  "لاله",
  "لاله رخ",
  "لاله دخت",
  "لبخند",
  "لقاء",
  "لومانا",
  "لهراسب",
  "مارال",
  "ماری",
  "مازیار",
  "ماکان",
  "مامک",
  "مانا",
  "ماندانا",
  "مانوش",
  "مانی",
  "مانیا",
  "ماهان",
  "ماهاندخت",
  "ماه برزین",
  "ماه جهان",
  "ماهچهر",
  "ماهدخت",
  "ماهور",
  "ماهرخ",
  "ماهزاد",
  "مردآویز",
  "مرداس",
  "مرزبان",
  "مرمر",
  "مزدک",
  "مژده",
  "مژگان",
  "مستان",
  "مستانه",
  "مشکاندخت",
  "مشکناز",
  "مشکین دخت",
  "منیژه",
  "منوچهر",
  "مهبانو",
  "مهبد",
  "مه داد",
  "مهتاب",
  "مهدیس",
  "مه جبین",
  "مه دخت",
  "مهر آذر",
  "مهر آرا",
  "مهر آسا",
  "مهر آفاق",
  "مهر افرین",
  "مهرآب",
  "مهرداد",
  "مهر افزون",
  "مهرام",
  "مهران",
  "مهراندخت",
  "مهراندیش",
  "مهرانفر",
  "مهرانگیز",
  "مهرداد",
  "مهر دخت",
  "مهرزاده ‌",
  "مهرناز",
  "مهرنوش",
  "مهرنکار",
  "مهرنیا",
  "مهروز",
  "مهری",
  "مهریار",
  "مهسا",
  "مهستی",
  "مه سیما",
  "مهشاد",
  "مهشید",
  "مهنام",
  "مهناز",
  "مهنوش",
  "مهوش",
  "مهیار",
  "مهین",
  "مهین دخت",
  "میترا",
  "میخک",
  "مینا",
  "مینا دخت",
  "مینو",
  "مینودخت",
  "مینو فر",
  "نادر",
  "ناز آفرین",
  "نازبانو",
  "نازپرور",
  "نازچهر",
  "نازفر",
  "نازلی",
  "نازی",
  "نازیدخت",
  "نامور",
  "ناهید",
  "ندا",
  "نرسی",
  "نرگس",
  "نرمک",
  "نرمین",
  "نریمان",
  "نسترن",
  "نسرین",
  "نسرین دخت",
  "نسرین نوش",
  "نکیسا",
  "نگار",
  "نگاره",
  "نگارین",
  "نگین",
  "نوا",
  "نوش",
  "نوش آذر",
  "نوش آور",
  "نوشا",
  "نوش آفرین",
  "نوشدخت",
  "نوشروان",
  "نوشفر",
  "نوشناز",
  "نوشین",
  "نوید",
  "نوین",
  "نوین دخت",
  "نیش ا",
  "نیک بین",
  "نیک پی",
  "نیک چهر",
  "نیک خواه",
  "نیکداد",
  "نیکدخت",
  "نیکدل",
  "نیکزاد",
  "نیلوفر",
  "نیما",
  "وامق",
  "ورجاوند",
  "وریا",
  "وشمگیر",
  "وهرز",
  "وهسودان",
  "ویدا",
  "ویس",
  "ویشتاسب",
  "ویگن",
  "هژیر",
  "هخامنش",
  "هربد( هیربد )",
  "هرمز",
  "همایون",
  "هما",
  "همادخت",
  "همدم",
  "همراز",
  "همراه",
  "هنگامه",
  "هوتن",
  "هور",
  "هورتاش",
  "هورچهر",
  "هورداد",
  "هوردخت",
  "هورزاد",
  "هورمند",
  "هوروش",
  "هوشنگ",
  "هوشیار",
  "هومان",
  "هومن",
  "هونام",
  "هویدا",
  "هیتاسب",
  "هیرمند",
  "هیما",
  "هیوا",
  "یادگار",
  "یاسمن ( یاسمین )",
  "یاشار",
  "یاور",
  "یزدان",
  "یگانه",
  "یوشیتا"
];
