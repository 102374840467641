module["exports"] = [
  "Адамівна",
  "Азарівна",
  "Алевтинівна",
  "Альбертівна",
  "Анастасівна",
  "Анатоліївна",
  "Андріївна",
  "Антонівна",
  "Аркадіївна",
  "Арсенівна",
  "Арсеніївна",
  "Артемівна",
  "Архипівна",
  "Аскольдівна",
  "Афанасіївна",
  "Білославівна",
  "Богданівна",
  "Божемирівна",
  "Боженівна",
  "Болеславівна",
  "Боримирівна",
  "Борисівна",
  "Бориславівна",
  "Братиславівна",
  "В’ячеславівна",
  "Вадимівна",
  "Валентинівна",
  "Валеріївна",
  "Василівна",
  "Вікторівна",
  "Віталіївна",
  "Владиславівна",
  "Володимирівна",
  "Всеволодівна",
  "Всеславівна",
  "Гаврилівна",
  "Гарасимівна",
  "Георгіївна",
  "Гнатівна",
  "Гордіївна",
  "Григоріївна",
  "Данилівна",
  "Даромирівна",
  "Денисівна",
  "Дмитрівна",
  "Добромирівна",
  "Доброславівна",
  "Євгенівна",
  "Захарівна",
  "Захаріївна",
  "Збориславівна",
  "Звенимирівна",
  "Звениславівна",
  "Зеновіївна",
  "Зиновіївна",
  "Златомирівна",
  "Зореславівна",
  "Іванівна",
  "Ігорівна",
  "Ізяславівна",
  "Корнеліївна",
  "Корнилівна",
  "Корніївна",
  "Костянтинівна",
  "Лаврентіївна",
  "Любомирівна",
  "Макарівна",
  "Максимівна",
  "Марківна",
  "Маркіянівна",
  "Матвіївна",
  "Мечиславівна",
  "Микитівна",
  "Миколаївна",
  "Миронівна",
  "Мирославівна",
  "Михайлівна",
  "Мстиславівна",
  "Назарівна",
  "Назаріївна",
  "Натанівна",
  "Немирівна",
  "Несторівна",
  "Олегівна",
  "Олександрівна",
  "Олексіївна",
  "Олельківна",
  "Омелянівна",
  "Орестівна",
  "Орхипівна",
  "Остапівна",
  "Охрімівна",
  "Павлівна",
  "Панасівна",
  "Пантелеймонівна",
  "Петрівна",
  "Пилипівна",
  "Радимирівна",
  "Радимівна",
  "Родіонівна",
  "Романівна",
  "Ростиславівна",
  "Русланівна",
  "Святославівна",
  "Сергіївна",
  "Славутівна",
  "Станіславівна",
  "Степанівна",
  "Стефаніївна",
  "Тарасівна",
  "Тимофіївна",
  "Тихонівна",
  "Устимівна",
  "Юріївна",
  "Юхимівна",
  "Ярославівна"
];
