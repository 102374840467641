module["exports"] = [
  "+## ### ## ## ####",
  "+## ## #######",
  "+## ## ########",
  "+## ### #######",
  "+## ### ########",
  "+## #### #######",
  "+## #### ########",
  "0## ### ####",
  "+39 0## ### ###",
  "3## ### ###",
  "+39 3## ### ###"
];
