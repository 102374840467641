module["exports"] = [
  "24 ore",
  "24/7",
  "terza generazione",
  "quarta generazione",
  "quinta generazione",
  "sesta generazione",
  "asimmetrica",
  "asincrona",
  "background",
  "bi-direzionale",
  "biforcata",
  "bottom-line",
  "coerente",
  "coesiva",
  "composita",
  "sensibile al contesto",
  "basta sul contesto",
  "basata sul contenuto",
  "dedicata",
  "didattica",
  "direzionale",
  "discreta",
  "dinamica",
  "eco-centrica",
  "esecutiva",
  "esplicita",
  "full-range",
  "globale",
  "euristica",
  "alto livello",
  "olistica",
  "omogenea",
  "ibrida",
  "impattante",
  "incrementale",
  "intangibile",
  "interattiva",
  "intermediaria",
  "locale",
  "logistica",
  "massimizzata",
  "metodica",
  "mission-critical",
  "mobile",
  "modulare",
  "motivazionale",
  "multimedia",
  "multi-tasking",
  "nazionale",
  "neutrale",
  "nextgeneration",
  "non-volatile",
  "object-oriented",
  "ottima",
  "ottimizzante",
  "radicale",
  "real-time",
  "reciproca",
  "regionale",
  "responsiva",
  "scalabile",
  "secondaria",
  "stabile",
  "statica",
  "sistematica",
  "sistemica",
  "tangibile",
  "terziaria",
  "uniforme",
  "valore aggiunto"
];
