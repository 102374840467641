module["exports"] = [
  "qc.ca",
  "ca",
  "com",
  "biz",
  "info",
  "name",
  "net",
  "org"
];
