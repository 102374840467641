module["exports"] = [
  "Августин",
  "Аврелій",
  "Адам",
  "Адріян",
  "Азарій",
  "Алевтин",
  "Альберт",
  "Анастас",
  "Анастасій",
  "Анатолій",
  "Андрій",
  "Антін",
  "Антон",
  "Антоній",
  "Аркадій",
  "Арсен",
  "Арсеній",
  "Артем",
  "Архип",
  "Аскольд",
  "Афанасій",
  "Біломир",
  "Білослав",
  "Богдан",
  "Божемир",
  "Божен",
  "Болеслав",
  "Боримир",
  "Боримисл",
  "Борис",
  "Борислав",
  "Братимир",
  "Братислав",
  "Братомил",
  "Братослав",
  "Брячислав",
  "Будимир",
  "Буйтур",
  "Буревіст",
  "В’ячеслав",
  "Вадим",
  "Валентин",
  "Валерій",
  "Василь",
  "Велемир",
  "Віктор",
  "Віталій",
  "Влад",
  "Владислав",
  "Володимир",
  "Володислав",
  "Всевлад",
  "Всеволод",
  "Всеслав",
  "Гаврило",
  "Гарнослав",
  "Геннадій",
  "Георгій",
  "Герасим",
  "Гліб",
  "Гнат",
  "Гордій",
  "Горимир",
  "Горислав",
  "Градимир",
  "Григорій",
  "Далемир",
  "Данило",
  "Дарій",
  "Даромир",
  "Денис",
  "Дмитро",
  "Добромир",
  "Добромисл",
  "Доброслав",
  "Євген",
  "Єремій",
  "Захар",
  "Захарій",
  "Зборислав",
  "Звенигор",
  "Звенимир",
  "Звенислав",
  "Земислав",
  "Зеновій",
  "Зиновій",
  "Злат",
  "Златомир",
  "Зоремир",
  "Зореслав",
  "Зорян",
  "Іван",
  "Ігор",
  "Ізяслав",
  "Ілля",
  "Кий",
  "Корнелій",
  "Корнилій",
  "Корнило",
  "Корній",
  "Костянтин",
  "Кузьма",
  "Лаврентій",
  "Лаврін",
  "Лад",
  "Ладислав",
  "Ладо",
  "Ладомир",
  "Левко",
  "Листвич",
  "Лук’ян",
  "Любодар",
  "Любозар",
  "Любомир",
  "Макар",
  "Максим",
  "Мар’ян",
  "Маркіян",
  "Марко",
  "Матвій",
  "Мечислав",
  "Микита",
  "Микола",
  "Мирон",
  "Мирослав",
  "Михайло",
  "Мстислав",
  "Мусій",
  "Назар",
  "Назарій",
  "Натан",
  "Немир",
  "Нестор",
  "Олег",
  "Олександр",
  "Олексій",
  "Олелько",
  "Олесь",
  "Омелян",
  "Орест",
  "Орхип",
  "Остап",
  "Охрім",
  "Павло",
  "Панас",
  "Пантелеймон",
  "Петро",
  "Пилип",
  "Подолян",
  "Потап",
  "Радим",
  "Радимир",
  "Ратибор",
  "Ратимир",
  "Родіон",
  "Родослав",
  "Роксолан",
  "Роман",
  "Ростислав",
  "Руслан",
  "Святополк",
  "Святослав",
  "Семибор",
  "Сергій",
  "Синьоок",
  "Славолюб",
  "Славомир",
  "Славута",
  "Сніжан",
  "Сологуб",
  "Станіслав",
  "Степан",
  "Стефаній",
  "Стожар",
  "Тарас",
  "Тиміш",
  "Тимофій",
  "Тихон",
  "Тур",
  "Устим",
  "Хвалимир",
  "Хорив",
  "Чорнота",
  "Щастислав",
  "Щек",
  "Юліан",
  "Юрій",
  "Юхим",
  "Ян",
  "Ярема",
  "Яровид",
  "Яромил",
  "Яромир",
  "Ярополк",
  "Ярослав"
];
