module["exports"] = {
  "job": [
    "Superviseur",
    "Executif",
    "Manager",
    "Ingenieur",
    "Specialiste",
    "Directeur",
    "Coordinateur",
    "Administrateur",
    "Architecte",
    "Analyste",
    "Designer",
    "Technicien",
    "Developpeur",
    "Producteur",
    "Consultant",
    "Assistant",
    "Agent",
    "Stagiaire"
  ]
};
