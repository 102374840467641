module["exports"] = [
  "Checking",
  "Savings",
  "Money Market",
  "Investment",
  "Home Loan",
  "Credit Card",
  "Auto Loan",
  "Personal Loan"
];
