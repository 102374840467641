module["exports"] = [
  "Clics y mortero",
  "Valor añadido",
  "Vertical",
  "Proactivo",
  "Robusto",
  "Revolucionario",
  "Escalable",
  "De vanguardia",
  "Innovador",
  "Intuitivo",
  "Estratégico",
  "E-business",
  "Misión crítica",
  "Pegajosa",
  "Doce y cincuenta y nueve de la noche",
  "24/7",
  "De extremo a extremo",
  "Global",
  "B2B",
  "B2C",
  "Granular",
  "Fricción",
  "Virtual",
  "Viral",
  "Dinámico",
  "24/365",
  "Mejor de su clase",
  "Asesino",
  "Magnética",
  "Filo sangriento",
  "Habilitado web",
  "Interactiva",
  "Punto com",
  "Sexy",
  "Back-end",
  "Tiempo real",
  "Eficiente",
  "Frontal",
  "Distribuida",
  "Sin costura",
  "Extensible",
  "Llave en mano",
  "Clase mundial",
  "Código abierto",
  "Multiplataforma",
  "Cross-media",
  "Sinérgico",
  "ladrillos y clics",
  "Fuera de la caja",
  "Empresa",
  "Integrado",
  "Impactante",
  "Inalámbrico",
  "Transparente",
  "Próxima generación",
  "Innovador",
  "User-centric",
  "Visionario",
  "A medida",
  "Ubicua",
  "Enchufa y juega",
  "Colaboración",
  "Convincente",
  "Holístico",
  "Ricos"
];
