module["exports"] = [
  "5##-###-###",
  "5########",
  "5## ## ## ##",
  "5## ######",
  "5## ### ###",
  "995 5##-###-###",
  "995 5########",
  "995 5## ## ## ##",
  "995 5## ######",
  "995 5## ### ###",
  "+995 5##-###-###",
  "+995 5########",
  "+995 5## ## ## ##",
  "+995 5## ######",
  "+995 5## ### ###",
  "(+995) 5##-###-###",
  "(+995) 5########",
  "(+995) 5## ## ## ##",
  "(+995) 5## ######",
  "(+995) 5## ### ###"
];
