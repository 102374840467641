module["exports"] = [
  "Drahoslav",
  "Severín",
  "Alexej",
  "Ernest",
  "Rastislav",
  "Radovan",
  "Dobroslav",
  "Dalibor",
  "Vincent",
  "Miloš",
  "Timotej",
  "Gejza",
  "Bohuš",
  "Alfonz",
  "Gašpar",
  "Emil",
  "Erik",
  "Blažej",
  "Zdenko",
  "Dezider",
  "Arpád",
  "Valentín",
  "Pravoslav",
  "Jaromír",
  "Roman",
  "Matej",
  "Frederik",
  "Viktor",
  "Alexander",
  "Radomír",
  "Albín",
  "Bohumil",
  "Kazimír",
  "Fridrich",
  "Radoslav",
  "Tomáš",
  "Alan",
  "Branislav",
  "Bruno",
  "Gregor",
  "Vlastimil",
  "Boleslav",
  "Eduard",
  "Jozef",
  "Víťazoslav",
  "Blahoslav",
  "Beňadik",
  "Adrián",
  "Gabriel",
  "Marián",
  "Emanuel",
  "Miroslav",
  "Benjamín",
  "Hugo",
  "Richard",
  "Izidor",
  "Zoltán",
  "Albert",
  "Igor",
  "Július",
  "Aleš",
  "Fedor",
  "Rudolf",
  "Valér",
  "Marcel",
  "Ervín",
  "Slavomír",
  "Vojtech",
  "Juraj",
  "Marek",
  "Jaroslav",
  "Žigmund",
  "Florián",
  "Roland",
  "Pankrác",
  "Servác",
  "Bonifác",
  "Svetozár",
  "Bernard",
  "Júlia",
  "Urban",
  "Dušan",
  "Viliam",
  "Ferdinand",
  "Norbert",
  "Róbert",
  "Medard",
  "Zlatko",
  "Anton",
  "Vasil",
  "Vít",
  "Adolf",
  "Vratislav",
  "Alfréd",
  "Alojz",
  "Ján",
  "Tadeáš",
  "Ladislav",
  "Peter",
  "Pavol",
  "Miloslav",
  "Prokop",
  "Cyril",
  "Metod",
  "Patrik",
  "Oliver",
  "Ivan",
  "Kamil",
  "Henrich",
  "Drahomír",
  "Bohuslav",
  "Iľja",
  "Daniel",
  "Vladimír",
  "Jakub",
  "Krištof",
  "Ignác",
  "Gustáv",
  "Jerguš",
  "Dominik",
  "Oskar",
  "Vavrinec",
  "Ľubomír",
  "Mojmír",
  "Leonard",
  "Tichomír",
  "Filip",
  "Bartolomej",
  "Ľudovít",
  "Samuel",
  "Augustín",
  "Belo",
  "Oleg",
  "Bystrík",
  "Ctibor",
  "Ľudomil",
  "Konštantín",
  "Ľuboslav",
  "Matúš",
  "Móric",
  "Ľuboš",
  "Ľubor",
  "Vladislav",
  "Cyprián",
  "Václav",
  "Michal",
  "Jarolím",
  "Arnold",
  "Levoslav",
  "František",
  "Dionýz",
  "Maximilián",
  "Koloman",
  "Boris",
  "Lukáš",
  "Kristián",
  "Vendelín",
  "Sergej",
  "Aurel",
  "Demeter",
  "Denis",
  "Hubert",
  "Karol",
  "Imrich",
  "René",
  "Bohumír",
  "Teodor",
  "Tibor",
  "Maroš",
  "Martin",
  "Svätopluk",
  "Stanislav",
  "Leopold",
  "Eugen",
  "Félix",
  "Klement",
  "Kornel",
  "Milan",
  "Vratko",
  "Ondrej",
  "Andrej",
  "Edmund",
  "Oldrich",
  "Oto",
  "Mikuláš",
  "Ambróz",
  "Radúz",
  "Bohdan",
  "Adam",
  "Štefan",
  "Dávid",
  "Silvester"
];
