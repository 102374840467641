module["exports"] = [
  "Александрович",
  "Алексеевич",
  "Альбертович",
  "Анатольевич",
  "Андреевич",
  "Антонович",
  "Аркадьевич",
  "Арсеньевич",
  "Артёмович",
  "Борисович",
  "Вадимович",
  "Валентинович",
  "Валерьевич",
  "Васильевич",
  "Викторович",
  "Витальевич",
  "Владимирович",
  "Владиславович",
  "Вячеславович",
  "Геннадьевич",
  "Георгиевич",
  "Германович",
  "Григорьевич",
  "Даниилович",
  "Денисович",
  "Дмитриевич",
  "Евгеньевич",
  "Егорович",
  "Иванович",
  "Игнатьевич",
  "Игоревич",
  "Ильич",
  "Константинович",
  "Лаврентьевич",
  "Леонидович",
  "Лукич",
  "Макарович",
  "Максимович",
  "Матвеевич",
  "Михайлович",
  "Никитич",
  "Николаевич",
  "Олегович",
  "Романович",
  "Семёнович",
  "Сергеевич",
  "Станиславович",
  "Степанович",
  "Фёдорович",
  "Эдуардович",
  "Юрьевич",
  "Ярославович"
];
