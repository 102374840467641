module["exports"] = [
  "synergies",
  "web-readiness",
  "paradigms",
  "markets",
  "partnerships",
  "infrastructures",
  "platforms",
  "initiatives",
  "channels",
  "eyeballs",
  "communities",
  "ROI",
  "solutions",
  "e-tailers",
  "e-services",
  "action-items",
  "portals",
  "niches",
  "technologies",
  "content",
  "vortals",
  "supply-chains",
  "convergence",
  "relationships",
  "architectures",
  "interfaces",
  "e-markets",
  "e-commerce",
  "systems",
  "bandwidth",
  "infomediaries",
  "models",
  "mindshare",
  "deliverables",
  "users",
  "schemas",
  "networks",
  "applications",
  "metrics",
  "e-business",
  "functionalities",
  "experiences",
  "web services",
  "methodologies"
];
