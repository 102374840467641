module["exports"] = [
  "Afghanistan",
  "Akrotiri",
  "Albanië",
  "Algerije",
  "Amerikaanse Maagdeneilanden",
  "Amerikaans-Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua en Barbuda",
  "Arctic Ocean",
  "Argentinië",
  "Armenië",
  "Aruba",
  "Ashmore and Cartier Islands",
  "Atlantic Ocean",
  "Australië",
  "Azerbeidzjan",
  "Bahama's",
  "Bahrein",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "België",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivië",
  "Bosnië-Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazilië",
  "British Indian Ocean Territory",
  "Britse Maagdeneilanden",
  "Brunei",
  "Bulgarije",
  "Burkina Faso",
  "Burundi",
  "Cambodja",
  "Canada",
  "Caymaneilanden",
  "Centraal-Afrikaanse Republiek",
  "Chili",
  "China",
  "Christmas Island",
  "Clipperton Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoren (Unie)",
  "Congo (Democratische Republiek)",
  "Congo (Volksrepubliek)",
  "Cook",
  "Coral Sea Islands",
  "Costa Rica",
  "Cuba",
  "Cyprus",
  "Denemarken",
  "Dhekelia",
  "Djibouti",
  "Dominica",
  "Dominicaanse Republiek",
  "Duitsland",
  "Ecuador",
  "Egypte",
  "El Salvador",
  "Equatoriaal-Guinea",
  "Eritrea",
  "Estland",
  "Ethiopië",
  "European Union",
  "Falkland",
  "Faroe Islands",
  "Fiji",
  "Filipijnen",
  "Finland",
  "Frankrijk",
  "Frans-Polynesië",
  "French Southern and Antarctic Lands",
  "Gabon",
  "Gambia",
  "Gaza Strip",
  "Georgië",
  "Ghana",
  "Gibraltar",
  "Grenada",
  "Griekenland",
  "Groenland",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinee-Bissau",
  "Guyana",
  "Haïti",
  "Heard Island and McDonald Islands",
  "Heilige Stoel",
  "Honduras",
  "Hongarije",
  "Hongkong",
  "Ierland",
  "IJsland",
  "India",
  "Indian Ocean",
  "Indonesië",
  "Irak",
  "Iran",
  "Isle of Man",
  "Israël",
  "Italië",
  "Ivoorkust",
  "Jamaica",
  "Jan Mayen",
  "Japan",
  "Jemen",
  "Jersey",
  "Jordanië",
  "Kaapverdië",
  "Kameroen",
  "Kazachstan",
  "Kenia",
  "Kirgizstan",
  "Kiribati",
  "Koeweit",
  "Kroatië",
  "Laos",
  "Lesotho",
  "Letland",
  "Libanon",
  "Liberia",
  "Libië",
  "Liechtenstein",
  "Litouwen",
  "Luxemburg",
  "Macao",
  "Macedonië",
  "Madagaskar",
  "Malawi",
  "Maldiven",
  "Maleisië",
  "Mali",
  "Malta",
  "Marokko",
  "Marshall Islands",
  "Mauritanië",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldavië",
  "Monaco",
  "Mongolië",
  "Montenegro",
  "Montserrat",
  "Mozambique",
  "Myanmar",
  "Namibië",
  "Nauru",
  "Navassa Island",
  "Nederland",
  "Nederlandse Antillen",
  "Nepal",
  "Ngwane",
  "Nicaragua",
  "Nieuw-Caledonië",
  "Nieuw-Zeeland",
  "Niger",
  "Nigeria",
  "Niue",
  "Noordelijke Marianen",
  "Noord-Korea",
  "Noorwegen",
  "Norfolk Island",
  "Oekraïne",
  "Oezbekistan",
  "Oman",
  "Oostenrijk",
  "Pacific Ocean",
  "Pakistan",
  "Palau",
  "Panama",
  "Papoea-Nieuw-Guinea",
  "Paracel Islands",
  "Paraguay",
  "Peru",
  "Pitcairn",
  "Polen",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Roemenië",
  "Rusland",
  "Rwanda",
  "Saint Helena",
  "Saint Lucia",
  "Saint Vincent en de Grenadines",
  "Saint-Pierre en Miquelon",
  "Salomon",
  "Samoa",
  "San Marino",
  "São Tomé en Principe",
  "Saudi-Arabië",
  "Senegal",
  "Servië",
  "Seychellen",
  "Sierra Leone",
  "Singapore",
  "Sint-Kitts en Nevis",
  "Slovenië",
  "Slowakije",
  "Soedan",
  "Somalië",
  "South Georgia and the South Sandwich Islands",
  "Southern Ocean",
  "Spanje",
  "Spratly Islands",
  "Sri Lanka",
  "Suriname",
  "Svalbard",
  "Syrië",
  "Tadzjikistan",
  "Taiwan",
  "Tanzania",
  "Thailand",
  "Timor Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad en Tobago",
  "Tsjaad",
  "Tsjechië",
  "Tunesië",
  "Turkije",
  "Turkmenistan",
  "Turks-en Caicoseilanden",
  "Tuvalu",
  "Uganda",
  "Uruguay",
  "Vanuatu",
  "Venezuela",
  "Verenigd Koninkrijk",
  "Verenigde Arabische Emiraten",
  "Verenigde Staten van Amerika",
  "Vietnam",
  "Wake Island",
  "Wallis en Futuna",
  "Wereld",
  "West Bank",
  "Westelijke Sahara",
  "Zambia",
  "Zimbabwe",
  "Zuid-Afrika",
  "Zuid-Korea",
  "Zweden",
  "Zwitserland"
];
