module["exports"] = [
  "alléen",
  "bakken",
  "berget",
  "bråten",
  "eggen",
  "engen",
  "ekra",
  "faret",
  "flata",
  "gata",
  "gjerdet",
  "grenda",
  "gropa",
  "hagen",
  "haugen",
  "havna",
  "holtet",
  "høgda",
  "jordet",
  "kollen",
  "kroken",
  "lia",
  "lunden",
  "lyngen",
  "løkka",
  "marka",
  "moen",
  "myra",
  "plassen",
  "ringen",
  "roa",
  "røa",
  "skogen",
  "skrenten",
  "spranget",
  "stien",
  "stranda",
  "stubben",
  "stykket",
  "svingen",
  "tjernet",
  "toppen",
  "tunet",
  "vollen",
  "vika",
  "åsen"
];
