module["exports"] = [
  "Baglung",
  "Banke",
  "Bara",
  "Bardiya",
  "Bhaktapur",
  "Bhojupu",
  "Chitwan",
  "Dailekh",
  "Dang",
  "Dhading",
  "Dhankuta",
  "Dhanusa",
  "Dolakha",
  "Dolpha",
  "Gorkha",
  "Gulmi",
  "Humla",
  "Ilam",
  "Jajarkot",
  "Jhapa",
  "Jumla",
  "Kabhrepalanchok",
  "Kalikot",
  "Kapilvastu",
  "Kaski",
  "Kathmandu",
  "Lalitpur",
  "Lamjung",
  "Manang",
  "Mohottari",
  "Morang",
  "Mugu",
  "Mustang",
  "Myagdi",
  "Nawalparasi",
  "Nuwakot",
  "Palpa",
  "Parbat",
  "Parsa",
  "Ramechhap",
  "Rauswa",
  "Rautahat",
  "Rolpa",
  "Rupandehi",
  "Sankhuwasabha",
  "Sarlahi",
  "Sindhuli",
  "Sindhupalchok",
  "Sunsari",
  "Surket",
  "Syangja",
  "Tanahu",
  "Terhathum"
];
