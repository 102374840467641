module["exports"] = {
  "adjective": [
    "Маленький",
    "Эргономичный",
    "Грубый",
    "Интеллектуальный",
    "Великолепный",
    "Невероятный",
    "Фантастический",
    "Практчиный",
    "Лоснящийся",
    "Потрясающий"
  ],
  "material": [
    "Стальной",
    "Деревянный",
    "Бетонный",
    "Пластиковый",
    "Хлопковый",
    "Гранитный",
    "Резиновый"
  ],
  "product": [
    "Стул",
    "Автомобиль",
    "Компьютер",
    "Берет",
    "Кулон",
    "Стол",
    "Свитер",
    "Ремень",
    "Ботинок"
  ]
};
