module["exports"] = [
  "hormigas",
   "murciélagos",
   "osos",
   "abejas",
   "pájaros",
   "búfalo",
   "gatos",
   "pollos",
   "ganado",
   "perros",
   "delfines",
   "patos",
   "elefantes",
   "peces",
   "zorros",
   "ranas",
   "gansos",
   "cabras",
   "caballos",
   "canguros",
   "leones",
   "monos",
   "búhos",
   "bueyes",
   "pingüinos",
   "pueblo",
   "cerdos",
   "conejos",
   "ovejas",
   "tigres",
   "ballenas",
   "lobos",
   "cebras",
   "almas en pena",
   "cuervos",
   "gatos negros",
   "quimeras",
   "fantasmas",
   "conspiradores",
   "dragones",
   "enanos",
   "duendes",
   "encantadores",
   "exorcistas",
   "hijos",
   "enemigos",
   "gigantes",
   "gnomos",
   "duendes",
   "gansos",
   "grifos",
   "licántropos",
   "némesis",
   "ogros",
   "oráculos",
   "profetas",
   "hechiceros",
   "arañas",
   "espíritus",
   "vampiros",
   "brujos",
   "zorras",
   "hombres lobo",
   "brujas",
   "adoradores",
   "zombies",
   "druidas"
];
