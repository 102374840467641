module["exports"] = [
  "berg",
  "borg",
  "by",
  "bø",
  "dal",
  "eid",
  "fjell",
  "fjord",
  "foss",
  "grunn",
  "hamn",
  "havn",
  "helle",
  "mark",
  "nes",
  "odden",
  "sand",
  "sjøen",
  "stad",
  "strand",
  "strøm",
  "sund",
  "vik",
  "vær",
  "våg",
  "ø",
  "øy",
  "ås"
];
