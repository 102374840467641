module["exports"] = [
  "Аврелія",
  "Аврора",
  "Агапія",
  "Агата",
  "Агафія",
  "Агнеса",
  "Агнія",
  "Агрипина",
  "Ада",
  "Аделаїда",
  "Аделіна",
  "Адріана",
  "Азалія",
  "Алевтина",
  "Аліна",
  "Алла",
  "Альбіна",
  "Альвіна",
  "Анастасія",
  "Анастасія",
  "Анатолія",
  "Ангеліна",
  "Анжела",
  "Анна",
  "Антонида",
  "Антоніна",
  "Антонія",
  "Анфіса",
  "Аполлінарія",
  "Аполлонія",
  "Аркадія",
  "Артемія",
  "Афанасія",
  "Білослава",
  "Біляна",
  "Благовіста",
  "Богдана",
  "Богуслава",
  "Божена",
  "Болеслава",
  "Борислава",
  "Броніслава",
  "В’ячеслава",
  "Валентина",
  "Валерія",
  "Варвара",
  "Василина",
  "Вікторія",
  "Вілена",
  "Віленіна",
  "Віліна",
  "Віола",
  "Віолетта",
  "Віра",
  "Віргінія",
  "Віта",
  "Віталіна",
  "Влада",
  "Владислава",
  "Власта",
  "Всеслава",
  "Галина",
  "Ганна",
  "Гелена",
  "Далеслава",
  "Дана",
  "Дарина",
  "Дарислава",
  "Діана",
  "Діяна",
  "Добринка",
  "Добромила",
  "Добромира",
  "Добромисла",
  "Доброслава",
  "Долеслава",
  "Доляна",
  "Жанна",
  "Жозефіна",
  "Забава",
  "Звенислава",
  "Зінаїда",
  "Злата",
  "Зореслава",
  "Зорина",
  "Зоряна",
  "Зоя",
  "Іванна",
  "Ілона",
  "Інна",
  "Іннеса",
  "Ірина",
  "Ірма",
  "Калина",
  "Каріна",
  "Катерина",
  "Квітка",
  "Квітослава",
  "Клавдія",
  "Крентта",
  "Ксенія",
  "Купава",
  "Лада",
  "Лариса",
  "Леся",
  "Ликера",
  "Лідія",
  "Лілія",
  "Любава",
  "Любислава",
  "Любов",
  "Любомила",
  "Любомира",
  "Люборада",
  "Любослава",
  "Людмила",
  "Людомила",
  "Майя",
  "Мальва",
  "Мар’яна",
  "Марина",
  "Марічка",
  "Марія",
  "Марта",
  "Меланія",
  "Мечислава",
  "Милодара",
  "Милослава",
  "Мирослава",
  "Мілана",
  "Мокрина",
  "Мотря",
  "Мстислава",
  "Надія",
  "Наталія",
  "Неля",
  "Немира",
  "Ніна",
  "Огняна",
  "Оксана",
  "Олександра",
  "Олена",
  "Олеся",
  "Ольга",
  "Ореста",
  "Орина",
  "Орислава",
  "Орися",
  "Оріяна",
  "Павліна",
  "Палажка",
  "Пелагея",
  "Пелагія",
  "Поліна",
  "Поляна",
  "Потішана",
  "Радміла",
  "Радослава",
  "Раїна",
  "Раїса",
  "Роксолана",
  "Ромена",
  "Ростислава",
  "Руслана",
  "Світлана",
  "Святослава",
  "Слава",
  "Сміяна",
  "Сніжана",
  "Соломія",
  "Соня",
  "Софія",
  "Станислава",
  "Сюзана",
  "Таїсія",
  "Тамара",
  "Тетяна",
  "Устина",
  "Фаїна",
  "Февронія",
  "Федора",
  "Феодосія",
  "Харитина",
  "Христина",
  "Христя",
  "Юліанна",
  "Юлія",
  "Юстина",
  "Юхима",
  "Юхимія",
  "Яна",
  "Ярина",
  "Ярослава"
];
