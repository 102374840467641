module["exports"] = [
  "ТОВ",
  "ПАТ",
  "ПрАТ",
  "ТДВ",
  "КТ",
  "ПТ",
  "ДП",
  "ФОП"
];
