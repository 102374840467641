module["exports"] = [
  "Adhikari",
  "Aryal",
  "Baral",
  "Basnet",
  "Bastola",
  "Basynat",
  "Bhandari",
  "Bhattarai",
  "Chettri",
  "Devkota",
  "Dhakal",
  "Dongol",
  "Ghale",
  "Gurung",
  "Gyawali",
  "Hamal",
  "Jung",
  "KC",
  "Kafle",
  "Karki",
  "Khadka",
  "Koirala",
  "Lama",
  "Limbu",
  "Magar",
  "Maharjan",
  "Niroula",
  "Pandey",
  "Pradhan",
  "Rana",
  "Raut",
  "Sai",
  "Shai",
  "Shakya",
  "Sherpa",
  "Shrestha",
  "Subedi",
  "Tamang",
  "Thapa"
];
