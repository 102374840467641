module["exports"] = [
  "CH",
  "CH",
  "CH",
  "DE",
  "AT",
  "US",
  "LI",
  "US",
  "HK",
  "VN"
];
