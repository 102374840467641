module["exports"] = [
  "auxiliary",
  "primary",
  "back-end",
  "digital",
  "open-source",
  "virtual",
  "cross-platform",
  "redundant",
  "online",
  "haptic",
  "multi-byte",
  "bluetooth",
  "wireless",
  "1080p",
  "neural",
  "optical",
  "solid state",
  "mobile"
];
