var az = {};
module['exports'] = az;
az.title = "Azerbaijani";
az.separator = " və ";
az.address = require("./address");
az.internet = require("./internet");
az.name = require("./name");
az.phone_number = require("./phone_number");
az.commerce = require("./commerce");
az.company = require("./company");
az.date = require("./date");
