module["exports"] = [
  "Qasımova",
  "Əfəndiyeva",
  "Soltanova",
  "Abdullayeva",
  "Rəşidova",
  "Ələkbərova",
  "Əliyeva",
  "Tahirova",
  "Seyidova",
  "Vəsiyeva"
];
