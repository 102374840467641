module["exports"] = [
  "Адамович",
  "Азарович",
  "Алевтинович",
  "Альбертович",
  "Анастасович",
  "Анатолійович",
  "Андрійович",
  "Антонович",
  "Аркадійович",
  "Арсенійович",
  "Арсенович",
  "Артемович",
  "Архипович",
  "Аскольдович",
  "Афанасійович",
  "Білославович",
  "Богданович",
  "Божемирович",
  "Боженович",
  "Болеславович",
  "Боримирович",
  "Борисович",
  "Бориславович",
  "Братиславович",
  "В’ячеславович",
  "Вадимович",
  "Валентинович",
  "Валерійович",
  "Васильович",
  "Вікторович",
  "Віталійович",
  "Владиславович",
  "Володимирович",
  "Всеволодович",
  "Всеславович",
  "Гаврилович",
  "Герасимович",
  "Георгійович",
  "Гнатович",
  "Гордійович",
  "Григорійович",
  "Данилович",
  "Даромирович",
  "Денисович",
  "Дмитрович",
  "Добромирович",
  "Доброславович",
  "Євгенович",
  "Захарович",
  "Захарійович",
  "Збориславович",
  "Звенимирович",
  "Звениславович",
  "Зеновійович",
  "Зиновійович",
  "Златомирович",
  "Зореславович",
  "Іванович",
  "Ігорович",
  "Ізяславович",
  "Корнелійович",
  "Корнилович",
  "Корнійович",
  "Костянтинович",
  "Лаврентійович",
  "Любомирович",
  "Макарович",
  "Максимович",
  "Маркович",
  "Маркіянович",
  "Матвійович",
  "Мечиславович",
  "Микитович",
  "Миколайович",
  "Миронович",
  "Мирославович",
  "Михайлович",
  "Мстиславович",
  "Назарович",
  "Назарійович",
  "Натанович",
  "Немирович",
  "Несторович",
  "Олегович",
  "Олександрович",
  "Олексійович",
  "Олелькович",
  "Омелянович",
  "Орестович",
  "Орхипович",
  "Остапович",
  "Охрімович",
  "Павлович",
  "Панасович",
  "Пантелеймонович",
  "Петрович",
  "Пилипович",
  "Радимирович",
  "Радимович",
  "Родіонович",
  "Романович",
  "Ростиславович",
  "Русланович",
  "Святославович",
  "Сергійович",
  "Славутович",
  "Станіславович",
  "Степанович",
  "Стефанович",
  "Тарасович",
  "Тимофійович",
  "Тихонович",
  "Устимович",
  "Юрійович",
  "Юхимович",
  "Ярославович"
];
