module["exports"] = [
  "Emma",
  "Sara",
  "Thea",
  "Ida",
  "Julie",
  "Nora",
  "Emilie",
  "Ingrid",
  "Hanna",
  "Maria",
  "Sofie",
  "Anna",
  "Malin",
  "Amalie",
  "Vilde",
  "Frida",
  "Andrea",
  "Tuva",
  "Victoria",
  "Mia",
  "Karoline",
  "Mathilde",
  "Martine",
  "Linnea",
  "Marte",
  "Hedda",
  "Marie",
  "Helene",
  "Silje",
  "Leah",
  "Maja",
  "Elise",
  "Oda",
  "Kristine",
  "Aurora",
  "Kaja",
  "Camilla",
  "Mari",
  "Maren",
  "Mina",
  "Selma",
  "Jenny",
  "Celine",
  "Eline",
  "Sunniva",
  "Natalie",
  "Tiril",
  "Synne",
  "Sandra",
  "Madeleine"
];
